import { ComponentType } from 'react';
import styled from 'styled-components';
interface props {
    big?: boolean,
    color?: string,
    solid?: boolean,
    noborder?: boolean,
}

function color(c: string) {
    if (c === 'red') return `#ff5156`

    if (c === 'gray') return '#d1d1d1'
    return c
}

const BTN = styled('button') <props>`
    @media print {
        display: none;
    }
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    border-radius: 4px;
    box-sizing: border-box;
    border-width: ${props => props.noborder ? 0 : '1px'};
    border-style: solid;
    border-color: ${props => color(props.color || '')};
    font-size: ${props => props.big ? 16 : 14}px;
    padding: ${props => props.big ? '9px 22px' : '4px 13px'};
    &:hover{
        opacity: .5;
    }
    &:active{
        filter: brightness(.9)
    }
    
    color: ${props => {
        if(props.solid && props.color === 'gray') return '#666'
        if (!props.solid) return color(props.color || '')
        return '#fff'
    }};

    background-color: ${props => {
        if (props.solid) return color(props.color || '')
        return '#fff'
    }};
`
export default BTN

const Customer = styled<ComponentType<any>>(BTN)`
    &:hover{
        opacity: 1;
    }
`

export const SolidRed = styled<ComponentType<any>>(Customer)`
    background-color: var(--theme-main);
    border-color: var(--theme-main);
    color: #fff;
    &:hover{
        background-color: #EC2F19;
        border-color: #EC2F19;
    }
`

export const SolidGray = styled<ComponentType<any>>(Customer)`
    background-color: #e1e1e1;
    border-color: #e1e1e1;
    color: #666;
    &:hover{
        background-color: #999;
        border-color: #999;
        color: #e1e1e1;
    }
`

export const Light = styled<ComponentType<any>>(Customer)`
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0px 0px 4px 0px rgba(219,219,219,0.50); 
    color: #666;
    &:hover{
        background-color:var(--theme-main);
        border-color: var(--theme-main);
        color: #fff;
    }
`
/**
 * hollow red HOVER时变为 SOLID RED
 */
export const HR2SR = styled<ComponentType<any>>(Customer)`
    background-color: #fff;
    border-color: var(--theme-main);
    color: var(--theme-main);
    &:hover{
        background-color: var(--theme-main);
        color: #fff;
    }
`

/**
 * hollow gray HOVER时变为 SOLID RED
 */
export const HG2SR = styled<ComponentType<any>>(Customer)`
    background-color: #fff;
    border-color: #999;
    color: #999;
    &:hover{
        background-color: var(--theme-main);
        border-color: var(--theme-main);
        color: #fff;
    }
`