import React, { Component } from 'react'

import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import { Pagination, message } from 'antd';

import styled from 'styled-components';

import { get, post } from '../../../netApi/fetch';

import Content from '../../../high-components/Content';

import Search from '../../total/Input/Search';

import paramParse from '../../../methods/paramParse';
import userlevel from '../../../methods/userlevel';

import Modal from '../../total/Modal';
import Select from '../../total/Select_';

import loading from '../../Methods/Loading';
import Loading from '../../Methods/Loading';

export const Logo = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 50%;
`
const Name = styled.div`
    font-weight: 600;
    color: #330000;
    font-size: 14px;
    max-width: 6em;
    min-width: 6em;
    word-wrap:break-word;
    word-break:break-all;
`
const Table = styled.table`
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    & td{
        padding: 12px;
        border-right: 1px solid #eee;
        box-sizing: border-box;
    }
    &:nth-child(1){
        border-left: 1px solid #eee;
    }
    & tr:last-child{
        border-bottom: 1px solid #eee;
    }
`
const Title = styled.div`
    font-weight: 600;
    color: #454545;
    font-size: 20px;
`

const FloatPanel = styled.div`
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background: white;
    z-index: 9;
    width:266px;
    box-shadow:0px 2px 4px 0px rgba(204,204,204,1);
    border-radius:2px;
    border:1px solid rgba(232,232,232,1);
`

const FloatCtrl = styled.div`
    background:rgba(247,249,250,1);
    border-radius:0px 0px 2px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`

const FloatLink = styled.div`
    font-size: 12px;
    color:rgba(0,0,0,0.45);
    font-weight: 600;
    transition: all 0.3s;
    &:hover{
        color:rgba(255,81,86,1);
    }
`

interface Props {
    match: {
        path: String,
        url: String,
        params: {
            [key: string]: string
        }
    }
    location: {
        search: string
    }
    history: {
        push: (href: string) => void
    }
}

interface State {
    search: string,
    page: number,
    userCount: number,
    list: user[],
    op: string | number,
    selectOp: boolean,
    closeOp: boolean,
    setting: boolean,
    settingUser?: user
    settingOp?: number
    showVideoPremission: boolean,
    showCardPremission?: boolean,
    rate?: number,
    platform?: number;
    getDataSuccess?: boolean;
}

interface inputuser {
    uid: number | string;
    sid: string,
    shopname: string,
    shoplogo: string,
    createtime?: string,
    username?: string,
    userlogo?: string,
    type?: string,
    pid?: string,
    p_shopname?: string,
    p_logo?: string,
    income_total?: string,
    video_permission?: boolean,
    withdraw_to_bank_permission?: boolean,
    service_tip?: string | number,
    withdraw_way_status_data?: {
        bank_status?: number,
        to_bank_accounting_date?: number,
        wechat_status?: number,
    },
    shop_payment_platform?: string,
    is_brand_street_user?: string | number,
}

export interface user {
    uid: number | string;
    sid: number,
    shopname: string,
    shoplogo: string,
    createtime?: string,
    username?: string,
    userlogo?: string,
    typeText?: string,
    type?: number,
    pid?: number,
    p_shopname?: string,
    p_logo?: string,
    income_total?: number,
    account_total?: number,
    showMenu?: boolean,
    showMenuFlag?: boolean,
    video_permission: boolean,
    withdraw_to_bank_permission: boolean,
    iscertified?: number,
    service_tip?: number,
    withdraw_way_status_data?: {
        bank_status?: number, to_bank_accounting_date?: number, wechat_status?: number,
    },
    shop_payment_platform?: number;
    is_brand_street_user?: number | string
}



class User extends Component<Props, State> {

    constructor(p: any) {
        super(p)
        this.state = {
            search: '',
            page: 1,
            userCount: 0,
            list: [],
            op: 'all',
            selectOp: false,
            closeOp: false,
            setting: false,
            showVideoPremission: false,
            platform: 0,
        }
    }

    pageSize = 15

    componentDidMount() {
        const params = paramParse(this.props.location.search)
        const data: { search: string, page: number, op: string | number, rate?: number } = {
            search: this.state.search,
            page: this.state.page,
            op: this.state.op,
        }
        data.search = decodeURIComponent(params.s || '')
        data.page = Number(params.p) || 1
        data.op = params.op || 'all'
        if (params.rate !== undefined) {
            data.rate = Number(params.rate)
        }
        console.log(data);
        this.setState({ ...data }, () => {
            this.getData()
        })
    }

    componentDidUpdate(props: Props) {
        let getFrag = false
        // console.log(this.props)
        const params = paramParse(this.props.location.search)
        const data: { search: string, page: number, op: string | number, rate?: number, platform?: number } = {
            search: this.state.search,
            page: this.state.page,
            op: this.state.op,
        }
        if (params.rate !== undefined) {
            data.rate = Number(params.rate)
        }
        if (params.platform !== undefined) {
            data.platform = Number(params.platform)
        }
        const _p = paramParse(props.location.search)
        if (_p.s !== params.s) {
            data.search = params.s
            getFrag = true
        }
        if (_p.p !== params.p) {
            data.page = Number(params.p)
            getFrag = true
        }
        if (_p.op !== params.op) {
            data.op = params.op || 'all'
            getFrag = true
        }
        if (_p.rate !== params.rate) {
            data.rate = params.rate !== undefined ? Number(params.rate) : undefined
            getFrag = true
        }
        if (_p.platform !== params.platform) {
            data.platform = params.platform !== undefined ? Number(params.platform) : undefined
            getFrag = true
        }
        if (getFrag) {
            this.setState({ ...data }, () => {
                this.getData()
            })
        }
    }
    componentWillUnmount() {
        this.setState = () => { }
    }

    render() {
        if(!this.state.getDataSuccess)return null
        const opText = userlevel(Number(this.state.op))
        const tip: { value: string }[] = [] // 提现费率
        for (let i = 0; i <= 100; i++) {
            tip[i] = { value: (i / 100).toFixed(2) }
        }
        const trade_platform = [
            { value: 1, name: '微信支付' },
            { value: 3, name: '汇聚支付' },
            // { value: 4, name: '银联' },
            // { value: 5, name: '聚富通' },
        ] // 支付平台
        const rebate = [
            { value: 1, name: '0.1%' },
            { value: 2, name: '0.2%' },
        ]
        return <div style={{ width: '100%', margin: '0 auto', padding: 30, }}>
            <div className='fbc'>
                <Title className='flex-box'>
                    <span>筛选</span>
                    <div className="flex-center" style={{ flexDirection: 'column', marginLeft: 50 }}>
                        <div style={{ color: '#acacac' }}>用户类型</div>
                        <span
                            onClick={() => { this.setState({ selectOp: true }) }}
                            style={{ fontSize: 14, color: '#999', fontWeight: 400, padding: '4px 11px', height: 32 }} className='cur-pointer flex-center'>
                            {opText || '选择用户类型'}
                        </span>
                    </div>
                    <div className="flex-center" style={{ flexDirection: 'column', marginLeft: 80 }}>
                        <div style={{ color: '#acacac' }}>提现费率</div>
                        <Select maxHeight={300} inputStyle={{ border: 0, width: '8em', textAlign: 'center', fontSize: 14 }} uni='selectRate'
                            list={[{ value: '全部' }, ...tip]}
                            placeholder='选择提现费率' showKey='value' value={[{ value: '全部' }, ...tip].findIndex(i => Number(i.value) === Number(this.state.rate)) || 0}
                            onChange={e => {
                                this.props.history.push(`/user/spec?s=${this.state.search}&p=1&op=${this.state.op}${e.value !== '全部' ? `&rate=${e.value}` : ''}&platform=${this.state.platform}`)
                            }} />
                    </div>
                    <div className="flex-center" style={{ flexDirection: 'column', marginLeft: 80 }}>
                        <div style={{ color: '#acacac' }}>进账平台</div>
                        <Select maxHeight={300} inputStyle={{ border: 0, width: '8em', textAlign: 'center', fontSize: 14 }} uni='selectRate'
                            list={[{ name: '全部', value: 0 }, ...trade_platform]}
                            placeholder='选择进账平台' showKey='name' value={[{ name: '全部', value: 0 }, ...trade_platform].findIndex(i => Number(i.value) === Number(this.state.platform)) || 0}
                            onChange={e => {
                                this.props.history.push(`/user/spec?s=${this.state.search}&p=1&op=${this.state.op}${this.state.rate !== undefined ? `&rate=${this.state.rate}` : ''}&platform=${e.value}`)
                            }} />
                    </div>
                </Title>
                <div style={{ width: '40%' }}>
                    <Search onChange={(e) => {
                        this.setState({ search: e })
                    }} onSearch={() => {
                        this.props.history.push(`/user/spec?s=${this.state.search}&p=1&op=${this.state.op}${this.state.rate !== undefined ? `&rate=${this.state.rate}` : ''}&platform=${this.state.platform}`)
                    }} search={this.state.search} placeholder='搜索用户微信昵称或小程序昵称' />
                </div>
            </div>

            <Table>
                <thead>
                    <tr style={{ background: '#f5f5f5' }}>
                        <td style={{ width: 160 }}>微信</td>
                        <td style={{ width: 160 }}>小程序</td>
                        <td style={{ width: 160 }}>上级</td>
                        <td style={{ width: 100 }}>历史总流水</td>
                        <td style={{ width: 140 }}>提现费率</td>
                        <td style={{ width: 160 }}>返佣百分比</td>
                        <td style={{ width: 140 }}>提现方式</td>
                        <td style={{ width: 140 }}>到账时间</td>
                        <td style={{ width: 200 }}>进账平台</td>
                        <td style={{ width: 100 }}>品牌街</td>
                        <td style={{ width: 160 }}>注册时间</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.list.map((item: user, i: number) => {
                        return <tr key={item.sid} style={{ background: i % 2 ? '#f5f5f5' : '#fff' }}>
                            <td onMouseEnter={e => {
                                item.showMenuFlag = true
                                setTimeout(() => {
                                    if (!item.showMenuFlag) return
                                    item.showMenu = true
                                    this.setState({
                                        list: this.state.list
                                    })
                                }, 300);
                            }} onMouseLeave={e => {
                                item.showMenuFlag = false
                                item.showMenu = false
                                this.setState({
                                    list: this.state.list
                                })
                            }} >
                                <div className='fbc' style={{ position: 'relative' }}>
                                    {item.showMenu ? <FloatPanel>
                                        <div style={{ padding: 14 }}>
                                            <div className='fbc' style={{ marginBottom: 14 }}>
                                                <Logo style={{ marginRight: 14 }} src={item.userlogo} />
                                                <div style={{ flex: 1 }}>
                                                    <Name style={{ maxWidth: '11em' }}>{item.username}</Name>
                                                </div>
                                            </div>
                                            <div>可提现金额</div>
                                            <Name style={{ fontSize: 24 }}>￥{((item.account_total || 0) / 100).toFixed(2)}</Name>
                                        </div>
                                        <FloatCtrl>
                                            <NavLink to={{ pathname: `/user/detail/${item.sid}`, search: 'mode=2', state: { user: item } }}><FloatLink>提现记录</FloatLink></NavLink>
                                            <NavLink to={{ pathname: `/user/detail/${item.sid}`, search: 'mode=3', state: { user: item } }}><FloatLink>流水明细</FloatLink></NavLink>
                                            <NavLink to={{ pathname: `/user/detail/${item.sid}`, search: 'mode=1', state: { user: item } }}><FloatLink>TA的发布</FloatLink></NavLink>
                                        </FloatCtrl>
                                    </FloatPanel> : null}
                                    <Logo src={item.userlogo} />
                                    <Name>{item.username}</Name>
                                </div>
                            </td>
                            <td>
                                <div className='fbc'>
                                    <Logo src={item.shoplogo} />
                                    <Name>{item.shopname}</Name>
                                </div>
                            </td>
                            <td>
                                <NavLink target='_blank' to={`/user/detail/${item.pid}?mode=1`} >
                                    <div className='fbc'>
                                        <Logo src={item.p_logo} />
                                        <Name>{item.p_shopname}</Name>
                                    </div>
                                </NavLink>
                            </td>
                            {item.income_total !== undefined ? <td>
                                <Name style={{ minWidth: '4em' }}>￥{(item.income_total / 100).toFixed(2)}</Name>
                            </td> : null}
                            <td style={{ textAlign: 'center' }}>
                                <div className='flex-center'>
                                    <Select maxHeight={300} canSearch onChange={(e: any) => {
                                        console.log(e);
                                        if (!e.value) return
                                        if (e.value !== item.service_tip) {
                                            Loading.show()
                                            post('/god/setshopconfig', { types: 1, op: 1, rate: e.value, sid: item.sid }).then(() => {
                                                Loading.hide()
                                                item.service_tip = e.value
                                                this.setState({ list: this.state.list })
                                            }).catch((e: string) => {
                                                Loading.hide()
                                                message.error(e)
                                            })
                                        }
                                    }} uni={`${item.sid}st`} list={tip} showKey='value' value={tip.findIndex((i: any) => i.value === Number(item.service_tip).toFixed(2))} />
                                    <div>%</div>
                                </div>
                            </td>
                            {item.type ? <td>
                                <Select uni={`${item.sid}rb`} list={rebate} placeholder={item.typeText} showKey='name' onChange={e => {
                                    if (e.value === item.type) return
                                    Loading.show()
                                    post('/god/setshopconfig', { op: 2, types: 1, sid: item.sid, proxy_type: e.value }).then(() => {
                                        Loading.hide()
                                        item.type = e.value
                                        item.typeText = userlevel(item.type)
                                        this.setState({ list: this.state.list })
                                    }).catch((e: string) => {
                                        Loading.hide()
                                        message.error(e)
                                    })
                                }} value={rebate.findIndex(i => i.value === item.type)} />
                            </td> : null}
                            {item.withdraw_way_status_data ? <td>
                                <div>
                                    <div className='flex-box cur-pointer' onClick={this.setWithdrawType.bind(this, item, 1)}>
                                        <i style={{
                                            marginRight: 12,
                                            transition: 'all .3s', color: Number(item.withdraw_way_status_data.wechat_status) === 1 ? '#ff5156' : '#999',
                                        }} className={`iconfont ${Number(item.withdraw_way_status_data.wechat_status) === 1 ? 'icon-gou' : 'icon-yuan'}`} />
                                        <div>提现到零钱</div>
                                    </div>
                                    <div className='flex-box cur-pointer' onClick={this.setWithdrawType.bind(this, item, 2)}>
                                        <i style={{
                                            marginRight: 12,
                                            transition: 'all .3s', color: Number(item.withdraw_way_status_data.bank_status) === 1 ? '#ff5156' : '#999',
                                        }} className={`iconfont ${Number(item.withdraw_way_status_data.bank_status) === 1 ? 'icon-gou' : 'icon-yuan'}`} />
                                        <div>提现到银行卡</div>
                                    </div>
                                </div>
                            </td> : null}
                            {item.withdraw_way_status_data ? <td>
                                <div>
                                    <div className='flex-box cur-pointer' onClick={this.setArrivalTime.bind(this, item, 2)}>
                                        <i style={{
                                            marginRight: 12,
                                            transition: 'all .3s', color: Number(item.withdraw_way_status_data.to_bank_accounting_date) === 2 ? '#ff5156' : '#999',
                                        }} className={`iconfont ${Number(item.withdraw_way_status_data.to_bank_accounting_date) === 2 ? 'icon-gou' : 'icon-yuan'}`} />
                                        <div>T+1</div>
                                    </div>
                                    <div className='flex-box cur-pointer' onClick={this.setArrivalTime.bind(this, item, 1)}>
                                        <i style={{
                                            marginRight: 12,
                                            transition: 'all .3s', color: Number(item.withdraw_way_status_data.to_bank_accounting_date) === 1 ? '#ff5156' : '#999',
                                        }} className={`iconfont ${Number(item.withdraw_way_status_data.to_bank_accounting_date) === 1 ? 'icon-gou' : 'icon-yuan'}`} />
                                        <div>实时</div>
                                    </div>
                                </div>
                            </td> : null}
                            <td>
                                <div>
                                    <Select uni={`${item.sid}pp`} list={trade_platform} showKey='name' onChange={(e: any) => {
                                        if (e.value === item.shop_payment_platform) return
                                        Loading.show()
                                        post('/god/setshopconfig', { op: 5, types: 1, sid: item.sid, trade_platform: e.value }).then(() => {
                                            Loading.hide()
                                            item.shop_payment_platform = e.value
                                            this.setState({ list: this.state.list })
                                        }).catch((e: string) => {
                                            Loading.hide()
                                            message.error(e)
                                        })
                                    }} value={trade_platform.findIndex((i: any) => i.value === item.shop_payment_platform)} />
                                </div>
                            </td>
                            <td>
                                <div className='cur-pointer flex-center' onClick={() => {
                                    post('/brandstreet/useraddorupdate', {
                                        uid: item.uid,
                                        status: Number(item.is_brand_street_user) === 1 ? 0 : 1,
                                    }).then(() => {
                                        item.is_brand_street_user = Number(item.is_brand_street_user) === 1 ? 0 : 1
                                        this.setState({ list: this.state.list })
                                    })
                                }}>
                                    <i className={`iconfont ${Number(item.is_brand_street_user) === 1 ? 'icon-gouxuan' : 'icon-weixuanzhong'}`} style={{
                                        color: Number(item.is_brand_street_user) === 1 ? '#ff5156' : '#bbb'
                                    }} />
                                </div>
                            </td>
                            <td>
                                <Name>{item.createtime}</Name>
                            </td>
                            <td>
                                <NavLink to={`/user/detail/${item.sid}?mode=1`} target='_blank' >
                                    <button className='button_hollow_red'  >TA的主页</button>
                                </NavLink>
                            </td>
                            {/* <td>
                                <div className='fcc'>
                                    <i onClick={() => {
                                        if (item.video_permission) return
                                        this.setState({ showVideoPremission: true, settingUser: item })
                                    }} className={`iconfont ${item.video_permission ? 'icon-gouxuan' : 'icon-weixuanzhong cur-pointer'}`} style={{
                                        color: item.video_permission ? '#ff5156' : '#ccc'
                                    }} />
                                </div>
                            </td>
                            <td>
                                <div className='fcc'>
                                    <i onClick={() => {
                                        if (item.withdraw_to_bank_permission) return
                                        this.setState({ showCardPremission: true, settingUser: item })
                                    }} className={`iconfont ${item.withdraw_to_bank_permission ? 'icon-gouxuan' : 'icon-weixuanzhong cur-pointer'}`} style={{
                                        color: item.withdraw_to_bank_permission ? '#ff5156' : '#ccc'
                                    }} />
                                </div>
                            </td> */}
                        </tr>
                    })}
                </tbody>
            </Table>

            {this.state.setting && this.state.settingUser ? <Modal title={`将${this.state.settingUser.shopname}设为会员`} onClose={() => { this.setState({ setting: false, settingUser: undefined }) }} onConfirm={() => {
                if (!this.state.settingUser) return
                const item = this.state.settingUser
                const data = { sid: item.sid, types: 1, proxy_type: this.state.settingOp }
                console.log(item, data)
                post('/god/setfreevip', data).then(() => {
                    this.setState({ list: [] })
                    this.getData()
                    message.success('设置成功')
                }).catch((res: string) => message.error(res))
            }}>
                <div style={{ width: 500 }}>
                    <div style={{ width: 350 }}>
                        <div className='fbc'>
                            <div>微信</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.userlogo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.username}</Name>
                            </div>
                        </div>
                        <div className='fbc'>
                            <div>小程序</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.shoplogo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.shopname}</Name>
                            </div>
                        </div>
                        <div className='fbc'>
                            <div>上级</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.p_logo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.p_shopname}</Name>
                            </div>
                        </div>
                    </div>
                    <div>
                        将{this.state.settingUser.shopname}设为
                        <span><Select list={[
                            { name: '黄金供应商', op: 1 },
                            { name: '钻石供应商', op: 2 },
                            { name: '高级会员', op: 4 },
                            { name: '特邀会员', op: 6 },
                            { name: '设置0.006', op: 7 },
                        ]} showKey={'name'} uni='proxy_type' value={[
                            { name: '黄金供应商', op: 1 },
                            { name: '钻石供应商', op: 2 },
                            { name: '高级会员', op: 4 },
                            { name: '特邀会员', op: 6 },
                            { name: '设置0.006', op: 7 },
                        ].findIndex(i => { return this.state.settingOp === i.op })} onChange={e => {
                            if (e.op === this.state.settingOp) return
                            this.setState({ settingOp: e.op })
                        }} /></span>
                    </div>
                </div>
            </Modal> : null}

            <Pagination total={this.state.userCount} current={this.state.page} pageSize={this.pageSize} showQuickJumper={true} onChange={e => {
                let url = `/user/spec?s=${this.state.search}&p=${e}&op=${this.state.op}`
                if (this.state.rate !== undefined && !isNaN(Number(this.state.rate))) url += `&rate=${this.state.rate}`
                if (this.state.platform && this.state.platform > 0) url += `&platform=${this.state.platform}`
                this.props.history.push(url)
            }} />

            {this.state.selectOp ? (
                <Modal shouldClose={this.state.closeOp} title='选择用户类型' cancel={true} close={() => { this.setState({ selectOp: false, closeOp: false }) }} style={{ content: { maxHeight: 600, overflowX: 'auto' } }} >
                    {[
                        { name: '黄金供应商', op: 1 },
                        { name: '钻石供应商', op: 2 },
                        { name: '初级会员', op: 3 },
                        { name: '高级会员', op: 4 },
                        { name: '普通用户', op: 5 },
                        { name: '特邀会员', op: 6 },
                        { name: '全部用户', op: 'all' },
                    ].map((item, i) => {
                        return (
                            <div key={i} className='select-hover'
                                onClick={() => {
                                    this.props.history.push(`/user/spec?s=${this.state.search}&p=1&op=${item.op}`)
                                    this.setState({ closeOp: true })
                                }}
                                style={{
                                    display: 'flex', overflowY: 'auto', wordBreak: 'break-all', cursor: 'pointer', justifyContent: 'space-between',
                                    minWidth: 200, alignItems: 'center', marginTop: 10, padding: '4px 11px', maxHeight: 400
                                }}>
                                <div style={{ flex: 1, fontSize: 14, cursor: 'pointer' }}>{item.name}</div>
                            </div>
                        )
                    })}
                </Modal>
            ) : ''}

            {/* {this.state.showVideoPremission && this.state.settingUser ? <Modal onClose={() => { this.setState({ showVideoPremission: false }) }} cancel onConfirm={this.setVideoPermission.bind(this, [this.state.settingUser.sid])}>
                <div>确定为{this.state.settingUser.shopname}开通短视频带货功能？</div>
            </Modal> : null}

            {this.state.showCardPremission && this.state.settingUser ? <Modal onClose={() => { this.setState({ showCardPremission: false }) }} cancel onConfirm={this.setWithdrawToCard.bind(this, this.state.settingUser.sid)}>
                <div>确定为{this.state.settingUser.shopname}开通提现到银行卡功能？</div>
            </Modal> : null} */}
        </div>
    }

    async getData() {
        loading.show('加载中')
        const data: any = {
            limit: this.pageSize,
            op: this.state.op,
            search: this.state.search,
            offset: (this.state.page - 1) * this.pageSize,
            types: 1,
        }
        if (this.state.platform && this.state.platform > 0) data.payment_platfrom = this.state.platform
        if (this.state.rate !== undefined && !isNaN(Number(this.state.rate))) data.rate = (this.state.rate / 100).toFixed(4)
        try {
            const res = await get('/god/selectalluserinfolist', data)
            loading.hide()
    
            this.setState({
                userCount: Number(res.count),
                getDataSuccess: true,
                list: res.list.map((item: inputuser): user => {
                    const typeText = userlevel(Number(item.type))
                    return {
                        ...item,
                        income_total: Number(item.income_total),
                        service_tip: Number(item.service_tip),
                        pid: Number(item.pid),
                        type: Number(item.type),
                        typeText,
                        sid: Number(item.sid),
                        video_permission: Boolean(item.video_permission),
                        withdraw_to_bank_permission: Boolean(item.withdraw_to_bank_permission),
                        shop_payment_platform: Number(item.shop_payment_platform),
                        is_brand_street_user: item.is_brand_street_user,
                    }
                }),
            })
        } catch (error) {
            loading.hide()
            message.error(error)
            this.props.history.push('/home')
        }
    }

    async setVideoPermission(e: number[]) {
        post('/god/setvideopermission', {
            sid_list: e,
            types: 1,
        }).then(() => {
            this.setState({ list: [], showVideoPremission: false, settingUser: undefined })
            this.getData()
        }).catch((error: string) => {
            message.error(error)
        })
    }
    async setWithdrawToCard(e: number) {
        post('/god/setspecialfunction', {
            sid: e,
            types: 1,
            type: 2,
        }).then(() => {
            this.setState({ list: [], showCardPremission: false, settingUser: undefined })
            this.getData()
        }).catch((error: string) => {
            message.error(error)
        })
    }
    setArrivalTime(item: user, type: number) {
        if(!item.withdraw_way_status_data)return
        if (Number(item.withdraw_way_status_data.to_bank_accounting_date) === type) return
        Loading.show()
        post('/god/setshopconfig', { op: 4, types: 1, withdraw_accounting_date: type, sid: item.sid }).then(() => {
            Loading.hide()
            if(!item.withdraw_way_status_data)return
            item.withdraw_way_status_data.to_bank_accounting_date = type
            this.setState({ list: this.state.list })
        }).catch((e: string) => {
            Loading.hide()
            message.error(e)
        })
    }

    setWithdrawType(item: user, type: 1 | 2) {
        if(!item.withdraw_way_status_data)return
        const _type = {
            wechat_status: type === 1 ? Number(!Boolean(Number(item.withdraw_way_status_data.wechat_status))) : item.withdraw_way_status_data.wechat_status,
            bank_status: type === 2 ? Number(!Boolean(Number(item.withdraw_way_status_data.bank_status))) : item.withdraw_way_status_data.bank_status,
        }
        Loading.show()
        post('/god/setshopconfig', { op: 3, types: 1, withdraw_way_status_data: _type, sid: item.sid }).then(() => {
            Loading.hide()
            item.withdraw_way_status_data = {
                ...item.withdraw_way_status_data,
                ..._type,
            }
            this.setState({ list: this.state.list })
        }).catch((e: string) => {
            Loading.hide()
            message.error(e)
        })
    }
}

const mapStateToProps = (state: any) => {
    return {
        is_Login: state.is_Login
    }
}
export default connect(mapStateToProps)(Content(User))