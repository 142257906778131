import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className='fcc' id='footer' style={{ minHeight: '10vh' }}>
                <a style={{ fontSize: 12, color: '#999' }} target='_blank' rel="noopener noreferrer" href='http://www.beian.miit.gov.cn/'>京ICP备19012758号-2</a>
            </div>
        )
    }
}
export default Footer