import React, { useState } from 'react';
import ReactDom from 'react-dom';
import Modal from '../total/Modal';
let confirmWrap: Element

interface Props {
    text?: string | JSX.Element,
    title?: string,
}

export const confirm = (props: Props) => {
    return new Promise((res, rej) => {
        function Confirm(props: Props) {
            const [shouldClose, close] = useState(false)
            return <Modal title={props.title || ''} shouldClose={shouldClose} cancel={true}
                onClose={() => {
                    res(false)
                    ReactDom.render(<></>, confirmWrap)
                }}
                onConfirm={() => {
                    res(true)
                    close(true)
                }} >
                <div style={{ minWidth: 330 }}>{props.text || ''}</div>
            </Modal>
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDom.render(<Confirm {...props} />, confirmWrap)
    })

}