import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'


// import { initiativeRefund } from './methods/initiativeRefund.ts';
// import { message } from 'antd'
import styled from 'styled-components'

const Logo = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: .6em;
`

const Out = styled.div`
    border: 1px solid #dedede;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 10px;
`

const Head = styled.div`
    background: #f6f6f6;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`

interface state {

}

interface props extends RouteComponentProps {
    data: propsOrder
}

interface propsOrder extends order {

}

class Item extends Component<props, state> {
    readonly state: state = {

    }


    render() {
        return (
            <Out className='orderItem'>
                <Head>
                    <div>
                        <img src={this.props.data.shopinfo.logo + '?imageView2/5/w/200'} alt={this.props.data.shopinfo.name} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 16 }} className='img-center' />
                    </div>
                    <div style={{ wordBreak: 'break-all' }} onClick={() => { console.log(this.props.data) }}>购买人：{this.props.data.shopinfo.name}</div>
                    <div className='orderDate' style={{ flex: 1, color: '#999', fontSize: 14 }}>{this.props.data.paytime}</div>
                    <div>订单号：{this.props.data.payorderid}</div>
                </Head>
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <tbody>
                        {this.props.data.items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className='orderItem__goodName'>
                                        <div className='fcc img-center'>
                                            <div><img alt='' src={item.logo + '?imageView2/5/w/200'} /></div>
                                            <div style={{ wordBreak: 'break-all' }}><span>{item.name}</span><span>({item.unit})</span></div>
                                        </div>
                                    </td>
                                    <td className='orderItem__price'>
                                        <div className='fcc'>￥{(item.price / 100).toFixed(2)}</div>
                                    </td>
                                    <td className='orderItem__num'>
                                        <div className='fcc'>x{item.num}</div>
                                    </td>

                                    {index === 0 && <td className='orderItem__totalPrice' rowSpan={this.props.data.items.length}>
                                        <div>共{this.props.data.items.length}件</div>
                                        {/* {Number(this.props.data.postfee) ? <div>运费￥{(this.props.data.postfee / 100).toFixed(2)}</div> : null} */}
                                        <div>应付金额：￥{(this.props.data.totalprice / 100).toFixed(2)}</div>
                                        {/* {(() => {
                                            if ([0, 100].includes(Number(this.props.data.discount))) return null
                                            const discount = Number(this.props.data.discount) / 10 + "折"
                                            const dp = (Number(this.props.data.discount_price_after) / 100).toFixed(2)
                                            return <div >会员优惠（{discount}）：<span style={{ display: 'inline-block' }}>-￥{dp}</span></div>
                                        })()}
                                        {this.props.data.couponlog_price ? <div>活动惊喜：<span style={{ display: 'inline-block' }}>-￥{(Number(this.props.data.couponlog_price) / 100).toFixed(2)}</span></div> : null} */}
                                    </td>}

                                    {index === 0 && <td className='orderItem__totalPrice' rowSpan={this.props.data.items.length}>
                                        <div>实付金额</div>
                                        <div style={{ color: '#ff5156' }}>￥{(Number(this.props.data.payprice) / 100).toFixed(2)}</div>
                                    </td>}
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan={5} style={{ padding: '8px 20px' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <span style={{ padding: '4px 11px 4px 0', minWidth: '4em', color: '#999' }}>买家备注：</span>
                                    <span style={{ display: 'inline-block', height: '100%', padding: ' 4px 11px 4px 0px' }}>
                                        <span style={{ maxWidth: '70%', wordBreak: 'break-all' }}>{this.props.data.remark}</span>
                                        {/* <i className='icon-xiugai iconfont' onClick={()=>{this.setState({setRemark: true})}} style={{marginLeft: 20}}></i> */}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} style={{ paddingRight: 8, padding: '4px 20px' }}>
                                <div className='fbc'>
                                    {this.props.data.buy_no ? <div>
                                        <span style={{ color: '#999' }}>接龙号：</span>
                                        <span style={{ color: '#333' }}>NO.{/[a-zA-Z]+/.exec(this.props.data.buy_no)}-</span>
                                        <span style={{ color: '#333', fontSize: '2em' }}>{/[0-9]+/.exec(this.props.data.buy_no)}</span>
                                    </div> : null}
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 5, flex: 1 }}>


                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} style={{ padding: '8px 20px', borderBottom: 0 }}>
                                <div style={{ fontWeight: 800 }}>
                                    <span style={{ color: "#999", fontWeight: 400 }}>订单状态：</span>{this.props.data.status_content}
                                </div>
                                {this.props.data.mch_remark ? (
                                    <div className='orderItem__mchRemark'>
                                        卖家备注：{this.props.data.mch_remark}
                                    </div>
                                ) : null}
                            </td>
                        </tr>
                        {(this.props.data.lasttuan_shopinfo && this.props.data.lasttuan_shopinfo.logo) ? <tr>
                            <td colSpan={5} style={{ padding: '8px 20px', borderBottom: 0 }}>
                                <div style={{ fontWeight: 800, display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: "#999", fontWeight: 400 }}>订单来源：</span>
                                    <div className='fcc'>
                                        <Logo>
                                            <img className='img-center' src={this.props.data.lasttuan_shopinfo.logo} alt='团长头像' />
                                        </Logo>
                                        <div>{this.props.data.lasttuan_shopinfo.name}</div>
                                    </div>
                                </div>
                            </td>
                        </tr> : null}
                        <tr>
                            <td colSpan={5} style={{ padding: '8px 20px', borderBottom: 0 }}>
                                <a target='_blank' rel="noopener noreferrer" href={`https://www.jieligo.net/tuan/${btoa(String(this.props.data.tuanid))}?from=manager`}>
                                    查看活动
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </Out>
        )
    }
}

const mapStateToProps = () => {
    return {
    }
}
const mapDispatchToProps = () => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Item))

export class FakeItem extends Component {
    render() {
        return <Out className='orderlistitem'>
            <Head >
                <div>
                    <div style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 16 }} className='img-center skeleton' />
                </div>
                <div className='orderDate skeleton' style={{ flex: 1, color: '#999', fontSize: 14 }}></div>
                <div className='skeleton'></div>
            </Head>
            <table style={{ width: '100%', tableLayout: 'fixed' }}>
                <tbody>
                    {[1, 1].map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className='orderItem__goodName'>
                                    <div className='skeleton'></div>
                                </td>
                                <td className='orderItem__price'>
                                    <div className='skeleton'></div>
                                </td>
                                <td className='orderItem__num'>
                                    <div className='skeleton'></div>
                                </td>
                                {index === 0 && <td className='orderItem__totalPrice' rowSpan={2}>
                                    <div className='skeleton'></div>
                                </td>}
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={5} style={{ padding: '8px 20px' }}>
                            <div className='skeleton' style={{ display: 'flex', alignItems: 'flex-start' }}></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5} style={{ paddingRight: 8, padding: '4px 20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 5 }}>
                                <div className='skeleton'></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5} style={{ padding: '8px 20px', borderBottom: 0 }}>
                            <div className='skeleton'></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Out>
    }
}