import React, { Component } from 'react'
import Content from '../../../high-components/Content';
import { RouteComponentProps, match, NavLink } from 'react-router-dom'
import { get } from '../../../netApi/fetch';
import styled from 'styled-components';
import Loading from '../../Methods/Loading';
interface props extends RouteComponentProps {
    match: Match
}

interface Match extends match {
    params: {
        type: string
    }
}

interface data {
    tuan_rank: tuan[],
    mall_rank: mall[],
    scan_rank: scan[],
    start_tuan_people_count: string | number,
    order_count: string | number,
    payprice_count: string | number,
    tuan_count: string | number,
    withdraw_to_wx: string | number,
    withdraw_to_bank: string | number,
    // order_count: string | number,
}

interface tuan {
    tuanid: string | number,
    orders_count: string | number,
    amount_sum: string | number,
    creator: string,
    title: string,
    sid: string,
}

interface mall {
    amount_sum: string
    branch_name: string
    mall_id: string
    mall_logo: string
    mall_name: string
    mall_pid: string
    mall_relation_id: string
    orders_count: string
    relation_pid: string
    shop_logo: string
    shop_name: string
    sid: string
}

interface scan {
    amount_sum: string
    creator: string
    orders_count: string
    sid: string
}

interface state {
    time_type: string | number,
    data?: data,
    dataType: 'tuan' | 'mall' | 'scan'
}

const Wrap = styled.div`
    border-radius:8px;
    border:1px solid rgba(204,204,204,1);
`

const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #454545;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    &.active{
        color: #ff5156;
    }
`

const Head = styled.div`
    padding: 0 30px;
    display: flex;
    align-items:center;
    border-bottom:1px solid rgb(204,204,204);
`

const Data = styled.div`
    padding: 30px 30px 0;
    display: flex;
    align-items:center;
    flex-wrap: wrap;
`

const DataItem = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    margin-bottom: 30px;
    & div:first-child{
        font-size: 12px;
        color: rgba(0,0,0,.45);
        margin-bottom: 1em;
    }
    & div:last-child{
        font-size:24px;
        font-weight:500;
        color:rgba(0,0,0,0.65);
    }
`

const TuanItem = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: #e9e9e9 1px solid;
    padding: 23px;
    &>:first-child{
        font-size:18px;
        font-weight:500;
        color:rgba(51,51,51,1);
        width: 2em;
    }
    &>:last-child{
        flex: 1;
    }
`

class SaleData extends Component<props>{

    readonly state: state = {
        time_type: '',
        dataType: 'tuan'
    }

    componentDidMount() {
        const time_type = this.props.match.params.type
        this.setType(time_type)
    }

    componentDidUpdate() {
        if (this.props.match.params.type !== this.state.time_type) {
            const time_type = this.props.match.params.type
            this.setType(time_type)
        }
    }

    render() {
        const time_type = this.props.match.params.type
        return <div style={{ padding: '40px 24px' }}>
            <Title>数据统计</Title>
            <Wrap>
                <Head>
                    {[
                        {
                            title: '今日',
                            value: 'today',
                        },
                        {
                            title: '昨日',
                            value: 'yesterday',
                        },
                        {
                            title: '前日',
                            value: 'the_day_before_yesterday',
                        },
                        {
                            title: '本周',
                            value: 'this_week',
                        },
                        {
                            title: '上周',
                            value: 'last_week',
                        },
                        {
                            title: '本月',
                            value: 'this_month',
                        },
                        {
                            title: '上月',
                            value: 'last_month',
                        },
                    ].map(i => {
                        return <div key={i.value} className='cur-pointer' style={{
                            padding: '16px 4px',
                            marginRight: '1em',
                            boxSizing: 'border-box',
                            borderBottom: i.value === time_type ? 'solid #ff5156 4px' : 0,
                            fontSize: i.value === time_type ? 16 : 12,
                            color: i.value === time_type ? '#ff5156' : '',
                        }} onClick={() => {
                            this.props.history.push(`/data/${i.value}`)
                        }}>
                            <span>{i.title}</span>
                        </div>
                    })}
                </Head>

                {this.state.data ? <Data>
                    {(() => {
                        const { payprice_count, tuan_count, start_tuan_people_count, order_count, withdraw_to_bank, withdraw_to_wx } = this.state.data
                        return <>
                            <DataItem>
                                <div>入账金额（包含退款）</div>
                                <div>￥{(Number(payprice_count || 0) / 100).toFixed(2)}</div>
                            </DataItem>
                            <DataItem>
                                <div>开团数量</div>
                                <div>{(Number(tuan_count || 0)).toFixed(0)}</div>
                            </DataItem>
                            <DataItem>
                                <div>开团人数</div>
                                <div>{(Number(start_tuan_people_count || 0)).toFixed(0)}</div>
                            </DataItem>
                            <DataItem>
                                <div>订单数（包含退款）</div>
                                <div>{(Number(order_count || 0)).toFixed(0)}</div>
                            </DataItem>
                            <DataItem>
                                <div>提现到微信</div>
                                <div>￥{(Number(withdraw_to_wx || 0) / 100).toFixed(2)}</div>
                            </DataItem>
                            <DataItem>
                                <div>提现到银行卡</div>
                                <div>￥{(Number(withdraw_to_bank || 0) / 100).toFixed(2)}</div>
                            </DataItem>
                        </>
                    })()}
                </Data> : null}
                {this.state.data ? <div style={{ padding: 20 }}>
                    <div className="flex-box">
                        <Title className={this.state.dataType === 'tuan' ? 'active' : ''} onClick={() => {
                            this.setState({ dataType: 'tuan' })
                        }}>团购销售排名</Title>
                        <Title className={this.state.dataType === 'mall' ? 'active' : ''} onClick={() => {
                            this.setState({ dataType: 'mall' })
                        }}>商城销售排名</Title>
                        <Title className={this.state.dataType === 'scan' ? 'active' : ''} onClick={() => {
                            this.setState({ dataType: 'scan' })
                        }}>扫码销售排名</Title>
                    </div>
                    {this.state.dataType === 'tuan' && this.state.data.tuan_rank.map((i: tuan, idx: number) => {
                        return <TuanItem key={i.tuanid}>
                            <div>{idx + 1}.</div>
                            <div style={{ fontSize: 12 }}>
                                <a target='_blank' rel="noopener noreferrer" href={`https://www.jieligo.net/tuan/${btoa(String(i.tuanid))}?from=manager`} style={{ display: 'block', marginBottom: 16, fontSize: 14, color: '#454545' }}>{i.title}</a>
                                <div style={{ marginBottom: 16 }}>
                                    <span style={{ color: '#999' }}>创建者：</span>
                                    {/* <NavLink target='_blank' to={`/user/spec?s=${i.creator}&p=1&op=all`}> */}
                                    <NavLink target='_blank' to={`/user/detail/${i.sid}?mode=1`}>
                                        <span style={{ color: '#333' }} className='cur-pointer'>{i.creator}</span>
                                    </NavLink>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginRight: 30 }}>
                                        <span style={{ color: '#999' }}>订单数：</span>
                                        <span style={{ color: '#333' }}>{i.orders_count}</span>
                                    </span>
                                    <span>
                                        <span style={{ color: '#999' }}>销售额：</span>
                                        <span style={{ color: '#333' }}>￥{(Number(i.amount_sum) / 100).toFixed(2)}</span>
                                    </span>
                                </div>
                            </div>
                        </TuanItem>
                    })}
                    {this.state.dataType === 'mall' && this.state.data.mall_rank.map((i: mall, idx: number) => {
                        return <TuanItem key={i.mall_relation_id}>
                            <div>{idx + 1}.</div>
                            <div style={{ fontSize: 12 }}>
                                <div style={{ display: 'block', marginBottom: 16, fontSize: 14, color: '#454545' }}>{i.mall_name}【{i.branch_name}】</div>
                                <div style={{ marginBottom: 16 }}>
                                    <span style={{ color: '#999' }}>创建者：</span>
                                    {/* <NavLink target='_blank' to={`/user/spec?s=${i.creator}&p=1&op=all`}> */}
                                    <NavLink target='_blank' to={`/user/detail/${i.sid}?mode=1`}>
                                        <span style={{ color: '#333' }} className='cur-pointer'>{i.shop_name}</span>
                                    </NavLink>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginRight: 30 }}>
                                        <span style={{ color: '#999' }}>订单数：</span>
                                        <span style={{ color: '#333' }}>{i.orders_count}</span>
                                    </span>
                                    <span>
                                        <span style={{ color: '#999' }}>销售额：</span>
                                        <span style={{ color: '#333' }}>￥{(Number(i.amount_sum) / 100).toFixed(2)}</span>
                                    </span>
                                </div>
                            </div>
                        </TuanItem>
                    })}
                    {this.state.dataType === 'scan' && this.state.data.scan_rank.map((i: scan, idx: number) => {
                        return <TuanItem key={i.sid}>
                            <div>{idx + 1}.</div>
                            <div style={{ fontSize: 12 }}>
                                <div style={{ display: 'block', marginBottom: 16, fontSize: 14, color: '#454545' }}>{i.creator}</div>
                                <div style={{ marginBottom: 16 }}>
                                    <span style={{ color: '#999' }}>创建者：</span>
                                    {/* <NavLink target='_blank' to={`/user/spec?s=${i.creator}&p=1&op=all`}> */}
                                    <NavLink target='_blank' to={`/user/detail/${i.sid}?mode=1`}>
                                        <span style={{ color: '#333' }} className='cur-pointer'>{i.creator}</span>
                                    </NavLink>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginRight: 30 }}>
                                        <span style={{ color: '#999' }}>订单数：</span>
                                        <span style={{ color: '#333' }}>{i.orders_count}</span>
                                    </span>
                                    <span>
                                        <span style={{ color: '#999' }}>销售额：</span>
                                        <span style={{ color: '#333' }}>￥{(Number(i.amount_sum) / 100).toFixed(2)}</span>
                                    </span>
                                </div>
                            </div>
                        </TuanItem>
                    })}
                </div> : null}
            </Wrap>
        </div>
    }

    getData() {
        Loading.show('加载中')
        const time_type = this.state.time_type
        this.setState({
            data: undefined
        })
        get('/file/counttuandata', {
            time_type,
            types: 1,
        }).then((res: data) => {
            if (this.state.time_type !== time_type) return
            console.log(res);
            this.setState({ data: res })
            Loading.hide()
        }).catch(() => {
            Loading.hide()
        })
    }

    setType(type: string | number) {
        this.setState({
            time_type: type
        }, (() => {
            this.getData()
        }))
    }
}

export default Content(SaleData)