
const parseParams: (data: string) => { [key: string]: string } = function (data: string): { [key: string]: string } {
    let str: string = data
    let ret: { [key: string]: string } = {}
    if (data[0] === '?') {
        str = data.slice(1)
    }

    let paramsArr: any[] = str.split('&')
    paramsArr = paramsArr.map(item => {
        return item.split('=')
    })

    for (let i = 0; i < paramsArr.length; i++) {
        const item = paramsArr[i];
        ret[`${item[0]}`] = item[1]
    }

    return ret
}
export default parseParams 