
export const OFFSET_LIMIT = 30

export const appname = 'jieligo'
// export const appname = 'bushubao'

console.log(process.env.NODE_ENV)

export const QQ_MAP_KEY = 'UL7BZ-EJCKP-CIQD3-LUJUC-OLQ2F-26BFS'

export const authkey = '1IUNQug7BiSdtoF27Vx9ljNSXKid3T8W'
export const platform = 'h5'

export const API = (() => {
    let state = ''
    if (process && process.env && process.env.NODE_ENV) state = process.env.NODE_ENV
    switch (state) {
        case 'development':
            // return 'https://api.jieligo.net'
            // return 'https://dev.jieligo.net'
            // return 'http://www.tuan.com/index.php'
            // return 'https://test.jieligo.net'
            return 'https://test.groupai.net'
        // eslint-disable-next-line no-fallthrough
        default:
            // return 'https://test.groupai.net'
            return 'https://api.jieligo.net'
    }
})()