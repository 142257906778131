const list = [
    // reg match
    // '/payment',
    // 'banSidebar',
    // '/print',
    // 'importexcel',
    // '/tuan/',
    // 'SelectGood',
    // 'settle',
    // 'Myorder',
    // 'Success',
    // 'orderdetail',
    // 'home',
]
export default list