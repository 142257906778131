import React, { Component } from 'react'

import ReactDOM from 'react-dom';

import styled from 'styled-components';

const View = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index:999;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoadingPanel = styled.div`
    background: rgba(0,0,0,.4);
    width: 90px;
    height: 90px;
    border-radius: 15px;
    color: #fff;
    flex-direction: column;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Icon = styled.i`
    line-height: 1;
    animation: rotate 1.5s ease-in-out 0s infinite;
    font-size: 32px;
    margin-bottom: 12px;
    &::before{
        animation: none !important;
    }
`

interface Props {
    message?: string
}

let wrapper: Element

class Loading extends Component<Props> {
    render() {
        return <View>
            <LoadingPanel>
                <Icon className='iconfont icon-xingzhuang'></Icon>
                {this.props.message ? <div>{this.props.message}</div> : null}
            </LoadingPanel>
        </View>
    }

}

function showLoading(message?: string): void {
    if (!wrapper) {
        wrapper = document.createElement('div') as Element
        document.body.appendChild(wrapper)
    }
    ReactDOM.render(<Loading message={message} />, wrapper)
}

function hideLoading(): void {
    if (!wrapper) {
        wrapper = document.createElement('div') as Element
        document.body.appendChild(wrapper)
    }
    ReactDOM.render(<></>, wrapper)
}


export default {
    show: showLoading,
    hide: hideLoading,
}