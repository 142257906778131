
import React, { Component } from 'react'
import Content from '../../../high-components/Content';
import styled from 'styled-components';
import * as H from 'history';
import { RouteComponentProps, NavLink, match } from 'react-router-dom'
import { get, post } from '../../../netApi/fetch';
import { user, Logo } from './User'
import Tuan from './component/Item';
import { Pagination, message } from 'antd';
import Modal from '../../total/Modal';
import Select from '../../total/Select_';
import Search from '../../total/Input/Search';
import UserAuthen from './UserAuthen';
import OrderItem from '../BannedUser/item';
import { confirm } from '../../Methods/Confirm';
import Loading from '../../Methods/Loading';
interface Props extends RouteComponentProps {
    location: location,
    match: Match
}
interface location extends H.Location {
    state: {
        user: user
    }
}
interface Match extends match {
    params: {
        id: number
    }
}
interface State {
    mode?: number
    recordList?: any[]
    turnoverList?: any[]
    tuanlist?: any[]
    page: number
    count: number
    settingUser?: user
    settingOp?: number
    setting?: boolean
    showChange?: boolean
    search?: string
    changeloading?: boolean
    userlistCtrl: {
        page: number,
        limit: number,
        search: string,
        op: string,
    }
    userlist: any[],
    selectedSid?: string | number,
    usercount: number
    showChangeConfirm: boolean,
    selectedUser?: any
    userFan?: number | string,
    userAuthenShow: boolean,
    iscertified: boolean,
    user?: user,

    exception_amount?: number,
    orderList?: order[],
    hasBeenBanned: boolean,
    exceptionid: string,
    setST?: boolean,
}
const Border = styled.div`
    border-radius:8px;
    border:1px solid rgba(204,204,204,1);
`
const TopData = styled.div`
    padding: 20px;
    margin-bottom: 30px;    
    border-radius:8px;
    border:1px solid rgba(204,204,204,1);
`
const Line = styled.div`
    width:1px;
    height:40px;
    background:rgba(233,233,233,1);
    margin-right: 30px;
`
const Name = styled.div`
    font-weight: 600;
    color: #330000;
    font-size: 14px;
    max-width: 6em;
    min-width: 6em;
    word-wrap:break-word;
    word-break:break-all;
`

const tip: { value: string }[] = [] // 提现费率
for (let i = 0; i <= 100; i++) {
    tip[i] = { value: (i / 100).toFixed(2) }
}

class Detail extends Component<Props>{

    readonly state: State = {
        page: 1,
        count: 0,
        userlistCtrl: {
            page: 1,
            limit: 10,
            search: '',
            op: 'all'
        },
        userlist: [],
        usercount: 0,
        showChangeConfirm: false,
        userAuthenShow: false,
        iscertified: false,
        hasBeenBanned: false,
        exceptionid: '',
    }

    sid?: number
    exid?: string
    componentDidMount() {
        console.log();
        this.setState({
            hasBeenBanned: /bu/.test(this.props.location.pathname)
        })
        // if (!this.props.location.state || !this.props.location.state.user) {
        //     this.props.history.goBack()
        // }
        const query = (this.getSearch())
        console.log(query)
        this.sid = this.props.match.params.id
        const mode = query.find((i: any) => i[0] === 'mode') || ['mode', 1]
        const exid = query.find((i: any) => i[0] === 'exid')
        if (exid) this.exid = exid[1]
        if (this.props.location.state && this.props.location.state.user) {
            this.setState({ iscertified: !!(this.props.location.state.user.iscertified) })
        }
        this.setState({ mode: Number(mode[1]), page: 1, }, () => {
            this.getData()
        })
    }
    componentDidUpdate() {
        const query = (this.getSearch())
        const mode = query.find((i: any) => i[0] === 'mode') || ['mode', 1]
        if (Number(mode[1]) !== this.state.mode) {
            this.setState({ mode: Number(mode[1]), page: 1 }, () => {
                this.getData()
            })
        }
    }

    render() {
        if ((!this.props.location.state || !this.props.location.state.user) && !this.state.user) return ''
        const user: user = this.state.user || this.props.location.state.user
        console.log(user);
        return (<div style={{ padding: 20, margin: ' 0 auto', maxWidth: 976, width: '100%' }}>
            <TopData className='fbc'>
                <div className='flex-box' style={{ height: '100%', flexDirection: 'column', alignItems: 'center', marginRight: '1em', alignSelf: 'flex-start', }}>
                    <Logo src={user.userlogo} />
                    <button onClick={() => {
                        confirm({
                            text: '确定封禁该账号？'
                        }).then(res => {
                            console.log(user.sid);
                            if (res) {
                                const data = {
                                    op: 'set_exception_account',
                                    sid: user.sid
                                }
                                post('/god/handleexceptionshop', data).then(() => {
                                    message.success('操作成功')
                                }).catch((e: string) => {
                                    message.error(e)
                                })
                            }
                        })
                    }} style={{ fontSize: 12, border: 0, borderRadius: 4, marginTop: 12, cursor: 'pointer'}}>封禁</button>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ fontSize: 16, marginBottom: '1em' }}>
                        <span style={{ marginRight: 12 }}>{user.username}</span>
                        <span style={{ color: '#999', marginRight: 12 }}>{user.typeText}</span>
                        <span style={{ color: '#999', marginRight: 12, fontSize: 12, cursor: 'pointer' }} title='点击修改提现费率' onClick={() => { this.setState({ setST: true }) }}>提现费率：{user.service_tip}%</span>
                        {this.state.setST ? <div style={{ display: 'inline-flex', alignItems: 'center', fontSize: 12 }}>
                            <span>费率比例</span>
                            <div style={{ display: 'inline-block', width: 100 }}>
                                <Select maxHeight={300} canSearch onChange={(e: any) => {
                                    console.log(e);
                                    if (!e.value) return
                                    if (e.value !== user.service_tip) {
                                        Loading.show()
                                        post('/god/setshopconfig', { types: 1, op: 1, rate: e.value, sid: user.sid }).then(() => {
                                            Loading.hide()
                                            user.service_tip = e.value
                                            this.setState({ user })
                                        }).catch((e: string) => {
                                            Loading.hide()
                                            message.error(e)
                                        })
                                    }
                                }} uni={`${user.sid}st`} list={tip} showKey='value' value={tip.findIndex((i: any) => i.value === Number(user.service_tip).toFixed(2))} />
                            </div>
                        </div> : null}
                        {user.type === 5 ? <span onClick={() => {
                            this.setState({
                                setting: true,
                                settingUser: user
                            })
                        }} className='cur-pointer' style={{ display: 'inline-block', background: '#FF5156', color: '#fff', borderRadius: 15, fontSize: 14, padding: 5 }}>
                            设为特邀会员
                        </span> : null}
                        {this.state.hasBeenBanned ? <div className='flex-between' style={{ maxWidth: 110 }}>
                            <button className='button button_hollow_red' onClick={() => {
                                if (this.state.exceptionid === '') return
                                const num = prompt('请输入扣款金额')
                                if (!!!Number(num)) {
                                    message.error('请输入合法数字')
                                    return
                                }
                                const data = {
                                    op: 'deduct_money',
                                    id: this.state.exceptionid,
                                    amount: Number(num) * 100
                                }
                                post('/god/handleexceptionshop', data).then(() => {
                                    this.getData()
                                }).catch((e: string) => {
                                    message.error(e)
                                })
                            }}>扣钱</button>
                            <button className='button button_hollow_red' onClick={() => {
                                if (this.state.exceptionid === '') return
                                confirm({
                                    text: '确定解封该账号？'
                                }).then(res => {
                                    if (res) {
                                        const data = {
                                            op: 'relieve',
                                            id: this.state.exceptionid,
                                        }
                                        post('/god/handleexceptionshop', data).then(() => {
                                            this.getData()
                                        }).catch((e: string) => {
                                            message.error(e)
                                        })
                                    }
                                })
                            }}>解封</button>
                        </div> : null}
                    </div>
                    <div className='fbc' style={{ display: 'inline-flex' }} >
                        <div style={{ width: 150 }}>
                            <div style={{ color: 'rgba(0,0,0,0.45)', fontSize: 12 }}>可提现金额</div>
                            <div style={{ fontSize: 24, color: 'rgba(0,0,0,0.65)', fontWeight: 600, }}>
                                ￥{(Number(user.account_total) / 100).toFixed(2)}
                            </div>
                        </div>
                        <Line />
                        {user.income_total ? <div style={{ width: 150 }}>
                            <div style={{ color: 'rgba(0,0,0,0.45)', fontSize: 12 }}>历史总流水</div>
                            <div style={{ fontSize: 24, color: 'rgba(0,0,0,0.65)', fontWeight: 600, }}>
                                ￥{(user.income_total / 100).toFixed(2)}
                            </div>
                        </div> : null}
                        {this.state.exception_amount ? <><Line />
                            <div style={{ width: 150 }}>
                                <div style={{ color: 'rgba(0,0,0,0.45)', fontSize: 12 }}>异常订单金额</div>
                                <div style={{ fontSize: 24, color: 'rgba(0,0,0,0.65)', fontWeight: 600, }}>
                                    ￥{(this.state.exception_amount / 100).toFixed(2)}
                                </div>
                            </div></> : null}
                    </div>
                </div>
                <div style={{ alignSelf: 'flex-start', padding: '0 20px' }}>
                    <i className={`iconfont ${this.state.iscertified ? 'icon-yirenzheng1' : 'icon-weirenzheng1'}`} style={{
                        color: this.state.iscertified ? '#ff5156' : '#999'
                    }} />
                    {!this.state.iscertified ? <div className='cur-pointer' style={{ color: '#ff5156', fontSize: 12 }} onClick={() => { this.setState({ userAuthenShow: true }) }}>人工认证身份</div> : null}
                </div>
                <Line />
                <div>
                    <div style={{ fontSize: 12, color: '#858585', marginBottom: '1em' }}>TA的上级</div>
                    <div className='fcc'>
                        <Logo style={{ marginRight: '1em', }} src={user.p_logo} />
                        <div style={{ fontWeight: 600, color: '#300' }}>{user.p_shopname}</div>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 6 }}>
                        <button className='button_solid_red' onClick={() => { this.setState({ showChange: true }) }}>更改上级</button>
                    </div>
                </div>
            </TopData>

            <Border>
                <div style={{ display: 'flex', alignItems: 'center', padding: '15px 20px', borderBottom: '1px solid #ccc' }}>
                    <div onClick={() => { this.props.history.push({ pathname: `/user/detail/${user.sid}`, state: { user }, search: 'mode=1' }) }} className='cur-pointer' style={{ fontSize: this.state.mode === 1 ? 16 : 12, color: this.state.mode === 1 ? '#ff5156' : '#666', marginRight: 30 }}>TA的发布</div>
                    <div onClick={() => { this.props.history.push({ pathname: `/user/detail/${user.sid}`, state: { user }, search: 'mode=2' }) }} className='cur-pointer' style={{ fontSize: this.state.mode === 2 ? 16 : 12, color: this.state.mode === 2 ? '#ff5156' : '#666', marginRight: 30 }}>提现记录</div>
                    <div onClick={() => { this.props.history.push({ pathname: `/user/detail/${user.sid}`, state: { user }, search: 'mode=3' }) }} className='cur-pointer' style={{ fontSize: this.state.mode === 3 ? 16 : 12, color: this.state.mode === 3 ? '#ff5156' : '#666', marginRight: 30 }}>流水明细</div>
                    {this.state.hasBeenBanned ? <>
                        <div onClick={() => { this.props.history.push({ pathname: `/user/detail/${user.sid}`, state: { user }, search: 'mode=4' }) }} className='cur-pointer' style={{ fontSize: this.state.mode === 4 ? 16 : 12, color: this.state.mode === 4 ? '#ff5156' : '#666', marginRight: 30 }}>异常订单</div>
                    </> : null}
                </div>
                <div>
                    {this.state.mode === 2 && this.state.recordList ? <>
                        {this.state.recordList.map((item: any) => {
                            return <div key={item.id} className='fbc' style={{ borderBottom: '1px solid #ccc', padding: 20 }}>
                                <Logo style={{ marginRight: 20, }} src={item.shopinfo.logo} />
                                <div style={{ flex: 1, }}>
                                    <div>{item.log}</div>
                                    <div>{item.createtime}</div>
                                </div>
                                <div style={{ color: Number(item.operate) === 1 ? '#FF9A27' : '#333', fontSize: 18, fontWeight: 600 }}>
                                    {Number(item.operate) === 1 ? '+' : '-'}￥{(Number(item.number) / 100).toFixed(2)}
                                </div>
                            </div>
                        })}
                    </> : null}
                    {this.state.mode === 3 && this.state.turnoverList ? <>
                        {this.state.turnoverList.map((item: any) => {
                            return <div key={item.id} className='fbc' style={{ borderBottom: '1px solid #ccc', padding: 20 }}>
                                <NavLink target='_blank' to={`/user/detail/${item.shopinfo.id}?mode=3`}>
                                    <Logo title={item.shopinfo.name} alt={item.shopinfo.name} style={{ marginRight: 20, }} src={item.shopinfo.logo} />
                                </NavLink>
                                <div style={{ flex: 1, }}>
                                    <div>{item.log}</div>
                                    <div>{item.createtime}</div>
                                </div>
                                <div style={{ color: Number(item.operate) === 1 ? '#FF9A27' : '#333', fontSize: 18, fontWeight: 600 }}>
                                    {Number(item.operate) === 1 ? '+' : '-'}￥{(Number(item.number) / 100).toFixed(2)}
                                </div>
                            </div>
                        })}
                    </> : null}
                    {this.state.mode === 1 && this.state.tuanlist ? <>
                        {this.state.tuanlist.map((item: any) => {
                            return <Tuan key={item.id} data={item} noCtrl toHover={() => { }} ></Tuan>
                        })}
                    </> : null}
                    {this.state.mode === 4 && this.state.orderList ? <div style={{ padding: 20 }}>
                        {this.state.orderList.map((item: order) => {
                            return <OrderItem data={item} key={item.orderid} />
                        })}
                    </div> : null}
                </div>
                <div className='fcc' style={{ padding: 12 }}>
                    <Pagination total={Number(this.state.count)} current={this.state.page} pageSize={20} onChange={e => {
                        this.setState({ page: e }, this.getData)
                    }} />
                </div>
            </Border>

            {this.state.showChange ? <Modal title='更改TA的上级' loading={this.state.changeloading} onClose={() => {
                this.setState({ showChange: false, changeloading: false })
            }} onConfirm={async () => {
                this.setState({ showChangeConfirm: true })
                const { count } = await get('/god/getallnextshopcount', { types: 1, sid: user.sid || '' })
                this.setState({ userFan: count })
            }} >
                <Search onChange={(e: string) => { this.setState({ search: e }) }} placeholder='搜索用户微信昵称或小程序昵称' search={this.state.search || ''} onSearch={() => {
                    this.setState({ changeloading: true })
                    this.setState({ userlistCtrl: { ...this.state.userlistCtrl, page: 1, search: this.state.search || '' } }, async () => {
                        const list = await this.getUserlist()
                        console.log(list)
                        this.setState({ changeloading: false })
                    })
                }} />
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 12 }}>
                    <div style={{ minWidth: 30, maxWidth: 30 }}></div>
                    <div style={{ minWidth: '6em' }}>微信头像</div>
                    <div style={{ minWidth: '6em', wordBreak: 'break-all' }}>微信昵称</div>
                    <div style={{ minWidth: '6em' }}>小程序头像</div>
                    <div style={{ minWidth: '6em', wordBreak: 'break-all' }}>小程序昵称</div>
                </div>
                <div style={{ overflowY: 'auto', maxHeight: '40vh' }}>
                    <div style={{ maxHeight: '40vh' }}>
                        {this.state.userlist.map((i: any) => {
                            return <div className='cur-pointer' style={{ display: 'flex', alignItems: 'center', marginTop: 12 }} key={i.sid} onClick={() => { this.setState({ selectedSid: i.sid, selectedUser: i, userFan: '' }) }}>
                                <div style={{ minWidth: 30, maxWidth: 30 }}>
                                    <i style={{ color: i.sid === this.state.selectedSid ? '#ff5156' : '' }} className={`${i.sid === this.state.selectedSid ? 'icon-gouxuan' : 'icon-weixuanzhong'} iconfont`} />
                                </div>
                                <div style={{ minWidth: '6em' }}>
                                    <Logo src={i.userlogo} />
                                </div>
                                <div style={{ minWidth: '6em', wordBreak: 'break-all', maxWidth: '6em' }}>{i.username}</div>
                                <div style={{ minWidth: '6em' }}>
                                    <Logo src={i.shoplogo} />
                                </div>
                                <div style={{ minWidth: '6em', wordBreak: 'break-all', maxWidth: '6em' }}>{i.shopname}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                    <Pagination total={Number(this.state.usercount)} pageSize={this.state.userlistCtrl.limit} current={this.state.userlistCtrl.page} onChange={e => {
                        this.setState({
                            changeloading: true,
                            userlistCtrl: {
                                ...this.state.userlistCtrl,
                                page: e
                            }
                        }, () => { this.getUserlist().then(() => { this.setState({ changeloading: false }) }) })
                    }} />
                </div>
                {this.state.showChangeConfirm ? <Modal title={'确定更改TA为' + user.shopname + '的上级？'} onClose={() => {
                    this.setState({ showChangeConfirm: false })
                }} onConfirm={() => {
                    post('/god/changepid', { types: 1, sid: user.sid, change_pid: this.state.selectedUser.sid }).then((res: any) => {
                        message.success('设置成功')
                        this.props.history.goBack()
                    }).catch((err: string) => {
                        message.error(err)
                    })
                }} >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 12 }}>
                        <div style={{ minWidth: '6em' }}>微信头像</div>
                        <div style={{ minWidth: '6em', wordBreak: 'break-all' }}>微信昵称</div>
                        <div style={{ minWidth: '6em' }}>小程序头像</div>
                        <div style={{ minWidth: '6em', wordBreak: 'break-all' }}>小程序昵称</div>
                    </div>
                    <div className='cur-pointer' style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ minWidth: '6em' }}>
                            <Logo src={this.state.selectedUser.userlogo} />
                        </div>
                        <div style={{ minWidth: '6em', wordBreak: 'break-all', maxWidth: '6em' }}>{this.state.selectedUser.username}</div>
                        <div style={{ minWidth: '6em' }}>
                            <Logo src={this.state.selectedUser.shoplogo} />
                        </div>
                        <div style={{ minWidth: '6em', wordBreak: 'break-all', maxWidth: '6em' }}>{this.state.selectedUser.shopname}</div>
                    </div>
                    {(() => {
                        return (<div>绑定在{user.shopname}下面的粉丝共<span>{this.state.userFan}</span>人</div>)
                    })()}
                </Modal> : null}
            </Modal> : null}



            {this.state.setting && this.state.settingUser ? <Modal title={`将${this.state.settingUser.shopname}设为会员`} onClose={() => { this.setState({ setting: false, settingUser: undefined }) }} onConfirm={() => {
                if (!this.state.settingUser) return
                const item = this.state.settingUser
                const data = { sid: item.sid, types: 1, proxy_type: this.state.settingOp }
                console.log(item, data)
                post('/god/setfreevip', data).then(() => {
                    this.setState({ list: [] })
                    this.getData()
                    message.success('设置成功')
                }).catch((res: string) => message.error(res))
            }}>
                <div style={{ width: 500 }}>
                    <div style={{ width: 350 }}>
                        <div className='fbc'>
                            <div>微信</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.userlogo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.username}</Name>
                            </div>
                        </div>
                        <div className='fbc'>
                            <div>小程序</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.shoplogo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.shopname}</Name>
                            </div>
                        </div>
                        <div className='fbc'>
                            <div>上级</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.p_logo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.p_shopname}</Name>
                            </div>
                        </div>
                    </div>
                    <div>
                        将{this.state.settingUser.shopname}设为
                        <span><Select list={[
                            { name: '黄金供应商', op: 1 },
                            { name: '钻石供应商', op: 2 },
                            { name: '高级会员', op: 4 },
                            { name: '特邀会员', op: 6 },
                            { name: '设置0.006', op: 7 },
                        ]} showKey={'name'} uni='proxy_type' value={[
                            { name: '黄金供应商', op: 1 },
                            { name: '钻石供应商', op: 2 },
                            { name: '高级会员', op: 4 },
                            { name: '特邀会员', op: 6 },
                            { name: '设置0.006', op: 7 },
                        ].findIndex(i => { return this.state.settingOp === i.op })} onChange={e => {
                            if (e.op === this.state.settingOp) return
                            this.setState({ settingOp: e.op })
                        }} /></span>
                    </div>
                </div>
            </Modal> : null}

            {this.state.userAuthenShow
                ? <UserAuthen sid={user.sid} onClose={(e: boolean) => {
                    if (e) {
                        this.setState({ iscertified: true })
                        console.log(e);
                    }
                    this.setState({ userAuthenShow: false })
                }} />
                : null
            }

        </div>)
    }

    getSearch() {
        if (!this.props.location.search) return
        let search: any = this.props.location.search
        if (search[0] === '?') search = search.slice(1)
        search = search.split('&').map((str: string) => {
            return str.split('=')
        })
        return search
    }

    async getData() {
        if (!this.sid) {
            this.props.history.push('/home')
            return
        }
        const sid = this.sid
        const offset = (this.state.page - 1) * 20
        get('/god/selectalluserinfolist', {
            op: 'one',
            sid,
            types: 1
        }).then((res: any) => {
            console.log(res);
            if (!res.list || !res.list[0]) {
                message.error('错误')
                return
            }
            this.setState({ user: res.list[0] })
            this.setState({ iscertified: !!(res.list[0].iscertified) })
        })
        try {
            if (this.state.mode === 2) {
                const { list, count } = await get('/shop/getdealrecord', {
                    sid,
                    op: 'all',
                    offset,
                    limit: 20,
                    types: 1,
                    from: 'transfer'
                })
                this.setState({
                    recordList: list,
                    count,
                })
            }
            if (this.state.mode === 3) {
                const { list, count } = await get('/shop/getdealrecord', {
                    sid,
                    op: 'all',
                    offset,
                    limit: 20,
                    types: 1,
                    from: ''
                })
                this.setState({
                    turnoverList: list,
                    count,
                })
            }
            if (this.state.mode === 1) {
                const { list, count } = await get('/content/getcontentlist', {
                    viewtosid: sid,
                    types: 1,
                    from: 'managerbyone',
                    offset,
                    limit: 20,
                    app: 'jieligo',
                })
                this.setState({
                    tuanlist: list,
                    count,
                })
            }

            if (this.state.mode === 4) {
                this.setState({
                    orderList: []
                })
                if (!this.exid) {
                    message.error('缺少参数')
                    return
                }
                const data = await get('/god/getexceptionshoplistororderlist', {
                    id: this.exid,
                    type: 'orderlist',
                    offset,
                    limit: 20,
                })
                const { list, count, exception_amount, id } = data
                this.setState({
                    orderList: list,
                    count: Number(count),
                    exception_amount: Number(exception_amount),
                    exceptionid: id
                })
            }
        } catch (error) {
            message.error(error)
        }
    }

    getUserlist() {
        return new Promise(async (resolve, reject) => {
            try {
                const data: any = {
                    ...this.state.userlistCtrl,
                    offset: (this.state.userlistCtrl.page - 1) * this.state.userlistCtrl.limit
                }
                delete data.page
                const { list, count } = await get('/god/selectalluserinfolist', { types: 1, ...data, })
                this.setState({ userlist: list, usercount: count })
                resolve(list)
            } catch (err) {
                reject(err)
            }
        })

    }
}

export default Content(Detail)