import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { get } from '../../../netApi/fetch';
import Search from '../../total/Input/Search';
import Modal from '../../total/Modal';
let confirmWrap: Element

interface Props {
    selectedTuanIds: number[]
}

interface canSelectTuanItem {
    id: string
    logo: string
    name: string
    price: string
    title: string
}

export default (props: Props) => {
    return new Promise((res: (res: false | {data: any[], id: number[]}) => void, rej) => {
        function Confirm(props: Props) {
            const [shouldClose, close] = useState(false)

            const [list, setList] = useState<canSelectTuanItem[]>()
            const [searchKey, setSearchKey] = useState('')
            const search = useRef('')

            const [selectedTuanIds, setSelectedTuanIds] = useState<number[]>([])

            const selectedTuanDataList = useRef<canSelectTuanItem[]>([])

            const [loading, setLoading] = useState(false)

            useEffect(() => {
                if(props.selectedTuanIds){
                    setSelectedTuanIds(props.selectedTuanIds)
                }
            }, [props.selectedTuanIds])

            useEffect(() => {
                if (list) return
                get('/tuan/getcanchoosealltuanbytheme', {
                    offset: 0, limit: 20, search: search.current
                }).then((res: any) => {
                    setSearchKey(search.current)
                    setList(res.list)
                })
            }, [list])

            const getList = useCallback(() => {
                if (!list) return
                if (loading) return
                const rqdata = {
                    offset: list.length,
                    limit: 20,
                    search: search.current,
                }
                setLoading(true)
                get('/tuan/getcanchoosealltuanbytheme', rqdata).then((res: any) => {
                    setSearchKey(search.current)
                    setList([...list, ...res.list])
                    setLoading(false)
                })
            }, [list, loading])

            return <Modal title='选择活动' shouldClose={shouldClose} cancel={true}
                onClose={() => {
                    res(false)
                    ReactDom.render(<></>, confirmWrap)
                }}
                onConfirm={() => {
                    res({
                        id: selectedTuanIds,
                        data: selectedTuanDataList.current,
                    })
                    close(true)
                }} >
                <div style={{ width: 500, height: 468, overflow: 'overlay' }} onScroll={e => {
                    if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 50) {
                        getList()
                    }
                }} >
                    <Search search={searchKey} placeholder={'搜索商品'} onSearch={() => {
                        search.current = searchKey
                        setList(undefined)
                    }} onChange={str => { setSearchKey(str) }} />
                    {list?.map(tuan => {
                        const selected = selectedTuanIds.find(i => i === Number(tuan.id))
                        return <div key={tuan.id} className='flex-box ' style={{ padding: 12, }} onClick={() => {
                            if (selected) {
                                setSelectedTuanIds(selectedTuanIds.filter(i => i !== Number(tuan.id)))
                            }
                            else {
                                setSelectedTuanIds([...selectedTuanIds, Number(tuan.id)])
                                selectedTuanDataList.current = [...selectedTuanDataList.current.filter(i => i.id !== tuan.id), tuan]
                            }
                        }}>
                            <div style={{ marginRight: 10 }}>
                                {selected ? <i className='iconfont icon-gou' style={{ color: '#fa503c' }} /> : <i className='iconfont icon-yuan' style={{ color: '#ccc' }} />}
                            </div>
                            <div style={{ marginRight: 6 }}>
                                <img alt='tuanlogo' src={tuan.logo} style={{ width: 36, height: 36, }} className='img-center' />
                            </div>
                            <div>
                                <div style={{ fontSize: 14, color: '#333', marginBottom: 10 }}>{tuan.title}</div>
                                <div style={{ fontSize: 12, color: '#fa503c' }}>￥{(Number(tuan.price) / 100).toFixed(2)}</div>
                            </div>
                        </div>
                    })}
                </div>
            </Modal>
        }


        if (!confirmWrap) {
            confirmWrap = document.createElement('div')
            document.body.appendChild(confirmWrap)
        }
        ReactDom.render(<Confirm {...props} />, confirmWrap)
    })

}