
import React, { Component } from 'react'

interface Props{
    onSearch: () => void,
    onChange: (val: string) => void,
    placeholder?: string,
    search: string,
    outerStyle?: object,
    inputStyle?: object,
}

class Search extends Component<Props> {

    render(){
        return <div className='fcc' style={{background: '#f5f5f5', padding: '.8rem 1rem', borderRadius: 5, ...this.props.outerStyle}}>
            <input style={{flex: 1, border: 0, fontSize: 14, background: 'inherit', ...this.props.inputStyle}}
                onChange={e=>{
                    this.props.onChange(e.target.value)
                }} onKeyUp={e => {
                    if(e.key === 'Enter'){
                        this.props.onSearch()
                    }
                }} value={this.props.search} placeholder={this.props.placeholder} />
            <i className='icon-seekicon iconfont cur-pointer' style={{marginLeft: '.6rem'}} onClick={() => {
                this.props.onSearch()
            }} />
        </div>
    }
}

export default Search
