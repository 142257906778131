import { message, Pagination } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import parseParams from '../../../methods/paramParse';
import { get, post } from '../../../netApi/fetch';
import Prompt from '../../Methods/Prompt';
import BTN from '../../total/Button';
interface props extends RouteComponentProps {
    user: user
    sidebarOpen: boolean,
}

type applyItem = {
    "id": string
    "sid": string
    "apply_type": string
    "legal_person_id": string
    "business_id": string
    "facade_img_ids": string
    "indoor_img_ids": string
    "auxiliary_img_ids": string
    "account_type": string
    "account_name": string
    "bank_account": string
    "operator": string
    "status": string
    "createtime": string
    "upatetime": string
    "shop_name": string
    "shop_logo": string
    "legal_person_name": string
    "legal_person_id_card": string
    "is_long_effective": string
    "id_card_validity_start": string
    "id_card_validity_end": string
    "id_card_front_img": string
    "id_card_back_img": string
    "id_card_hand_img": string
    "organization": string
    "province": string
    "city": string
    "area": string
    "detail": string
    "license_img": string
    "auxiliary_img_list": string[]
    "indoor_img_list": string[]
    "facade_img_list": string[]
    "phone": string
    "reason": string
}

const Type = styled.div<{ active: boolean }>`
    color: ${props => props.active ? '#ff5156' : '#999'};
    font-size: ${props => props.active ? 20 : 14}px;
    position: relative;
    margin-right: 30px;
    font-weight: 600;
    cursor: pointer;
    &::after{
        content: '';
        display: ${props => props.active ? 'block' : 'none'};
        position: absolute;
        width: 40px;
        height: 4px;
        border-radius: 2px;
        bottom: -8px;
        left: calc((100% - 40px) / 2);
        background-color: #ff5156;
    }
`

const Table = styled.table`
    margin-bottom: 20px;
    img{
        width: 40px;height: 40px;
        margin-bottom: 6px;
        cursor: pointer;
        object-fit: cover;
    }
    thead{
        tr{
            background: #f5f5f5;
        }
    }
    tbody{
        td{
            border: 1px solid #f5f5f5;
        }
        td>div{
            min-height: 200px;
            padding: 12px;
            div{
                margin-bottom: 4px;
            }
        }
    }
`

function Auth(props: props) {
    console.log(props.sidebarOpen);
    useEffect(() => {
        document.title = '收款码审核'
        return () => {
            document.title = '接力GO'
        }
    }, [])

    const [list, setList] = useState<applyItem[]>()

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState<number[]>()
    const [currentTotal, setCt] = useState(0)
    const [status, setStatus] = useState<number>()

    // location改变时重新加载列表
    const getting = useRef(false)
    useEffect(() => {
        if (getting.current) return
        if (list !== undefined) return
        const params = parseParams(props.location.search);
        const page = Number(params.page) || 1
        const status = Number(params.status) || 1
        const limit = 20;
        getting.current = true
        get('/god/scanpayapplylist', { offset: (page - 1) * limit, limit, status }).then((res: any) => {
            getting.current = false
            setPage(page)
            setTotal([Number(res.statistics.apply_num), Number(res.statistics.success_num), Number(res.statistics.refuse_num), Number(res.statistics.wait_confirm_num),])
            setList(res.list)
            setStatus(status)
            setCt(res.count)
        }).catch((e: string) => {
            message.error(e)
            getting.current = false
        })
    }, [props.location, list])

    const changeUrl = useCallback((conf) => {
        if (getting.current) return
        const p = conf.page || page
        const s = conf.status || status
        setList(undefined)
        props.history.push(`/paycode/auth?page=${p}&status=${s}`)
    }, [page, props, status])

    const [showImg, setShowImg] = useState('')

    return <>
        <div style={{ marginLeft: props.sidebarOpen ? 228 : 0, paddingTop: 20, paddingLeft: 46, paddingRight: 46 }}>
            <div className="flex-box" style={{ marginBottom: 40 }} >
                <Type onClick={() => {
                    changeUrl({ page: 1, status: 1 })
                }} active={status === 1}>审核中（{(total || [])[0]}）</Type>
                <Type onClick={() => {
                    changeUrl({ page: 1, status: 2 })
                }} active={status === 2}>已通过（{(total || [])[1]}）</Type>
                <Type onClick={() => {
                    changeUrl({ page: 1, status: 3 })
                }} active={status === 3}>已拒绝（{(total || [])[2]}）</Type>
                 <Type onClick={() => {
                    changeUrl({ page: 1, status: 4 })
                }} active={status === 4}>待确定（{(total || [])[3]}）</Type>
            </div>
            {(list || []).map(item => {
                return <Table key={item.id}>
                    <thead>
                        <tr>
                            <td colSpan={3} >
                                <div className="flex-between" style={{ padding: 12 }}>
                                    <div className="flex-box" onClick={() => {
                                        props.history.push(`/user/detail/${item.sid}?mode=3`)
                                    }}>
                                        <img src={item.shop_logo} alt="" style={{ width: 36, height: 36, borderRadius: '50%', marginRight: 20, marginBottom: 0 }} />
                                        <div>{item.shop_name}</div>
                                    </div>
                                    {(status === 1) && <div className="flex-box">
                                        <BTN onClick={() => {
                                            Prompt({type: 'string', title: '填写拒绝理由'}).then(res => {
                                                return post('/god/scanpaycheck', {
                                                    id: item.id,
                                                    is_passed: false,
                                                    reason: res
                                                })
                                            }).then(() => {
                                                message.success('已拒绝审核')
                                                setList(undefined)
                                            }).catch(message.error)
                                        }} color={'gray'}>不通过</BTN>
                                        <BTN onClick={() => {
                                            post('/god/scanpaycheck', {
                                                id: item.id,
                                                is_passed: true,
                                            }).then(() => {
                                                message.success('操作成功')
                                                setList(undefined)
                                            }).catch(message.error)
                                        }} style={{ marginLeft: 16 }} solid color={'red'}>通过</BTN>
                                    </div>}
                                    {status === 3 && <div>
                                        {item.reason ? `拒绝理由：${item.reason}` : ''}    
                                    </div>}
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div>
                                <h4>身份信息</h4>
                                <div>法人姓名：{item.legal_person_name}</div>
                                <div>法人身份证号：{item.legal_person_id_card}</div>
                                <div>电话：{item.phone}</div>
                                <div className="flex-between" style={{ alignItems: 'flex-start' }} >
                                    <div>照片：</div>
                                    <img onClick={setShowImg.bind('', item.id_card_front_img)} src={item.id_card_front_img} alt="身份证正面照片" />
                                    <img onClick={setShowImg.bind('', item.id_card_back_img)} src={item.id_card_back_img} alt="身份证背面照片" />
                                    <img onClick={setShowImg.bind('', item.id_card_hand_img)} src={item.id_card_hand_img} alt="手持身份证照片" />
                                </div>
                            </div></td>
                            <td><div>
                                <h4>商户信息</h4>
                                <div>营业名称：{item.organization}</div>
                                <div>营业地址：{item.province + item.city + item.area}</div>
                                <div>营业地址：{item.detail}</div>
                                <div className="flex-box" style={{ alignItems: 'flex-start' }} >
                                    <div>照片：</div>
                                    <img src={item.license_img} onClick={setShowImg.bind('', item.license_img)} alt="门面照地址列表 " />
                                </div>
                            </div></td>
                            {/* <td><div>
                            <h4>账户信息</h4>
                            <div>账户名称：{item.account_name}</div>
                            <div>银行账户：{item.bank_account}</div>
                        </div></td> */}
                            <td><div>
                                <h4>图片信息</h4>
                                <div className="flex-between" style={{ alignItems: 'flex-start' }} >
                                    <div>店铺门面照：</div>
                                    <div className="flex-between">
                                        {item.facade_img_list.map(img => {
                                            return <img key={img} src={img} onClick={setShowImg.bind('', img)} alt="门面照地址列表 " />
                                        })}
                                        <img src='' style={{ opacity: 0, background: '#fff' }} alt="" />
                                        <img src='' style={{ opacity: 0, background: '#fff' }} alt="" />
                                    </div>
                                </div>
                                <div className="flex-between" style={{ alignItems: 'flex-start' }} >
                                    <div>店铺室内照：</div>
                                    <div className="flex-between">
                                        {item.indoor_img_list.map(img => {
                                            return <img key={img} src={img} onClick={setShowImg.bind('', img)} alt="店铺室内照 " />
                                        })}
                                        <img src='' style={{ opacity: 0, background: '#fff' }} alt="" />
                                        <img src='' style={{ opacity: 0, background: '#fff' }} alt="" />
                                    </div>
                                </div>
                                <div className="flex-between" style={{ alignItems: 'flex-start' }} >
                                    <div>辅助证明材料：</div>
                                    <div className="flex-between">
                                        {item.auxiliary_img_list.map(img => {
                                            return <img key={img} src={img} onClick={setShowImg.bind('', img)} alt="辅助证明材料 " />
                                        })}
                                        <img src='' style={{ opacity: 0, background: '#fff' }} alt="" />
                                        <img src='' style={{ opacity: 0, background: '#fff' }} alt="" />
                                    </div>
                                </div>
                            </div></td>
                        </tr>
                    </tbody>
                </Table>
            })}
            <div className="flex-center" style={{ position: 'fixed', bottom: 0, left: props.sidebarOpen ? 228 : 0, height: 90, width: `calc(100vw - 228px)`, borderTop: '1px solid #eee' }}>
                <Pagination current={page}
                    // total={(100)}
                    total={currentTotal}
                    pageSize={20}
                    showSizeChanger={false} onChange={(e) => {
                        changeUrl({ page: e })
                    }} />
            </div>
        </div>
        {showImg !== '' && <div onClick={setShowImg.bind('', '')} className='flex-center' style={{ position: 'fixed', left: 0, top: 0, height: '100vh', width: '100vw', background: 'rgba(0,0,0,0.6)', zIndex: 99 }}>
            <img src={showImg} alt="" style={{ maxWidth: '90vw', maxHeight: '90vh' }} />
        </div>}
    </>
}
const mapStateToProps = (state: { user: any, sidebarOpen: boolean }, ownProps: any) => {
    return {
        user: state.user,
        sidebarOpen: state.sidebarOpen,
    }
}
export default connect(mapStateToProps)(Auth)