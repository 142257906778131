import React, { Component } from 'react';
import Content from '../../../high-components/Content';
import { RouteComponentProps, match,  } from 'react-router-dom'
import { connect } from 'react-redux';
import { get } from '../../../netApi/fetch';
import JSONPretty  from 'react-json-pretty';
interface props extends RouteComponentProps {
    match: Match,
    login_user_data: any
}
interface Match extends match {
    params: {
        type: string,
    }
}
interface afterError {
    [str: string]: any
}

interface state {
    errorList: afterError[],
    init: boolean,

    offset: number,
}
class Log extends Component<props, state>{
    readonly state: state = {
        errorList: [],
        init: false,
        offset: 0
    }
    componentDidMount() {
        this.getting = true
        console.log(this.props);

        const initdata = (() => {
            if (this.props.login_user_data.token) {
                get('/file/geterrorlog', {
                    error_type: this.props.match.params.type,
                    sort: 'desc',
                    offset: this.state.offset,
                    limit: 200,
                    types: 1
                }).then((res: any) => {
                    console.log(res);
                    console.log(res.list.filter((i: any) => i.type === "submitError")[1]?.data.page_data.goodslist);
                    this.getting = false
                    this.setState({ errorList: res.list.map((i: afterError) => {
                        // return {...i, show: JSON.stringify(i).split(',').join(',\n').split('\\').join('')}
                        return {...i, show: JSON.stringify(i)}
                    }), init: true, offset: this.state.offset + res.list.length })
                })
                clearInterval(inter)
            }
        })
        const inter = setInterval(initdata, 50)

        document.addEventListener('scroll', this.getDataHook)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.getDataHook)
    }

    getDataHook = this.getData.bind(this)

    getting: boolean = false

    getData(e: any) {
        if (this.getting) return
        const offsetY = window.pageYOffset
        const scrollHeight = e.srcElement.body.scrollHeight
        let bottom = scrollHeight - offsetY
        console.log(bottom);
        if (bottom < 2000) {
            this.getting = true
            get('/file/geterrorlog', {
                error_type: this.props.match.params.type,
                sort: 'desc',
                offset: this.state.offset,
                limit: 100,
                types: 1
            }).then((res: any) => {
                setTimeout(() => {
                    this.getting = false
                }, 100);
                this.setState({ errorList: [...this.state.errorList, ...res.list.map((i: afterError) => {
                    return {
                        ...i,
                        show: JSON.stringify(i)
                        // show: JSON.stringify(i).split(',').join(',\n').split('\\').join('')
                    }
                })], init: true, offset: this.state.offset + res.list.length })
            })
        }
    }

    render() {
        if (this.state.errorList.length === 0 && this.state.init) return <div>暂无错误</div>
        if (!this.state.init) return null
        if(this.props.match.params.type === 'front')return null
        return <div style={{ margin: '20px auto' }}>
            <h1>记录</h1>
            {/* {this.props.match.params.type === 'after' ? <> */}
                {this.state.errorList.map((i: afterError, idx: number) => {
                    return <div style={{ wordBreak: 'break-all', marginBottom: 90, maxWidth: '90%', margin: '0 auto 80px',}} key={idx}>
                        <JSONPretty data={i.show} mainStyle='white-space: pre-wrap'/>
                    </div>
                })}
            {/* </> : null} */}
        </div>
    }
}
const mapStateToProps = (state: any) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch: any, ) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content(Log))