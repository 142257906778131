import React, { Component } from 'react'
import Modal from '../../total/Modal';
import styled, { CSSProperties } from 'styled-components';
import { post, getToken } from '../../../netApi/fetch';
import { message } from 'antd';

interface Props {
    onClose: (e: boolean) => void,
    sid: number | string,
}

interface State {
    shouldClose: boolean,
    name: string,
    idcard: string,
    phone: string,
    idcard_front: string,
    idcard_back: string,
    loading: boolean,
    nameError?: boolean,
    phoneError?: boolean,
    idError?: boolean,
}

const Input = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 30px;
    & > div{
        min-width: 5em;
        margin-right: 12px;
    }
    & input{
        border-radius:6px;
        border:1px solid #ccc;
        flex: 1;
        transition: all .2s;
        padding: 8px 16px;
    }
`
const InputImage = styled.div`
    display: flex;
    align-items:center;
    flex-direction: column;
    label{
        width: 130px;
        height: 100px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        cursor: pointer;
        img{
            max-height: 80%;
            max-width: 80%;
        }
    }
    input{
        position: absolute;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
    }
`

export default class UserAuthen extends Component<Props, State>{

    idcard_front: string = ''
    idcard_back: string = ''

    idcard_front_loading: boolean = false
    idcard_back_loading: boolean = false
    readonly state: State = {
        shouldClose: false,
        name: '',
        idcard: '',
        phone: '',
        idcard_front: '',
        idcard_back: '',
        loading: false,
    }
    
    ErrorStyle: CSSProperties = {
        borderColor: '#ff5156',
        boxShadow: '0 0 5px #ff5156',
    }
    success: boolean = false
    
    render() {
        return <Modal title='人工认证身份' loading={this.state.loading} cancel onConfirm={() => {
            console.log(this.state, this.idcard_back, this.idcard_front);
            const { idcard, phone, name, idcard_front, idcard_back } = this.state
            if (!idcard) this.setState({ idError: true })
            if (!phone) this.setState({ phoneError: true })
            if (!name) this.setState({ nameError: true })
            if (!this.idcard_back || !this.idcard_front) {
                if (!idcard_back || !idcard_front) {
                    message.warning('请选择照片')
                    return
                }
                message.warning('照片上传中')
                return
            }
            post('/god/manuallyauthentication', {
                token: getToken(),
                types: 1,
                name,
                phone,
                idcard,
                image_list: [this.idcard_front, this.idcard_back],
                sid: this.props.sid
            }).then((res: any) => {
                this.success = true
                this.setState({ shouldClose: true })
            }).catch((e: string) => {
                message.warning(e)
            })
        }} shouldClose={this.state.shouldClose} onClose={() => { this.props.onClose(this.success) }} >
            <Input>
                <div>姓名</div>
                <input value={this.state.name} style={this.state.nameError ? this.ErrorStyle : {}}
                    onBlur={() => { if (this.state.name) this.setState({ nameError: false }) }}
                    onChange={e => this.setState({ name: e.currentTarget.value })} />
            </Input>
            <Input>
                <div>身份证</div>
                <input value={this.state.idcard} style={this.state.idError ? this.ErrorStyle : {}}
                    onBlur={() => { if (this.state.idcard) this.setState({ idError: false }) }}
                    onChange={e => this.setState({ idcard: e.currentTarget.value })} />
            </Input>
            <Input>
                <div>手机号码</div>
                <input value={this.state.phone} style={this.state.phoneError ? this.ErrorStyle : {}}
                    onBlur={() => { if (this.state.phone) this.setState({ phoneError: false }) }}
                    onChange={e => this.setState({ phone: e.currentTarget.value })} />
            </Input>
            <div className='fbc'>
                <InputImage >
                    <label style={{ background: 'url(https://jieligo-1259536745.image.myqcloud.com/uploads/content/qxctxMwF8T.png) 100%/100% no-repeat' }} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <input type='file' accept='image/*' onChange={e => {
                            const files = e.currentTarget.files
                            this.dealImage(files, 'idcard_front')
                        }} />
                        {this.state.idcard_front
                            ? <img alt='请上传身份证' src={this.state.idcard_front} />
                            : null}
                    </label>
                    <div>正面</div>
                </InputImage>
                <InputImage >
                    <label style={{ background: 'url(https://jieligo-1259536745.image.myqcloud.com/uploads/content/F06eSESA4N.png) 100%/100% no-repeat' }} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <input type='file' accept='image/*' onChange={e => {
                            const files = e.currentTarget.files
                            this.dealImage(files, 'idcard_back')
                        }} />
                        {this.state.idcard_back
                            ? <img alt='请上传身份证' src={this.state.idcard_back} />
                            : null}
                    </label>
                    <div>反面</div>
                </InputImage>
            </div>
        </Modal>
    }
    dealImage(e: FileList | null, type: 'idcard_back' | 'idcard_front') {
        if (!e || e.length === 0) return
        this.uploadImage(e[0], type)
        const fr = new FileReader()
        fr.onload = () => {
            switch (type) {
                case 'idcard_front':
                    this.setState({ idcard_front: String(fr.result) })
                    break;
                case 'idcard_back':
                    this.setState({ idcard_back: String(fr.result) })
                    break;
                default:
                    break;
            }
        }
        fr.readAsDataURL(e[0])
    }
    uploadImage(e: File, type: string) {
        const fd = new FormData()
        fd.append('types', '1')
        fd.append('file', e)
        fd.append('uploadtype', type)
        fd.append('token', getToken())

        switch (type) {
            case 'idcard_front':
                this.idcard_front_loading = true
                break;
            case 'idcard_back':
                this.idcard_back_loading = true
                break;
            default:
                break;
        }

        fetch('https://api.jieligo.net/file/upload', {
            method: 'post', body: fd, mode: 'cors',
            credentials: 'include',
        }).then((res: any) => {
            res.json().then((res: NetworkResult) => {
                if (res.code !== 0) {
                    message.error(res.message)
                    return
                }
                console.log(res);
                const data = res.data.file
                switch (type) {
                    case 'idcard_front':
                        this.idcard_front_loading = false
                        this.idcard_front = data.id
                        break;
                    case 'idcard_back':
                        this.idcard_back_loading = false
                        this.idcard_back = data.id
                        break;
                    default:
                        break;
                }
            })
        }).catch(() => {
            message.error('网络错误')
        })
    }
}