import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components';
import {  post } from '../../../netApi/fetch';
import { set_login_user_data } from '../../../reducer';

interface state {
    code?: string
}
interface props extends RouteComponentProps {
    is_Login: boolean,
    login: (data: any) => void
}

const Code = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;

    img{
        max-width: 200px;
        max-height: 200px;
    }
`


class Home extends Component<props> {

    interval: any = null

    readonly state: state = {

    }

    async check() {
        let user
        try {
            user = await post('/admin/checkIsLogin', {})
            this.props.login(user)
        } catch (err) {
            console.log(err)
        }
        return user
    }

    checkhandle = this.check.bind(this)

    async componentDidMount() {
        
        // console.log(this.props, this.state)
        // const code = await get('/admin/show', {})
        // console.log(code)
        // this.setState({ code: code.base64 })

        // this.interval = setInterval(this.checkhandle, 1000)
    }

    componentDidUpdate() {
        if (this.props.is_Login) {
            clearInterval(this.interval)
        }
    }

    componentWillUnmount(){
        clearInterval(this.interval)
    }



    render() {
        return <div>
            <Code>
                <img alt='' src={this.state.code} />
            </Code>
        </div>
    }
}

const mapStateToProps = (state: any, ) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        login: (data: any) => {
            dispatch(set_login_user_data(true, data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)