import { message } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import Content from "../../../high-components/Content";
import imageUpload from "../../../methods/imageUpload";
import { get, post } from "../../../netApi/fetch"
import Prompt from "../../Methods/Prompt";
import BTN from "../../total/Button";
import selectTuan from "./selectTuan";
import ThemeList from './ThemeList';

interface props {
    user: user
}

const mapStateToProps = (state: { login_user_data: user }) => {
    return {
        user: state.login_user_data
    }
}

export interface themeItem {
    id: string
    theme_name: string
    desc: string
    logo: string
    rank: number
    status: string
    tuan_list: {
        id: string
        logo: string
        title: string
        original_price: string
        pre_income: string
        price: string
    }[]
}

interface themeTuanItem {
    id: string
    pid: string
    sid: string
    status: string
    title: string
    type: string
    goods_list: { logo: string, price: string }[]
    rank: number
}

interface themeData {
    background: string
    createtime?: string
    desc: string
    id?: string
    is_hyd_theme?: string | number
    is_top?: string
    logo: string
    sid?: string
    status?: string
    theme_name: string
    theme_rank?: string
    updatetime?: string
    tuanList: themeTuanItem[]
}

export default Content(connect(mapStateToProps)((props: props) => {
    const [themeList, setThemeList] = useState<themeItem[]>()

    const [selectedThemeId, setSelectedThemeId] = useState<number | string | undefined>()

    const [logo, setLogo] = useState('')
    const [background, setBackground] = useState('')
    const [desc, setDesc] = useState('')
    const [name, setName] = useState('')
    const [tuanList, setTuanList] = useState<themeTuanItem[]>([])

    useEffect(() => {
        if (themeList) return
        get('/index/goodsrecommendedbytheme', { offset: 0, limit: 9999, is_get_stop_theme: 1 }).then((res: { list: themeItem[] }) => {
            setThemeList(res.list.map((item, rank) => {
                return {
                    ...item, rank,
                }
            }))
        })
    }, [themeList])

    useEffect(() => {
        if (!selectedThemeId) return
        get('/index/getsubscribelist', { offset: 0, limit: 9999, theme_id: selectedThemeId, is_get_stop_tuan: 1, }).then((res: { theme: themeData; list: themeTuanItem[]; }) => {
            let themeData: themeData = res.theme
            themeData.tuanList = res.list.map((tuan, index) => {
                return {
                    ...tuan, rank: index
                }
            })
            setLogo(themeData.logo)
            setBackground(themeData.background)
            setDesc(themeData.desc)
            setName(themeData.theme_name)
            setTuanList(themeData.tuanList)
            setSelectedThemeId(selectedThemeId)
        })
    }, [selectedThemeId])

    const selectImage = useRef(() => {
        return new Promise((res: (data: File) => void, rej) => {
            const input = document.createElement('input')
            input.type = 'file'
            input.accept = 'image/*'
            input.onchange = () => {
                if (!input.files) {
                    rej('取消')
                    return
                }
                console.log(input.files)
                const img = input.files[0]
                if (!img) return
                res(img)
            }
            input.click()
        })
    })

    const [logoUploading, setLogoUploading] = useState(false)
    const [bgUploading, setBgUploading] = useState(false)

    const updateCallBack = useCallback(() => {
        setThemeList(undefined)
        setSelectedThemeId(undefined)
        setLogo('')
        setBackground('')
        setDesc('')
        setName('')
        setTuanList([])
    }, [])

    useEffect(() => {
        document.title = '好物推荐配置'
        return () => { document.title = 'jieligo' }
    }, [])

    const uploadBackground = useCallback(() => {
        selectImage.current().then(img => {
            setBgUploading(true)
            return imageUpload(img, 'tuan_theme_bg').then(result => {
                setBgUploading(false)
                setBackground(result.src)
            })
        }).catch((e) => {
            message.error(e?.message || e)
            setBgUploading(false)
        })
    }, [])

    const uploadLogo = useCallback(() => {
        selectImage.current().then(img => {
            setLogoUploading(true)
            return imageUpload(img, 'tuan_theme_logo').then(result => {
                setLogoUploading(false)
                setLogo(result.src)
            })
        }).catch((e) => {
            message.error(e?.message || e)
            setLogoUploading(false)
        })
    }, [])

    return <div style={{
        display: "flex", flexDirection: 'column', height: '100vh', background: '#ededed',
    }}>
        <div style={{ height: 60, background: '#fff', paddingLeft: 20, }} className='flex-box'>
            <div>
                <div style={{ borderBottom: '4px solid #fa503c', color: '#fa503c', fontSize: 14, paddingBottom: 5, }}>好物推荐</div>
            </div>
            <div style={{ marginLeft: 200 }}>
                <BTN color='red' solid onClick={() => {
                    setSelectedThemeId('')
                    setLogo('')
                    setBackground('')
                    setDesc('')
                    setName('')
                    setTuanList([])
                }} >
                    <i className="iconfont icon-jia" style={{ fontSize: 12, marginRight: 6 }}></i>
                    <div>添加专题</div>
                </BTN>
            </div>
        </div>
        <div style={{ paddingLeft: 30, paddingTop: 20, flex: 1, alignItems: 'stretch', overflow: 'hidden', paddingBottom: 30 }} className='flex-between'  >
            <ThemeList list={themeList || []} user={props.user} activeThemeId={selectedThemeId || ''} selectTheme={(id) => {
                setSelectedThemeId(id)
            }} onRankChange={(list) => {
                post('/tuan/batchupdatethemerank', {
                    theme_id_list: list.map(i => i.id)
                }).then(() => {
                    setThemeList(list)
                }).catch((e: any) => {
                    message.error(e?.message || e)
                    setThemeList(undefined)
                })
            }} onStatusChange={(theme) => {
                setThemeList(themeList?.map(t => {
                    if (t.id === theme.id) return theme
                    return t
                }))
            }} />
            {selectedThemeId !== undefined && <div style={{ flex: 1, marginLeft: 20, marginRight: 20, overflow: 'auto' }}>
                <div className="flex-between" style={{ marginBottom: 12 }}>
                    <div style={{ fontWeight: 600, fontSize: 16, color: '#333' }}>专题设置</div>
                    <div className="flex-center">
                        <BTN color='gray' solid>取消</BTN>
                        <BTN color='red' solid style={{ marginLeft: 8 }} onClick={() => {
                            const data = {
                                theme_name: name,
                                desc,
                                background,
                                logo,
                                tuan_info_list: tuanList.map(tuan => {
                                    return {
                                        tuanid: tuan.id,
                                        rank: tuan.rank + 1,
                                    }
                                })
                            }
                            if (selectedThemeId) {
                                post('/tuan/updatetheme', {
                                    ...data, theme_id: selectedThemeId,
                                }).then(() => { message.success('修改成功'); updateCallBack() }).catch((e: any) => message.error(e?.message || e))
                            } else {
                                post('/tuan/createtheme', {
                                    ...data,
                                }).then(() => { message.success('创建成功'); updateCallBack() }).catch((e: any) => message.error(e?.message || e))
                            }
                        }} >保存</BTN>
                    </div>
                </div>
                <div style={{ background: '#ffffff', borderRadius: 4, padding: 16, marginBottom: 12 }}>
                    <div style={{ alignItems: "flex-start", marginBottom: 12, }} className='flex-between'>
                        <div style={{ width: 60 }}>头像：</div>
                        <div style={{ flex: 1 }} >
                            {logoUploading
                                ? <div style={{ width: 60, height: 60 }}>上传中...</div>
                                : logo
                                    ? <img onClick={uploadLogo} alt='' src={logo + '/sm'} className='img-center' style={{ width: 60, height: 60, border: '1px solid #f5f5f5' }} />
                                    : <div className='flex-center cur-pointer' onClick={uploadLogo} style={{ background: '#f5f5f5', width: 160, height: 60 }} >
                                        <i className="iconfont icon-jia" style={{ fontSize: 12, marginRight: 6 }}></i>
                                        <div>添加图片</div>
                                    </div>}
                        </div>
                    </div>
                    <div style={{ alignItems: "flex-start", marginBottom: 12, }} className='flex-between'>
                        <div style={{ width: 60 }}>背景：</div>
                        <div style={{ flex: 1 }} >
                            {bgUploading
                                ? <div style={{ width: 60, height: 60 }}>上传中...</div>
                                : background
                                    ? <img onClick={uploadBackground} alt='' src={background + '/sm'} className='img-center' style={{ width: 160, height: 116, border: '1px solid #f5f5f5' }} />
                                    : <div className='flex-center cur-pointer' onClick={uploadBackground} style={{ background: '#f5f5f5', width: 160, height: 60 }} >
                                        <i className="iconfont icon-jia" style={{ fontSize: 12, marginRight: 6 }}></i>
                                        <div>添加图片</div>
                                    </div>}

                        </div>
                    </div>
                    <div style={{ alignItems: "flex-start", marginBottom: 12, }} className='flex-between'>
                        <div style={{ width: 60 }}>标题：</div>
                        <div style={{ flex: 1 }} >
                            <input onChange={e => {
                                setName(e.target.value)

                            }} value={name} style={{ borderRadius: 4, border: '1px solid #ccc', padding: '8px 12px', width: 430 }} />
                        </div>
                    </div>
                    <div style={{ alignItems: "flex-start", }} className='flex-between'>
                        <div style={{ width: 60 }}>副标题：</div>
                        <div style={{ flex: 1 }} >
                            <input onChange={e => {
                                setDesc(e.target.value)
                            }} value={desc} style={{ borderRadius: 4, border: '1px solid #ccc', padding: '8px 12px', width: 430 }} />
                        </div>
                    </div>
                </div>
                {tuanList.length > 0 && <div style={{ background: '#ffffff', borderRadius: 4, padding: 16, marginBottom: 12 }}>
                    {tuanList.map((tuan, index) => {
                        return <div key={tuan.id} style={{ alignItems: "flex-start", marginBottom: 12, }} className='flex-between'>
                            <div style={{ width: 60 }}>
                                {index === 0 && '活动：'}
                            </div>
                            <div className='flex-box' style={{ flex: 1 }} >
                                <img onDoubleClick={() => {
                                    Prompt({
                                        type: 'number',
                                        title: '排第几',
                                    }).then(res => {
                                        console.log(res);
                                        tuan.rank = Number(res) - 1.1
                                        tuanList.sort((a, b) => a.rank - b.rank)
                                        setTuanList(tuanList.map((i, index) => {
                                            return { ...i, rank: index }
                                        }))
                                    })
                                }} alt='' src={tuan.goods_list[0]?.logo + '/sm'} className='img-center' style={{ width: 60, height: 60, border: '1px solid #f5f5f5', marginRight: 18 }} />
                                <div style={{ flex: 1 }}>
                                    <div className="flex-between">
                                        <div style={{ fontSize: 14, color: '#333' }} >{tuan.title}</div>
                                        <div className="flex-between" style={{ width: 100, minWidth: 100, }}>
                                            <i onClick={() => {
                                                tuan.rank -= 1.1
                                                tuanList.sort((a, b) => a.rank - b.rank)
                                                setTuanList(tuanList.map((i, index) => {
                                                    return { ...i, rank: index }
                                                }))
                                            }} className="iconfont icon-shangyi cur-pointer" style={{ fontSize: 20 }} ></i>
                                            <i onClick={() => {
                                                tuan.rank += 1.1
                                                tuanList.sort((a, b) => a.rank - b.rank)
                                                setTuanList(tuanList.map((i, index) => {
                                                    return { ...i, rank: index }
                                                }))
                                            }} className="iconfont icon-xiayi cur-pointer" style={{ fontSize: 20 }} ></i>
                                            <i onClick={(e) => {
                                                const _tuanList = tuanList.filter(i => i.id !== tuan.id)
                                                setTuanList(_tuanList.map((i, index) => {
                                                    return { ...i, rank: index }
                                                }))
                                            }} className="iconfont icon-shanchu cur-pointer" style={{ fontSize: 20 }} ></i>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 14, color: '#fa503c' }} >￥{(Number(tuan.goods_list[0]?.price) / 100).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>}
                <div style={{ background: '#ffffff', borderRadius: 4, padding: 16, marginBottom: 12 }}>
                    <div className="flex-center cur-pointer" onClick={() => {
                        selectTuan({
                            selectedTuanIds: tuanList.map(i => Number(i.id))
                        }).then((res) => {
                            if (!res) return
                            console.log(res);
                            const _tuanList = [...tuanList, ...res.data.filter(i => !tuanList.map(i => Number(i.id)).includes(Number(i.id))).map(data => {
                                return {
                                    ...data,
                                    goods_list: [{
                                        logo: data.logo, price: data.price,
                                    }]
                                }
                            })].filter(tuan => res.id.includes(Number(tuan.id)))
                            setTuanList(_tuanList.map((i, index) => {
                                return { ...i, rank: index }
                            }))
                        })
                    }}>
                        <i className="iconfont icon-jia" style={{ fontSize: 12, marginRight: 6 }}></i>
                        <div>添加活动</div>
                    </div>
                </div>
            </div>}
        </div>
    </div >
}), false)