import React, { Component } from 'react'

import styled from 'styled-components';

const Tri = styled.div`
    position: absolute;
    height: 0;
    width: 0; left: 6px;
    border-top: 4px solid rgba(0,0,0,.75);
    border-left: 3.4px solid transparent;
    border-right: 3.4px solid transparent;
    border-bottom: 0;
    /* border-bottom: 5px solid transparent; */
`
const Title = styled.div`
    background: rgba(0,0,0,.75);
    padding: 6px 8px;
    border-radius: 4px;
    color: white;
    width: ${props => props.title? ((props.title.length + 2) + 'em') : ''};
`
const Tip = styled.div`
    position: absolute;
    bottom: 100%;
    transition: all .2s ease-in;
    transform-origin: bottom left;
    transform: scale(0);
    opacity: 0;
    &.active{
        transform: scale(1);
    opacity: 1;
    }
`

interface Props {
    title: string,
    show: boolean,
    // left?: number,
    // top?: number,
    // right?: number,
    // bottom?: number,
}

class Tooltip extends Component<Props> {
    render(){
        return (
            <Tip className={this.props.show?'active':''}>
                <Title title={this.props.title}>{this.props.title}</Title>
                <Tri />
            </Tip>
        )
    }
}

export default Tooltip