import React, { Component } from 'react'
import Content from '../../../high-components/Content';
import { RouteComponentProps, match, NavLink } from 'react-router-dom'
import { get } from '../../../netApi/fetch';
import styled from 'styled-components';
import Loading from '../../Methods/Loading';
import { message } from 'antd';
interface props extends RouteComponentProps {
    match: Match
}

interface Match extends match {
    params: {
        type: string
    }
}
interface Item {
    amount_sum: number,
    logo: string,
    name: string,
    sid: string | number
}

interface data {
    withdraw_data_by_wechat: Item[],
    withdraw_data_by_bank: Item[],
}

enum type {
    card = 'card',
    wechat = 'wechat'
}

interface state {
    time_type: string | number,
    data?: data,
    type: type
}

const Wrap = styled.div`
    border-radius:8px;
    border:1px solid rgba(204,204,204,1);
`

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: #666;
    &.active{
        font-size: 20px;
        color: #454545;
    }
`

const Head = styled.div`
    padding: 0 30px;
    display: flex;
    align-items:center;
    border-bottom:1px solid rgb(204,204,204);
`

const Item = styled.div`
    padding: 10px;
    border-bottom: 1px solid rgba(204,204,204,1);
    &:last-child{
        border-bottom: 0;
    }
`

class WithdrawData extends Component<props>{

    readonly state: state = {
        time_type: '',
        type: type.card
    }

    componentDidMount() {
        document.title='数据统计'
        this.getData()
    }

    componentWillUnmount(){
        document.title='接力GO 管理'
    }

    componentDidUpdate(props: props) {
        console.log(this.state);
        if (this.props.match.params.type !== props.match.params.type) {
            this.getData()
        }
    }

    render() {
        const time_type = this.props.match.params.type
        const render = (item: Item, idx: number) => <Item key={item.sid}>
            <div className='flex-box' style={{marginBottom: 12}} >
                <span style={{ marginRight: 16 }}>{idx + 1}.</span>
                <NavLink to={`/user/detail/${item.sid}?mode=1`} target='_blank' className='flex-box'>
                    <img alt='avatar' src={item.logo} style={{
                        borderRadius: '50%',
                        width: 36, height: 36,
                        marginRight: 12
                    }} className='img-center' />
                    <span>
                        {item.name}
                    </span>
                </NavLink>
            </div>
            <div>
                提现金额：{(item.amount_sum / 100).toFixed(2)}
            </div>
        </Item>
        return <div style={{ padding: '40px 24px' }}>
            <h1>数据统计</h1>
            <Wrap>
                <Head>
                    {[
                        {
                            title: '今日',
                            value: 'today',
                        },
                        {
                            title: '昨日',
                            value: 'yesterday',
                        },
                        // {
                        //     title: '前日',
                        //     value: 'the_day_before_yesterday',
                        // },
                        {
                            title: '本周',
                            value: 'this_week',
                        },
                        {
                            title: '上周',
                            value: 'last_week',
                        },
                        {
                            title: '本月',
                            value: 'this_month',
                        },
                        {
                            title: '上月',
                            value: 'last_month',
                        },
                        {
                            title: '全部',
                            value: 'all',
                        },
                    ].map(i => {
                        return <div key={i.value} className='cur-pointer' style={{
                            padding: '16px 4px',
                            marginRight: '1em',
                            boxSizing: 'border-box',
                            borderBottom: i.value === time_type ? 'solid #ff5156 4px' : 0,
                            fontSize: i.value === time_type ? 16 : 12,
                            color: i.value === time_type ? '#ff5156' : '',
                        }} onClick={() => {
                            this.props.history.push(`/withdrawdata/${i.value}`)
                        }}>
                            <span>{i.title}</span>
                        </div>
                    })}
                </Head>
                <div className='flex-box' style={{ padding: 20 }}>
                    <Title className={`${this.state.type === type.wechat ? 'active' : ''}`} onClick={() => { this.setState({ type: type.wechat }) }}
                        style={{ marginRight: 20 }}>提现到微信排名</Title>
                    <Title className={`${this.state.type === type.card ? 'active' : ''}`} onClick={() => { this.setState({ type: type.card }) }} >提现到银行卡排名</Title>
                </div>

                {this.state.data ? <div style={{ padding: 20 }}>
                    {(() => {
                        let arr: Array<Item> = []
                        if (this.state.type === type.card) {
                            arr = this.state.data.withdraw_data_by_bank
                        }
                        if (this.state.type === type.wechat) {
                            arr = this.state.data.withdraw_data_by_wechat
                        }
                        return arr.map(render)
                    })()}
                </div> : null}
            </Wrap>
        </div>
    }
    getData() {
        Loading.show()
        get('/file/countwithdrawdata', {
            types: 1, time_type: this.props.match.params.type, terminal_types: 1
        }).then((res: any) => {
            Loading.hide()
            console.log(res);
            const deal = (i: any): Item => {
                return {
                    amount_sum: Number(i.amount_sum),
                    sid: i.sid,
                    name: i.name,
                    logo: i.logo,
                }
            }
            this.setState({
                data: {
                    withdraw_data_by_bank: res.withdraw_data_by_bank.map(deal),
                    withdraw_data_by_wechat: res.withdraw_data_by_wechat.map(deal),
                }
            })
        }).catch((e: string) => {
            Loading.hide()
            message.error(e)
        })
    }
}

export default Content(WithdrawData)