import React from 'react';
import './App.css';

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from './reducer/index'
import Myrouter from './router/index'

import './App.css';
import './style/animate.css'
import './style/total.scss'
// import './style/Sidebar.scss'
// import './style/Home.scss'
// import './style/login.scss'
// import './style/ContentLayoutPanel.scss'
// import './style/Payment.scss'
import './style/order.scss'
// import './style/promotion.scss'
// import './style/member_after_pay.scss'
import './style/tuan.scss'
// import './style/Good.scss'
// import './style/ali.scss'
const store = createStore(reducer)
function App() {
  return (
    <Provider store={store}>
      <Myrouter />
    </Provider>
  );
}

export default App;
