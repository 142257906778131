import React, { Component } from 'react'

import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import { Pagination, message } from 'antd';

import styled from 'styled-components';

import { get, post } from '../../../netApi/fetch';

import Content from '../../../high-components/Content';

import Search from '../../total/Input/Search';

import paramParse from '../../../methods/paramParse';
import userlevel from '../../../methods/userlevel';

import Modal from '../../total/Modal';
import Select from '../../total/Select_';

import loading from '../../Methods/Loading';
import Loading from '../../Methods/Loading';

import { confirm } from '../../Methods/Confirm';

export const Logo = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 50%;
`
const Name = styled.div`
    font-weight: 600;
    color: #330000;
    font-size: 14px;
    max-width: 6em;
    min-width: 6em;
    word-wrap:break-word;
    word-break:break-all;
`
const Table = styled.table`
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    & td{
        padding: 12px;
        border-right: 1px solid #eee;
        box-sizing: border-box;
    }
    &:nth-child(1){
        border-left: 1px solid #eee;
    }
    & tr:last-child{
        border-bottom: 1px solid #eee;
    }
`
const Title = styled.div`
    font-weight: 600;
    color: #454545;
    font-size: 20px;
`

const FloatPanel = styled.div`
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background: white;
    z-index: 9;
    width:266px;
    box-shadow:0px 2px 4px 0px rgba(204,204,204,1);
    border-radius:2px;
    border:1px solid rgba(232,232,232,1);
`

const FloatCtrl = styled.div`
    background:rgba(247,249,250,1);
    border-radius:0px 0px 2px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`

const FloatLink = styled.div`
    font-size: 12px;
    color:rgba(0,0,0,0.45);
    font-weight: 600;
    transition: all 0.3s;
    &:hover{
        color:rgba(255,81,86,1);
    }
`

interface Props {
    match: {
        path: String,
        url: String,
        params: {
            [key: string]: string
        }
    }
    location: {
        search: string
    }
    history: {
        push: (href: string) => void
    }
}

interface State {
    search: string,
    page: number,
    userCount: number,
    list: user[],
    op: 1 | 0,
    selectOp: boolean,
    closeOp: boolean,
    setting: boolean,
    settingUser?: user
    settingOp?: number
    showVideoPremission: boolean,
    showCardPremission?: boolean,
    rate?: number,
}

interface user {
    uid: number | string;
    sid: number,
    shopname: string,
    shoplogo: string,
    createtime: string,
    username: string,
    userlogo: string,
    typeText: string,
    type: number,
    pid: number,
    p_shopname: string,
    p_logo: string,
    income_total: number,
    showMenu?: boolean,
    showMenuFlag?: boolean,
    video_permission: boolean,
    withdraw_to_bank_permission: boolean,
    iscertified?: number,
    service_tip?: number,
    withdraw_way_status_data: {
        bank_status: number, to_bank_accounting_date: number, wechat_status: number,
    },
    shop_payment_platform: number;
    is_brand_street_user: number | string;
    exception_text: string;
    exception_amount: number;
    account_total: number;
    id: string;
}

class User extends Component<Props, State> {

    constructor(p: any) {
        super(p)
        this.state = {
            search: '',
            page: 1,
            userCount: 0,
            list: [],
            op: 1,
            selectOp: false,
            closeOp: false,
            setting: false,
            showVideoPremission: false,
        }
    }

    pageSize = 15

    componentDidMount() {
        const params = paramParse(this.props.location.search)
        const data: { search: string, page: number, op: 1 | 0 } = {
            search: this.state.search,
            page: this.state.page,
            op: Number(this.state.op) === 1 ? 1 : 0,
        }
        data.search = decodeURIComponent(params.s || '')
        data.page = Number(params.p) || 1
        data.op = Number(params.op) === 1 ? 1 : 0
        this.setState({ ...data }, () => {
            this.getData()
        })
    }

    componentDidUpdate(props: Props, state: State) {
        let getFrag = false
        const params = paramParse(this.props.location.search)
        const data: { search: string, page: number, op: 1 | 0 } = {
            search: this.state.search,
            page: this.state.page,
            op: Number(this.state.op) === 1 ? 1 : 0,
        }
        const _p = paramParse(props.location.search)
        if (_p.s !== params.s) {
            data.search = params.s
            getFrag = true
        }
        if (_p.p !== params.p) {
            data.page = Number(params.p)
            getFrag = true
        }
        if (_p.op !== params.op) {
            data.op = Number(params.op) === 1 ? 1 : 0
            getFrag = true
        }
        if (getFrag) {
            this.setState({ ...data }, () => {
                this.getData()
            })
        }
    }
    componentWillUnmount() {
        this.setState = () => { }
    }

    render() {
        return <div style={{ width: '100%', margin: '0 auto', padding: 30, }}>
            <div className='fbc'>
                <Title className='flex-box'>
                    <span>异常用户</span>
                </Title>
                <div className='flex-between'>
                    <div data-op={1} onClick={this.opChange.bind(this)} className='cur-pointer' style={{ marginRight: 20, color: this.state.op === 1 ? '#ff5156' : '#999' }}>未处理</div>
                    <div data-op={0} onClick={this.opChange.bind(this)} className='cur-pointer' style={{ marginRight: 20, color: this.state.op === 0 ? '#ff5156' : '#999' }}>已处理</div>
                </div>
                <div style={{ width: '40%' }}>
                    <Search onChange={(e) => {
                        this.setState({ search: e })
                    }} onSearch={() => {
                        this.props.history.push(`/bu/list?s=${this.state.search}&p=1&op=${this.state.op}${this.state.rate !== undefined ? `&rate=${this.state.rate}` : ''}`)
                    }} search={this.state.search} placeholder='搜索用户微信昵称或小程序昵称' />
                </div>
            </div>

            <Table>
                <thead>
                    <tr style={{ background: '#f5f5f5' }}>
                        <td style={{ width: 160 }}>微信</td>
                        <td style={{ width: 160 }}>小程序</td>
                        <td style={{ width: 100 }}>历史总流水</td>
                        <td style={{ width: 180 }}>账户余额</td>
                        <td style={{ width: 160 }}>原因</td>
                        <td style={{ width: 200 }}>异常订单金额</td>
                        <td style={{ width: 150 }}>操作</td>
                        {/* <td style={{ width: 200 }}>操作记录</td> */}
                    </tr>
                </thead>
                <tbody>
                    {this.state.list.map((item: user, i: number) => {
                        return <tr key={item.id} style={{ background: i % 2 ? '#f5f5f5' : '#fff' }}>
                            <td onMouseEnter={e => {
                                item.showMenuFlag = true
                                setTimeout(() => {
                                    if (!item.showMenuFlag) return
                                    item.showMenu = true
                                    this.setState({
                                        list: this.state.list
                                    })
                                }, 300);
                            }} onMouseLeave={e => {
                                item.showMenuFlag = false
                                item.showMenu = false
                                this.setState({
                                    list: this.state.list
                                })
                            }} >
                                <div className='flex-box' style={{ position: 'relative' }}>
                                    {item.showMenu ? <FloatPanel>
                                        <div style={{ padding: 14 }}>
                                            <div className='fbc' style={{ marginBottom: 14 }}>
                                                <Logo style={{ marginRight: 14 }} src={item.userlogo} />
                                                <div style={{ flex: 1 }}>
                                                    <Name style={{ maxWidth: '11em' }}>{item.username}</Name>
                                                </div>
                                            </div>
                                            <div>可提现金额</div>
                                            <Name style={{ fontSize: 24 }}>￥{((item.account_total || 0) / 100).toFixed(2)}</Name>
                                        </div>
                                        <FloatCtrl>
                                            <NavLink to={{ pathname: `/user/detail/${item.sid}`, search: 'mode=2', state: { user: item } }}><FloatLink>提现记录</FloatLink></NavLink>
                                            <NavLink to={{ pathname: `/user/detail/${item.sid}`, search: 'mode=3', state: { user: item } }}><FloatLink>流水明细</FloatLink></NavLink>
                                            <NavLink to={{ pathname: `/user/detail/${item.sid}`, search: 'mode=1', state: { user: item } }}><FloatLink>TA的发布</FloatLink></NavLink>
                                        </FloatCtrl>
                                    </FloatPanel> : null}
                                    <Logo src={item.userlogo} style={{marginRight: 12,}} />
                                    <Name>{item.username}</Name>
                                </div>
                            </td>
                            <td>
                                <div className='flex-box'>
                                    <Logo src={item.shoplogo} style={{marginRight: 12,}} />
                                    <Name>{item.shopname}</Name>
                                </div>
                            </td>
                            <td>
                                <Name style={{ minWidth: '4em' }}>￥{(item.income_total / 100).toFixed(2)}</Name>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                {/* 账户余额 */}
                                <Name style={{ minWidth: '4em' }}>￥{(item.account_total / 100).toFixed(2)}</Name>
                            </td>
                            <td>
                                {/* 封号原因 */}
                                <Name style={{ minWidth: '4em' }}>{(item.exception_text)}</Name>
                            </td>
                            <td>
                                {/* 异常金额 */}
                                <Name style={{ minWidth: '4em' }}>￥{(item.exception_amount / 100).toFixed(2)}</Name>
                                <button className='button button_hollow_red' onClick={()=>{
                                    window.open(`/bu/user/${item.sid}?mode=4&exid=${item.id}`, '_blank')
                                }}>查看异常订单</button>
                            </td>
                            <td>
                                {this.state.op === 1 ? <div className='flex-between'>
                                    <button className='button button_hollow_red' onClick={() => {
                                        const num = prompt('请输入扣款金额')
                                        if (!!!Number(num)) {
                                            message.error('请输入合法数字')
                                            return
                                        }
                                        const data = {
                                            op: 'deduct_money',
                                            id: item.id,
                                            amount: Number(num) * 100
                                        }
                                        post('/god/handleexceptionshop', data).then(() => {
                                            this.getData()
                                        }).catch((e: string) => {
                                            message.error(e)
                                        })
                                    }}>扣钱</button>
                                    <button className='button button_hollow_red' onClick={() => {
                                        confirm({
                                            text: '确定解封该账号？'
                                        }).then(res => {
                                            if (res) {
                                                const data = {
                                                    op: 'relieve',
                                                    id: item.id,
                                                }
                                                post('/god/handleexceptionshop', data).then(() => {
                                                    this.getData()
                                                }).catch((e: string) => {
                                                    message.error(e)
                                                })
                                            }
                                        })
                                    }}>解封</button>
                                </div> : null}
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>

            {this.state.setting && this.state.settingUser ? <Modal title={`将${this.state.settingUser.shopname}设为会员`} onClose={() => { this.setState({ setting: false, settingUser: undefined }) }} onConfirm={() => {
                if (!this.state.settingUser) return
                const item = this.state.settingUser
                const data = { sid: item.sid, types: 1, proxy_type: this.state.settingOp }
                console.log(item, data)
                post('/god/setfreevip', data).then(() => {
                    this.setState({ list: [] })
                    this.getData()
                    message.success('设置成功')
                }).catch((res: string) => message.error(res))
            }}>
                <div style={{ width: 500 }}>
                    <div style={{ width: 350 }}>
                        <div className='fbc'>
                            <div>微信</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.userlogo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.username}</Name>
                            </div>
                        </div>
                        <div className='fbc'>
                            <div>小程序</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.shoplogo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.shopname}</Name>
                            </div>
                        </div>
                        <div className='fbc'>
                            <div>上级</div>
                            <div className='fbc' style={{ position: 'relative' }}>
                                <Logo style={{ marginRight: 16 }} src={this.state.settingUser.p_logo} />
                                <Name style={{ minWidth: 'unset', maxWidth: 'unset' }}>{this.state.settingUser.p_shopname}</Name>
                            </div>
                        </div>
                    </div>
                    <div>
                        将{this.state.settingUser.shopname}设为
                        <span><Select list={[
                            { name: '黄金供应商', op: 1 },
                            { name: '钻石供应商', op: 2 },
                            { name: '高级会员', op: 4 },
                            { name: '特邀会员', op: 6 },
                            { name: '设置0.006', op: 7 },
                        ]} showKey={'name'} uni='proxy_type' value={[
                            { name: '黄金供应商', op: 1 },
                            { name: '钻石供应商', op: 2 },
                            { name: '高级会员', op: 4 },
                            { name: '特邀会员', op: 6 },
                            { name: '设置0.006', op: 7 },
                        ].findIndex(i => { return this.state.settingOp === i.op })} onChange={e => {
                            if (e.op === this.state.settingOp) return
                            this.setState({ settingOp: e.op })
                        }} /></span>
                    </div>
                </div>
            </Modal> : null}

            <Pagination total={this.state.userCount} current={this.state.page} pageSize={this.pageSize} showQuickJumper={true} onChange={e => {
                let url = `/bu/list?s=${this.state.search}&p=${e}&op=${this.state.op}`
                console.log(url);
                this.props.history.push(url)
            }} />

            {/* {this.state.showVideoPremission && this.state.settingUser ? <Modal onClose={() => { this.setState({ showVideoPremission: false }) }} cancel onConfirm={this.setVideoPermission.bind(this, [this.state.settingUser.sid])}>
                <div>确定为{this.state.settingUser.shopname}开通短视频带货功能？</div>
            </Modal> : null}

            {this.state.showCardPremission && this.state.settingUser ? <Modal onClose={() => { this.setState({ showCardPremission: false }) }} cancel onConfirm={this.setWithdrawToCard.bind(this, this.state.settingUser.sid)}>
                <div>确定为{this.state.settingUser.shopname}开通提现到银行卡功能？</div>
            </Modal> : null} */}
        </div>
    }

    opChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.props.history.push(`/bu/list?s=${this.state.search}&p=1&op=${e.currentTarget.dataset.op}`)
    }

    async getData() {
        loading.show('加载中')
        const data: any = {
            limit: this.pageSize,
            offset: (this.state.page - 1) * this.pageSize,
            search: this.state.search,
            op: this.state.op,
            types: 1,
            type: 'shoplist'
        }
        const res = await get('/god/getexceptionshoplistororderlist', data)
        loading.hide()
        this.setState({
            userCount: Number(res.count),
            list: res.list.map((item: any): user => {
                const typeText = userlevel(Number(item.type))
                return {
                    ...item,
                    income_total: Number(item.income_total),
                    service_tip: Number(item.service_tip),
                    pid: Number(item.pid),
                    type: Number(item.type),
                    typeText,
                    sid: Number(item.sid),
                    video_permission: Boolean(item.video_permission),
                    withdraw_to_bank_permission: Boolean(item.withdraw_to_bank_permission),
                    shop_payment_platform: Number(item.shop_payment_platform),
                    is_brand_street_user: item.is_brand_street_user,
                    exception_text: item.exception_text,
                    exception_amount: Number(item.exception_amount),
                    account_total: Number(item.account_total),
                    id: item.id
                }
            }),
        })
    }

    async setVideoPermission(e: number[]) {
        post('/god/setvideopermission', {
            sid_list: e,
            types: 1,
        }).then(() => {
            this.setState({ list: [], showVideoPremission: false, settingUser: undefined })
            this.getData()
        }).catch((error: string) => {
            message.error(error)
        })
    }
    async setWithdrawToCard(e: number) {
        post('/god/setspecialfunction', {
            sid: e,
            types: 1,
            type: 2,
        }).then(() => {
            this.setState({ list: [], showCardPremission: false, settingUser: undefined })
            this.getData()
        }).catch((error: string) => {
            message.error(error)
        })
    }
    setArrivalTime(item: user, type: number) {
        if (Number(item.withdraw_way_status_data.to_bank_accounting_date) === type) return
        Loading.show()
        post('/god/setshopconfig', { op: 4, types: 1, withdraw_accounting_date: type, sid: item.sid }).then(() => {
            Loading.hide()
            item.withdraw_way_status_data.to_bank_accounting_date = type
            this.setState({ list: this.state.list })
        }).catch((e: string) => {
            Loading.hide()
            message.error(e)
        })
    }

    setWithdrawType(item: user, type: 1 | 2) {
        const _type = {
            wechat_status: type === 1 ? Number(!Boolean(Number(item.withdraw_way_status_data.wechat_status))) : item.withdraw_way_status_data.wechat_status,
            bank_status: type === 2 ? Number(!Boolean(Number(item.withdraw_way_status_data.bank_status))) : item.withdraw_way_status_data.bank_status,
        }
        Loading.show()
        post('/god/setshopconfig', { op: 3, types: 1, withdraw_way_status_data: _type, sid: item.sid }).then(() => {
            Loading.hide()
            item.withdraw_way_status_data = {
                ...item.withdraw_way_status_data,
                ..._type,
            }
            this.setState({ list: this.state.list })
        }).catch((e: string) => {
            Loading.hide()
            message.error(e)
        })
    }
}

const mapStateToProps = () => {
    return {
        // prop: state.prop
    }
}
export default connect(mapStateToProps)(Content(User))