import React, { Component } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { toggleSelectPanel, toggleSidebar } from '../reducer/index'

import Home from '../components/page/home/index.tsx';

// 左侧边栏
import Sidebar from '../components/Sidebar/sidebar'
import '../components/Sidebar/Sidebar.scss'

// 登录控制器
import Login from '../components/Login.tsx';

// 用户
import SpecialUser from '../components/page/Ctrl/User';
import SpecialUserDetail from '../components/page/Ctrl/Detail';

// 人工认证列表
import ManualCertifiedList from '../components/page/Ctrl/ManualCertifiedList';

// 销售汇总
import SaleData from '../components/page/Data/SaleData';

// 错误log
import Log from '../components/page/Log/Log.tsx'
import Apilog from '../components/page/Log/apilog.tsx'

// 供应商管理
import Suppliers_List from '../components/page/Suppliers/List.tsx';

// 提现数据
import WithdrawData from '../components/page/Data/WithdrawData';

// 品牌街
import BrandStreet from '../components/page/BrandStreet/Setting.tsx';

// banned user
import BannedUserList from '../components/page/BannedUser/List.tsx';
import auth from '../components/page/paycode/auth';
import upload from '../components/page/app/upload';
import themeControl from '../components/page/theme/themeControl';

class R extends Component {
    hideSelectPanel() {
        const closeList = [
            ['showSelectPanel', 'hideSelectPanel'],
            ['sidebarOpen', 'hideSidebar']
        ]
        for (let i = 0; i < closeList.length; i++) {
            const item = closeList[i];
            if (!this.props[item[0]]) continue;

            if (item[0] === 'sidebarOpen') {
                if (document.body.clientWidth > 993) continue;
            }
            this.props[item[1]]()
        }
    }
    render() {
        return (
            <div onClick={this.hideSelectPanel.bind(this)}>
                <Router>
                    <Sidebar />
                    <Switch>
                        <Route path='/home' exact component={Home} />
                        {/* SpecialUser */}
                        <Route path='/user/spec' exact component={SpecialUser} />
                        <Route path='/user/detail/:id' exact component={SpecialUserDetail} />
                        <Route path='/user/manualcertified' component={ManualCertifiedList} />
                        <Route path='/data/:type' component={SaleData} />
                        <Route path='/withdrawdata/:type' component={WithdrawData} />
                        <Route path='/log/:type' component={Log} />
                        <Route path='/apilog/:type' component={Apilog} />
                        <Route path='/apilog' component={Apilog} />
                        <Route path='/suppliers/list' component={Suppliers_List} />
                        <Route path='/suppliers' component={Suppliers_List} />
                        <Route path='/brand/home' component={BrandStreet} />
                        <Route path='/bu/list' component={BannedUserList} />
                        <Route path='/bu/user/:id' component={SpecialUserDetail} />
                        <Route path='/paycode/auth' component={auth} />
                        <Route path='/app/upload' component={upload} />
                        <Route path='/theme/ctrl' component={themeControl} />
                        <Redirect to="/home" />
                    </Switch>
                    <Login />
                    {/* <Loading /> */}
                </Router>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        showSelectPanel: state.showSelectPanel,
        sidebarOpen: state.sidebarOpen,
        is_Login: state.is_Login,
        showLoginPanel: state.showLoginPanel,
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        hideSelectPanel: () => {
            dispatch(toggleSelectPanel(false))
        },
        hideSidebar: () => {
            dispatch(toggleSidebar(false))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(R);