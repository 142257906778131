const userlevel: (type?: number) => string = function(type){
    switch (Number(type)) {
        case 1:
            return '黄金供应商'
        case 2:
            return '钻石供应商'
        case 3:
            return '初级会员'
        case 4:
            return '高级会员'
        case 5:
            return '普通用户'
        case 6:
            return '特邀会员'
        default:
            return '全部用户'
    }
}

export default userlevel