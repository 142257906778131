import React, { useState, useEffect, useRef, } from 'react';
import styled from 'styled-components';
import { get, post } from '../../../netApi/fetch';
import Modal from '../../total/Modal';
import Search from '../../total/Input/Search';
import { message } from 'antd';

interface Props {
    onClose: () => void;
}
interface shopItem {
    id: string,
    logo: string,
    name: string,
    tuanNum: string,
}

interface tuanItem {
    title: string;
    images: {
        src: string;
    }[];
    id: string | number;
    is_brand_street_tuan: number| string|boolean;
}

const ShopItem = styled.div`
    padding: 16px 10px;
    &:hover{
        background: #eee;
    }
`

export default function (props: Props) {
    const [page, setPage] = useState(1)
    const getting = useRef(false)
    const limit = 20

    // page1 搜索商家
    const [searchUserKey, setSUK] = useState('')
    const userOffset = useRef(0)
    const noMoreUser = useRef(false)
    const [shoplist, setShopList] = useState<shopItem[]>([])
    const selectedUserId = useRef('') // 选择的商家

    // page2 搜索活动
    const [searchTuanKey, setSTK] = useState('')
    const tuanOffset = useRef(0)
    const noMoreTuan = useRef(false)
    const [tuanlist, setTuanList] = useState<tuanItem[]>([])

    useEffect(() => {
        if (shoplist.length === 0) {
            userOffset.current = 0
            noMoreUser.current = false
            searchUser(searchUserKey)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoplist])

    return <Modal title={(() => {
        if (page === 1) return '选择品牌'
        if (page === 2) return <div className='cur-pointer' onClick={() => { setPage(1); setTuanList([]); tuanOffset.current = 0; noMoreTuan.current = false; }} >
            <i className='iconfont icon-arrow-fine-left' />&nbsp;&nbsp; 返回
        </div>
    })()} onClose={props.onClose} >
        {page === 1
            ? <>
                <Search placeholder='请输入品牌名称' search={searchUserKey} onChange={setSUK} onSearch={() => {
                    setShopList([])
                }} />
                <div style={{ maxHeight: 370, overflowY: 'auto', width: 618, height: 370 }} onScroll={e => {
                    if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 50) {
                        searchUser(searchUserKey)
                    }
                }} >
                    {shoplist.map(i => {
                        return <ShopItem key={i.id} className='flex-between cur-pointer' onClick={() => {
                            setPage(2)
                            selectedUserId.current = i.id
                            searchTuan()
                        }} >
                            <div style={{ marginRight: 16, }}>
                                <img alt='' src={i.logo} className='img-center' style={{ width: 36, height: 36, }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div>{i.name}</div>
                                <div>共{i.tuanNum}个活动</div>
                            </div>
                        </ShopItem>
                    })}
                </div>
            </>
            : null}
        {page === 2
            ? <>

                <Search placeholder='请输入品牌名称' search={searchTuanKey} onChange={setSTK} onSearch={() => {
                    setTuanList([])
                    tuanOffset.current = 0
                    noMoreTuan.current = false
                    searchTuan()
                }} />
                <div style={{ maxHeight: 370, overflowY: 'auto', width: 618, height: 370 }} onScroll={e => {
                    if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 50) {
                        searchTuan()
                    }
                }} >
                    {tuanlist.map(i => {
                        return <ShopItem key={i.id} className='flex-between cur-pointer'>
                            <div style={{ marginRight: 16, }}>
                                <img alt='' src={i.images[0]?.src || 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/KsNUJJJEJh.png'} className='img-center' style={{ width: 36, height: 36, }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div>{i.title}</div>
                            </div>
                            <div className='button_hollow_red' style={{ padding: '4px 11px' }} onClick={() => {
                                addTuan(i.id).then(()=>{
                                    i.is_brand_street_tuan = 1
                                    setTuanList([...tuanlist])
                                })
                            }} >{Number(i.is_brand_street_tuan) === 1 ? '已添加' : '添加'}</div>
                        </ShopItem>
                    })}
                </div>
            </>
            : null}

    </Modal>

    function searchUser(name: string) {
        if (getting.current === true) return
        if (noMoreUser.current === true) return
        getting.current = true

        get('/brandstreet/shoplist', {
            name, offset: userOffset.current, limit,
        }).then((res: any) => {
            getting.current = false
            userOffset.current += res.shops.length
            setShopList([...shoplist, ...res.shops])
            if (res.shops.length === 0) {
                noMoreUser.current = true
            }
        })
    }

    function searchTuan() {
        if (getting.current === true) return
        if (noMoreTuan.current === true) return
        getting.current = true
        get('/brandstreet/tuanlist', {
            sid: selectedUserId.current,
            offset: tuanOffset.current,
            limit,
        }).then((res: any) => {
            getting.current = false
            tuanOffset.current += res.list.length
            setTuanList([...tuanlist, ...res.list])
            if (Number(res.count) <= tuanOffset.current) {
                noMoreTuan.current = true
            }
        })
    }

    function addTuan(id: string | number) {
        return new Promise<void>((resolve, rej)=> {
            post('/brandstreet/activeaddorupdate', {
                scene: 'add', tuanId: id,
            }).then((res: any) => {
                message.success('添加成功')
                resolve()
            }).catch((e: string) => {
                message.error(e)
            })
        })
    }
}