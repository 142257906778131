
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import api from '../../../../netApi/index';
import { post } from '../../../../netApi/fetch.ts';
import { message } from 'antd';
import Modal from '../../../total/Modal';
import { appname } from '../../../../data/options';
import { connect } from 'react-redux'
import Tooltip from '../../../total/Show/Tooltip.tsx';

class Item extends Component {

    constructor() {
        super()
        this.state = {
            showMenu: false
        }
    }

    sticky(e) {
        e.preventDefault()
        post('/tuan/updatetuanbystatus', {
            tuanid: this.props.data.id,
            type: 'istop',
            types: 1,
        }).then(res => {
            message.success(`${Number(this.props.data.istop) === 1 ? '取消置顶' : '置顶'}成功`)
            this.props.reload()
        })
    }

    render() {
        const status = Number(this.props.data.status)
        const classicon = 'mytuanlist__item__status__icon'
        const classShare = 'mytuanlist__item__sharePanel'

        let SMALL_SCREEN = false
        const showCtrl = (() => {
            if (document.body.clientWidth < 660) {
                SMALL_SCREEN = true
                return true
            }
            return this.props.forceHover || this.state.hover
        })()
        return (
            <a target='_blank' rel="noopener noreferrer" href={`https://www.jieligo.net/tuan/${btoa(this.props.data.id)}`} style={{ position: 'relative', background: this.props.forceHover ? '#f8f8f8' : null }} onMouseLeave={() => {
                this.setState({ showMenu: false, hover: false })
            }} onMouseEnter={() => {
                this.setState({ hover: true })
            }}>
                {Number(this.props.data.istop) === 1 ? <span className='mytuanlist__item__stickySpan'>置顶</span> : null}
                <div className='fcc mytuanlist__item'>
                    <div style={{ flex: 1, alignItems: 'flex-start', minWidth: SMALL_SCREEN ? '70%' : '' }} className='fbc'>
                        <div style={{ width: 36, height: 36, marginTop: 20 }}>
                            <img alt='' src={(this.props.data.image && this.props.data.image.src) ? this.props.data.image.src + '?imageView2/5/w/200' : 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/KsNUJJJEJh.png'} className='img-center' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div style={{ flex: 1, padding: !SMALL_SCREEN ? 20 : '20px 0 20px 20px', }}>
                            <div className='mytuanlist__item__title' style={{ marginBottom: 12 }}>
                                <span className=' cur-pointer' title={this.props.data.title}>{this.props.data.title}</span>
                            </div>
                            <div className='fc' style={{ flexWrap: 'wrap', marginBottom: 16 }}>
                                {status === 0 ? <span className={classicon} style={{ color: '#999', borderColor: '#999' }}>已删除</span> : ''}
                                {status === 1 ? <span className={classicon} style={{}}>正在开团</span> : ''}
                                {status === 2 ? <span className={classicon} style={{ color: '#999', borderColor: '#999' }}>已暂停</span> : ''}
                                {Number(this.props.data.pid) > 0 ? <span className={classicon} style={{ color: status === 2 ? '#999' : '#f7b441', borderColor: status === 2 ? '#999' : '#f7b441' }}>帮好友卖</span> : ''}
                                {Number(this.props.data.pid) === 0 ? <span className={classicon} style={{ color: status === 2 ? '#999' : '#ff5156', borderColor: status === 2 ? '#999' : '#ff5156' }}>已开帮卖</span> : ''}
                                <span style={{ minWidth: '5.5em', fontSize: 12, color: '#858585' }}>{this.props.data.showtime}</span>
                                {Number(this.props.data.isprivate) === 1 ? <i style={{ color: '#999' }} className="iconfont activitItemIcon icon-yincang" /> : null}
                            </div>
                            <div className='fc' style={{ flexWrap: 'wrap', fontSize: 12, color: '#858585', }}>
                                <span style={{ marginRight: 12, paddingRight: 12 }}>{this.props.data.views}人访问</span>
                                {!this.props.noCtrl ? <> <NavLink to={'/orders/q/t=' + encodeURIComponent(window.btoa(this.props.data.id))}>
                                    <div className='cur-pointer mytuanlist__item__textLink' title='我的订单'>
                                        我的订单
                                    </div>
                                </NavLink>
                                    {Number(this.props.data.isdistribution) === 1 ? <NavLink to={'/orders/q/t=' + encodeURIComponent(window.btoa(this.props.data.id)) + '&st=' + encodeURIComponent(window.btoa(this.props.data.id)) + '&op=all&p=1&ot=2&dt=2'}>
                                        <div className='cur-pointer mytuanlist__item__textLink' title='好友订单'>
                                            好友订单
                                    </div>
                                    </NavLink> : null} </> : null}
                            </div>
                        </div>

                    </div>
                    {!this.props.noCtrl ? <>
                        <div className='mytuanlist__item__menu-button'>
                            {showCtrl ? <button className='cur-pointer mytuanlist__item__shareButton' onClick={e => {
                                e.preventDefault()
                                this.getCode()
                                this.setState({
                                    showCode: true
                                })
                            }} title='' >
                                转发
                            </button> : null}

                            {/* 置顶 */}
                            {showCtrl || Number(this.props.data.istop) === 1 ? <button className='cur-pointer mytuanlist__item__menu__item'
                                onMouseEnter={() => { this.setState({ istopActive: true }) }}
                                onMouseLeave={() => { this.setState({ istopActive: false }) }}
                                onClick={this.sticky.bind(this)}>
                                <i className={`iconfont ${Number(this.props.data.istop) === 1 ? 'icon-zhidingxian' : 'icon-zhidingxianbeifen'}`}
                                    style={{ color: `${Number(this.props.data.istop) === 1 ? '#FF9A27' : ''}` }}
                                />
                                <Tooltip show={this.state.istopActive} title={`${Number(this.props.data.istop) === 1 ? '取消置顶' : '置顶活动'}`} />
                            </button> : null}

                            {/* 跳转至编辑页面 */}
                            {showCtrl ? <NavLink to={'/event/edit/' + window.btoa(this.props.data.id)}>
                                <button className='mytuanlist__item__menu__item mytuanlist__item__menu__item_hover' title='编辑活动'
                                    onMouseEnter={() => { this.setState({ editActive: true }) }}
                                    onMouseLeave={() => { this.setState({ editActive: false }) }}>
                                    <i className='icon-bianji iconfont' />
                                    <Tooltip show={this.state.editActive} title='编辑活动' />
                                </button>
                            </NavLink> : <button className='mytuanlist__item__menu__item mytuanlist__item__menu__item_hover' style={{ opacity: 0, cursor: 'default' }}>
                                    <i className='icon-bianji iconfont' />
                                </button>}


                            <button className='cur-pointer' style={{ border: 0 }} onClick={e => {
                                e.preventDefault()
                                this.setState({ showMenu: !this.state.showMenu, })
                            }} onMouseEnter={() => { this.setState({ showMenu: true, }) }}>
                                <i className='icon-gengduo iconfont' style={{ color: '#999' }} />
                                <div className='mytuanlist__item__menu' style={{
                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                                    transformOrigin: 'top',
                                    transition: 'transform .2s ease',
                                    transform: this.state.showMenu ? 'scaleY(1)' : 'scaleY(0)',
                                }} onMouseLeave={() => { this.setState({ showMenu: false }) }}>

                                    {/* 删除活动 */}
                                    <div className='mytuanlist__item__menu__item mytuanlist__item__menu__item_hover' onClick={e => {
                                        e.preventDefault()
                                        post('/tuan/updatetuanbystatus', {
                                            tuanid: this.props.data.id,
                                            type: "hidden",
                                            types: 1,
                                        }).then(res => {
                                            message.success(`${Number(this.props.data.isprivate) === 1 ? '取消隐藏' : '隐藏'}成功`)
                                            this.props.reload()
                                        })
                                    }}>
                                        {`${Number(this.props.data.isprivate) === 1 ? '取消隐藏' : '隐藏活动'}`}
                                    </div>

                                    {/* 暂停活动 */}
                                    <div className='mytuanlist__item__menu__item mytuanlist__item__menu__item_hover' onClick={() => {
                                        api('tuan', 'tuanbutton')({}, 'post', {
                                            types: 1,
                                            id: this.props.data.id,
                                            status: Number(this.props.data.status) === 1 ? 1 : 2
                                        }).then(res => {
                                            if (res.status === 200) {
                                                if (res.data.code === 0) {
                                                    message.success(`活动《${this.props.data.title}》已${Number(this.props.data.status) === 1 ? '暂停' : '开始'}`)
                                                    this.props.reload()
                                                }
                                            }
                                        })
                                    }}>
                                        {`${Number(this.props.data.status) === 1 ? '暂停' : '开始'}`}活动
                                    </div>

                                    {/* 删除活动 */}
                                    <div className='mytuanlist__item__menu__item mytuanlist__item__menu__item_hover' onClick={() => {
                                        if (!window.confirm(`确定删除团购《${this.props.data.title}》吗？删除后不可恢复`)) return
                                        api('tuan', 'delete')({}, 'post', {
                                            types: 1,
                                            id: this.props.data.id
                                        }).then(res => {
                                            if (res.status === 200) {
                                                if (res.data.code === 0) {
                                                    message.warning(`活动《${this.props.data.title}》已删除`)
                                                    this.props.reload()
                                                }
                                            }
                                        })
                                    }}>
                                        删除活动
                                    </div>

                                    {/* 复制活动 */}
                                    <NavLink style={{ display: 'block' }} to={'/event/copy/' + window.btoa(this.props.data.id)} className='mytuanlist__item__menu__item mytuanlist__item__menu__item_hover'>
                                        <span style={{ color: '#666' }}>复制活动</span>
                                    </NavLink>
                                </div>
                            </button>
                        </div>
                    </> : null}
                </div>

                {this.state.showCode ? (
                    <Modal close={() => { this.setState({ showCode: false }) }} title='转发活动' showConfirm={false} >
                        <div className={`${classShare}`} onClick={e => e.preventDefault()}>
                            <div className='fbc'>
                                <div className={`${classShare}__type ${classShare}__type_active`}>
                                    <h3>转发给用户下单</h3>
                                    <p>供用户下单，查看对外公开售价</p>
                                </div>
                                {/* <div className={`${classShare}__type`}>
                                    <h3>转发给好友帮卖</h3>
                                    <p>仅供用户下单，查看对外公开售价</p>
                                </div> */}
                            </div>

                            <div>
                                <div className={`${classShare}__link fbc`} style={{ color: '#666' }} >
                                    <span>H5分享链接</span>
                                    <input className='disableInput' onClick={() => {
                                        const input = document.querySelector('.disableInput')
                                        input.select();
                                        if (document.execCommand('copy')) {
                                            document.execCommand('copy');
                                            message.success('复制成功')
                                        }
                                    }} readOnly value={`https://www.jieligo.net/${this.props.user.self_sid}/tuan/${window.btoa(this.props.data.id)}`} />
                                </div>
                            </div>

                            <div style={{ color: '#666', fontSize: 14 }}>活动小程序码</div>
                            <div style={{ margin: 24, textAlign: 'center' }}>
                                {(() => {
                                    if (!this.state.code_src) return (<div className='fcc' style={{ position: 'absolute', width: '100%', top: 0, left: 0, height: '100%', background: 'rgba(255,255,255,.6)' }}>
                                        <i className='iconfont icon-xingzhuang' style={{ fontSize: 32, color: 'black' }}></i>
                                    </div>)
                                    const code = new Image()
                                    code.src = this.state.code_src
                                    return <img alt='' style={{ width: 146, height: 146 }} src={code.src} />
                                })()}
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </a>
        )
    }

    getCode() {
        if (!this.state.code_src) {
            api('user', 'getwxacode')({ app: appname }, 'post', {
                page: 'pages/good/good', scene: `id=${this.props.data.id}&code=2`,
                width: 280, types: 1
            }).then(res => {
                if (res.status !== 200) return
                if (Number(res.data.code) === 0) {
                    this.setState({ code_src: res.data.data.image })
                }
            })
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login_user_data,
    }
}

export default connect(
    mapStateToProps,
)(Item)

