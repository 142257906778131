import React, { useState, } from 'react';
import { DatePicker, message } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import zhCN from 'antd/es/locale/zh_CN';
import styled from 'styled-components';
import moment from 'moment';
import { post } from '../../../netApi/fetch';
const { RangePicker } = DatePicker



const Border = styled.div`
    padding: 5px 11px;
    border-radius: 4px;
    border: 1px solid #999;
    color: #999;
    font-size: 12px;
    &:not(:last-child){
        margin-right: 12px;
    }
`

const Panel = styled.div`
    :hover{
        background-color: rgb(248, 248, 248);
    }
`

export default function (props: {
    data: {
        tuan: contentListItem;
        id: string;
        end_time: string | null;
        rank: string;
        start_time: string | null;
        status: string | number;
    },
    maxRank: string | number;
    refresh: () => void;
}) {

    const [rank, setRank] = useState<number>(Number(props.data.rank))
    const tuan = props.data.tuan

    const [time, setTime] = useState<RangePickerValue>((props.data.start_time && props.data.end_time) ? [
        moment(props.data.start_time),
        moment(props.data.end_time),
    ] : [undefined])
    const status = Number(tuan.status)
    const classicon = 'mytuanlist__item__status__icon'

    return <Panel className='flex-between'>
        <a target='_blank' rel="noopener noreferrer" href={`https://www.jieligo.net/tuan/${btoa(tuan.id)}`} style={{ position: 'relative', flex: 1, }}>
            {Number(tuan.istop) === 1 ? <span className='mytuanlist__item__stickySpan'>置顶</span> : null}
            <div className='fcc mytuanlist__item'>
                <div style={{ flex: 1, alignItems: 'flex-start', }} className='fbc'>
                    <div style={{ width: 36, height: 36, marginTop: 20 }}>
                        <img alt='' src={(tuan.image && tuan.image.src) ? tuan.image.src + '?imageView2/5/w/200' : 'https://jieligo-1259536745.picgz.myqcloud.com/uploads/tuan/KsNUJJJEJh.png'} className='img-center' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div style={{ flex: 1, padding: '20px 0 20px 20px', }}>
                        <div className='mytuanlist__item__title' style={{ marginBottom: 12 }}>
                            <span className=' cur-pointer' title={tuan.title}>{tuan.title}</span>
                        </div>
                        <div className='fc' style={{ flexWrap: 'wrap', marginBottom: 16 }}>
                            {status === 0 ? <span className={classicon} style={{ color: '#999', borderColor: '#999' }}>已删除</span> : ''}
                            {status === 1 ? <span className={classicon} style={{}}>正在开团</span> : ''}
                            {status === 2 ? <span className={classicon} style={{ color: '#999', borderColor: '#999' }}>已暂停</span> : ''}
                            {Number(tuan.pid) > 0 ? <span className={classicon} style={{ color: status === 2 ? '#999' : '#f7b441', borderColor: status === 2 ? '#999' : '#f7b441' }}>帮好友卖</span> : ''}
                            {Number(tuan.pid) === 0 ? <span className={classicon} style={{ color: status === 2 ? '#999' : '#ff5156', borderColor: status === 2 ? '#999' : '#ff5156' }}>已开帮卖</span> : ''}
                            <span style={{ minWidth: '5.5em', fontSize: 12, color: '#858585' }}>{tuan.showtime}</span>
                            {Number(tuan.isprivate) === 1 ? <i style={{ color: '#999' }} className="iconfont activitItemIcon icon-yincang" /> : null}
                        </div>
                        <div className='fc' style={{ flexWrap: 'wrap', fontSize: 12, color: '#858585', }}>
                            <span style={{ marginRight: 12, paddingRight: 12 }}>{tuan.visitors}人访问</span>
                        </div>
                    </div>
                </div>
            </div>
        </a>
        <div style={{ paddingRight: 16 }}>
            <div className='flex-between' style={{ marginBottom: 12 }}>
                <RangePicker style={{ marginRight: 12 }}
                    value={time}
                    showTime locale={zhCN.DatePicker} onChange={(e) => {
                        setTime(e)
                    }} />
                <Border className='cur-pointer' onClick={save}>保存</Border>
            </div>
            <div className='flex-box'>
                <Border className='flex-between'>
                    <div>排序序号</div>
                    <input value={rank} style={{ border: 0, textAlign: 'center' }} onChange={(e) => setRank(Number(e.target.value))} type='number' />
                </Border>
                <Border className='cur-pointer' onClick={setRank.bind(null, Number(props.maxRank) + 1)}>置顶</Border>
                <Border className='cur-pointer' onClick={() => { cancel() }}>{Number(props.data.status) === 1 ? '上架中' : '未上架'}</Border>
            </div>
        </div>
    </Panel>

    function save() {
        const startTime = time[0]?.format('YYYY-MM-DD HH:mm:ss')
        const endTime = time[1]?.format('YYYY-MM-DD HH:mm:ss')
        if (!startTime || !endTime) {
            message.warning('请选择时间')
            return
        }
        console.log(startTime, endTime);
        post('/brandstreet/activeaddorupdate', {
            startTime,
            endTime,
            rank,
            brandStreetTuanId: props.data.id,
            scene: 'upd',
            status: 1,
        }).then(() => {
            message.success('设置成功')
            props.refresh()
        })
    }

    function cancel() {
        if (Number(props.data.status) === 0) return
        post('/brandstreet/activeaddorupdate', {
            brandStreetTuanId: props.data.id,
            scene: 'upd',
            status: 0,
        }).then(() => {
            message.success('设置成功')
            props.refresh()
        })
    }
}