import React, { useState, useEffect, } from 'react';
import Content from '../../../high-components/Content';
import zhCN from 'antd/es/locale/zh_CN';
import { Pagination, DatePicker } from 'antd';
import styled from 'styled-components';
import { RouteComponentProps, } from 'react-router-dom'
import { get } from '../../../netApi/fetch';
import Item from './TuanItem';
import moment from 'moment';
import SelectEvent from './Select';
interface Props extends RouteComponentProps {

}
const SelectTime = styled.div`
    padding: 16px 20px;
    & > div{
        margin-right: 20px;
        font-size: 12px;
        color: #666;
        &.active{
            color: #ff5156;
            font-size: 16px;
        }
    }
`

export default Content(function (props: Props) {
    const limit = 10
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [maxRank, setMaxRank] = useState<number>(0)
    const [status, setStatus] = useState<number>(1)
    const [showSelectEvent, setShowSelectEvent] = useState(false)
    const [date, setDate] = useState<moment.Moment | undefined>()
    const [list, setList] = useState<{
        tuan: contentListItem;
        id: string;
        end_time: string | null;
        rank: string;
        start_time: string | null;
        status: number | string;
    }[]>([])
    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, date, status, ])

    useEffect(() => {
        document.title = '帮卖广场活动管理'
        return () => {document.title = '接力GO'}
    }, [])

    const dateStatus = ((): string => {
        if (status === 0) return 'del'
        if (!date) return ''
        const str = date.format('YYYY-MM-DD')
        switch (str) {
            case moment().format('YYYY-MM-DD'):
                return 'today'
            case moment().add(1, 'd').format('YYYY-MM-DD'):
                return 'tomorrow'
            case moment().add(2, 'd').format('YYYY-MM-DD'):
                return 'day_after_tomorrow'
            default:
                break;
        }
        return 'date'
    })()

    return (<div style={{ width: 936, minHeight: '90vh', position: 'relative', padding: 24, paddingTop: 48, paddingBottom: 200, margin: '0 auto' }}>
        <div className='flex-between' style={{marginBottom: 20}}>
            <div className='flex-center'>
                <h2>帮卖广场活动管理</h2>
                {/* <Select list={hotcity} showKey='full_name' value={hotcity.findIndex(i => Number(i.id) === Number(cityId))} uni='city' onChange={(e) => {
                    setCityId(e.id)
                }} /> */}
            </div>
            <div className='cur-pointer button_solid_red' style={{ padding: '4px 11px' }} onClick={() => { setShowSelectEvent(true) }}>选择品牌及活动</div>
        </div>
        <div style={{
            background: 'rgba(255,255,255,1)',
            borderRadius: 8,
            border: '1px solid rgba(204,204,204,1)',
        }}>
            <SelectTime className='flex-box'>
                <div className='cur-pointer' style={{ color: dateStatus === '' ? '#ff5156' : '' }} onClick={() => { setStatus(1); setPage(1); setDate(undefined) }}>已选择活动（未上架）</div>
                <div className='cur-pointer' style={{ color: status === 0 ? '#ff5156' : '' }} onClick={() => {
                    setPage(1); setStatus(0); setDate(undefined)
                }} >已下架活动</div>
                <div className='cur-pointer' style={{ color: dateStatus === 'today' ? '#ff5156' : '' }} onClick={() => { setStatus(1); setPage(1); setDate(moment()) }}>今天</div>
                <div className='cur-pointer' style={{ color: dateStatus === 'tomorrow' ? '#ff5156' : '' }} onClick={() => { setStatus(1); setPage(1); setDate(moment().add(1, 'd')) }}>明天</div>
                <div className='cur-pointer' style={{ color: dateStatus === 'day_after_tomorrow' ? '#ff5156' : '' }} onClick={() => { setStatus(1); setPage(1); setDate(moment().add(2, 'd')) }}>后天</div>
                <div>
                    <DatePicker locale={zhCN.DatePicker} onChange={e => {
                        if (e) {
                            setPage(1)
                            setDate(e)
                        }
                    }} />
                </div>
            </SelectTime>
            {list.map(i => {
                return <Item refresh={() => {
                    getData()
                }}  maxRank={maxRank} data={i} key={i.id} />
            })}
        </div>
        <div style={{ position: 'fixed', width: '100%', bottom: 0, right: 0, padding: 30, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', background: '#fff', zIndex: 4 }}>
            <Pagination total={total} pageSize={limit} current={page} onChange={e => {
                setPage(e)
            }} />
        </div>

        {/* 选择活动 */}
        {showSelectEvent
            ? <SelectEvent onClose={() => { setShowSelectEvent(false); getData() }} />
            : null}

    </div >)

    function getData() {
        const data: any = {
            offset: (page - 1) * limit,
            limit,
            date: date ? date.format('YYYY-MM-DD hh:mm:ss') : '',
        }
        if (date) data.status = 1
        if (status === 0) {
            delete data.date
            data.status = status
        } else {
            setStatus(1)
        }
        get('/brandstreet/activelist', data).then((res: any) => {
            setMaxRank(Number(res.maxRank))
            setTotal(Number(res.count))
            setList(res.bstList)
        })
    }
}, false)