import { Checkbox } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { API } from "../../../data/options";
import { get } from "../../../netApi/fetch";
import BTN from "../../total/Button";
const mapStateToProps = (state: { login_user_data: user; }) => {
    return {
        user: state.login_user_data
    }
}
interface bundleData {
    content: string
    createtime: string
    download_url: string
    internal_version: string
    is_force: string
    version: string
}

const Panel = styled.div`
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
    padding: 30px 30px;
    margin-bottom: 30px;
    .item{
        width: 140px;
        min-width: 140px;
        margin-right: 12px;
        div:first-child{
            margin-bottom: 8px;
        }
        input, textarea{
            width: 140px;
            border: 1px solid #e3e4e5;
            border-radius: 4px;
            
        }
    }
    input, textarea{
        border: 1px solid #e3e4e5;
        outline: none;
    }
    .title{
        margin-bottom: 30px;
        font-size: 16px;
    }
`
export default connect(mapStateToProps)((props: { user: user }) => {
    const [bundleData, setBundleData] = useState<bundleData>()
    const [bundleId, setBundleId] = useState('')
    const [version, setVersion] = useState('')
    const [content, setContent] = useState('')
    const [isForce, setForce] = useState(false)
    const file = useRef<File>()
    useEffect(() => {
        if (bundleData) return
        get('/appversion/newest', {}).then((res: bundleData) => {
            setBundleData(res)
            setBundleId(Number(res.internal_version) + 1 + '')
        })
    }, [bundleData])
    const upload = useCallback(() => {
        if (!file.current) return
        const formData = new FormData()
        formData.append('version', version)
        formData.append('internal_version', bundleId)
        formData.append('is_force', String(Number(isForce)))
        formData.append('content', content)
        formData.append('file', file.current)
        formData.append('types', '1')
        formData.append('token', props.user.token)
        fetch(API + '/appversion/uploadnewversion', {
            method: 'post', body: formData, mode: 'cors',
            credentials: 'include',
        })
    }, [bundleId, version, content, isForce, props])
    console.log(bundleData);
    return <div style={{ padding: '200px 300px' }}>
        <Panel>
            <div className='title'>线上版本</div>
            <div className='flex-box' style={{ alignItems: "flex-start" }}>
                <div className='item'>
                    <div>版本号</div>
                    <div>{bundleData?.version}</div>
                </div>
                <div className='item'>
                    <div>bundleId</div>
                    <div>{bundleData?.internal_version}</div>
                </div>
                <div className='item'>
                    <div>发布时间</div>
                    <div>{bundleData?.createtime}</div>
                </div>
                <div className=''>
                    <div>版本描述</div>
                    <div>{bundleData?.content}{bundleData?.content}{bundleData?.content}{bundleData?.content}{bundleData?.content}{bundleData?.content}</div>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='title'>发布版本信息</div>
            <div className='flex-box' style={{ alignItems: "flex-start", marginBottom: 12 }}>
                <div className='item'>
                    <div>版本号</div>
                    <div>
                        <input value={version} onChange={(e) => {
                            setVersion(e.target.value)
                        }} type="text" />
                    </div>
                </div>
                <div className='item'>
                    <div>bundleId</div>
                    <div>
                        <input value={bundleId} onChange={(e) => {
                            setBundleId(e.target.value)
                        }} type="number" />
                    </div>
                </div>
                <div className='item'>
                    <div>强制更新</div>
                    <div>
                        <Checkbox checked={isForce} onChange={(e) => {
                            console.log(e);
                            setForce(e.target.checked)
                        }} />
                    </div>
                </div>
            </div>
            <div className='' style={{ flex: 1, marginBottom: 12 }}>
                <div style={{ marginBottom: 8 }}>版本描述</div>
                <textarea value={content} onChange={e => {
                    setContent(e.target.value)
                }} style={{ width: '100%', height: 70 }} />
            </div>
            <div className="flex-box" style={{ flex: 1, marginBottom: 12 }}>
                <span>apk文件</span>
                <input type="file" name="" id="" accept='.apk' onChange={e => {
                    if (!e.target.files) return
                    console.log(e.target.files[0]);
                    file.current = e.target.files[0]
                }} />
            </div>
            <div className="flex-box" style={{ flex: 1, marginBottom: 12 }}>
                <BTN solid color='red' onClick={upload}>发布</BTN>
            </div>
        </Panel>
    </div>
})