import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink, withRouter } from "react-router-dom";
import { toggleSidebar, logout, set_login_user_data, waitingLogin, setusertype, setOrder } from '../../reducer/index'

// import can_change_admin_list from '../../data/can_change_admin';
import banSidebarList from '../../data/banSidebarList';
// import { appname } from '../../data/options'

import styled from 'styled-components';

import Modal from '../total/Modal'

import api from '../../netApi'
import { get } from '../../netApi/fetch';
// import { get } from '../../netApi/fetch.ts';

const LinkPanel = styled.div`
    width: 100%; 
    height: 100%;
    font-size: 16px ;
    padding: 20px;
    position: relative;
    &:hover{
        /* background: #ffeeef; */
        color: #ff5156;
    }
`
// const SpanNumber = styled.span`
//     position: absolute;
//     background: #ff5156;
//     color: white;
//     line-height: 1;
//     font-size: 12px;
//     border-radius: 7px;
//     padding: 1px 4.5px;
//     left: 11.7em;
//     top: 1.6em;
// `

class Sidebar extends Component {
    constructor(p) {
        super()
        console.log('contrust')
        this.state = {
            sidebarLeft: 0,
            sidebarWidth: '228px',
            isShow: true,
            logoutPanel: false,
            adminList: [],
            banSidebar: false
        }
        const width = document.body.clientWidth
        if (width < 993) {
            p.toggleSidebar(false)
        }
    }

    componentDidMount(){
        this.getItems()
    }

    componentDidUpdate(prev){
        if(!prev.is_Login)this.getItems()
        if(this.props.location.pathname === '/home'){
            if(this.state.urlList && this.state.urlList.filter(i => i.auth).length > 0){
                this.props.history.push(this.state.urlList[this.state.urlList.findIndex(i => i.auth)].href)
            }
        }
    }

    getItems(){
        if(!this.props.is_Login)return
        get('/god/getindextag').then(res => {
            this.setState({urlList: res.tag_list})
        })
    }

    toggleSidebar() {
        const isShow = !this.props.sidebarOpen
        this.props.toggleSidebar(isShow)
    }
    click(e) {
        e.preventDefault()
        e.stopPropagation()
    }
    logout() {
        const _this = this
        api('admin', 'logout')(
            { openid: _this.props.login_user_data.openid }
        ).then(res => {
            window.location.replace('/')
            // console.log(res)
        })
        this.props.logout()
        this.setState({
            logoutPanel: false
        })
    }
    toggleAccount() {
        api('admin', 'accountList')().then(res => {
            this.setState({
                adminList: res.data.data.list
            })
        })
        this.setState({
            toggleAccount: true
        })
    }
    close() {
        this.setState({
            toggleAccount: false
        })
    }
    changeAdmin(i, e) {
        api('admin', 'switchAccount')({}, 'post', { s_id: this.state.adminList[i].id }).then((data) => {
            // window.location.reload()
            this.props.logout()
            api('admin', 'checkIsLogin')({}, 'post', {}, {
                header: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((res) => {
                if (Number(res.data.code) === 0) {
                    api('proxy', 'checkProxy')({ type: 1 }, 'post', {}, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    }).then((res) => {
                        // console.log(res.data.data.type)
                        this.props.setUserType(res.data.data.type)
                    })
                    this.props.login(res.data.data)
                    this.close.bind(this)
                }
                this.props.waitingLogin()
            })
            this.close()
            this.props.history.push('/refresh')
            this.props.setOrderList({
                ...this.props.order,
                offset: 0,
                list: [],
                selectedTuan: {},
                search: '',
                count: -1
            })
        })
    }
    toLogout() {
        this.setState({
            logoutPanel: true
        })
    }
    cancelLogout() {
        this.setState({
            logoutPanel: false
        })
    }
    clickCloseSidebar() {
        const width = document.body.clientWidth
        if (width < 993) {
            this.toggleSidebar()
        }
    }
    render() {
        return (
            <div onClick={this.click.bind(this)} style={{ display: this.state.banSidebar ? 'none' : '' }}>
                <div>
                    <i className='iconfont icon-pc_gengduo' style={{}} id='toggleSidebarIcon' onClick={this.toggleSidebar.bind(this)}></i>
                </div>
                <div id='sidebar' style={{
                    left: this.props.sidebarOpen ? this.state.sidebarLeft : '-' + this.state.sidebarWidth,
                    width: this.state.sidebarWidth
                }}>
                    <div style={{ height: 42 }}>
                        {/* <img alt='接力GO图标' src={require('../../images/logo.png')} /> */}
                    </div>
                    {!this.props.is_Login ? <div>
                        {/* <img src={require('../../images/code.png')} alt='小程序码' style={{ marginTop: '100px', marginBottom: '25px', width: 140 }} /> */}
                        <div>扫码体验小程序</div>
                    </div> : <div id='sidebaruserdata' >
                            <img onClick={() => {
                                if(Number(this.props.login_user_data.sid) === 3){
                                    this.props.history.push('/app/upload')
                                }
                            }} src={this.props.login_user_data.avatar + '?imageView2/1/w/300'} alt='用户头像' style={{ marginTop: '100px', marginBottom: '25px', width: 70, height: 'auto', borderRadius: '50%' }} />
                            <div style={{
                                wordBreak: 'break-all', margin: '0 1em', fontWeight: 600
                            }}>{this.props.login_user_data.name}</div>
                            <button className='button_hollow_red'
                                style={{ marginTop: 8, fontSize: 12 }} onClick={this.toggleAccount.bind(this)}>
                                切换身份
                                </button>
                        </div>}

                    <div className='navList'>
                        {this.props.is_Login && this.state.urlList
                            ? <>
                                {this.state.urlList.map(i => {
                                    if (!i.auth) return null
                                    return <NavLink to={i.href} key={i.href}>
                                        <LinkPanel className='fcc' onClick={this.clickCloseSidebar.bind(this)}>
                                            <i className={'iconfont ' + i.icon} />
                                            <span>{i.name}</span>
                                        </LinkPanel>
                                    </NavLink>
                                })}
                            </>
                            : <>
                                <NavLink exact to='/'>
                                    <LinkPanel className='fcc'>首页</LinkPanel>
                                </NavLink>
                            </>}
                    </div>
                </div>
                {this.state.toggleAccount ? (
                    <Modal title='切换主页' cancel={true} close={this.close.bind(this)} style={{ content: { maxHeight: 600, overflowX: 'auto' } }} >
                        {this.state.adminList.map((item, i) => {
                            return (
                                <div key={i} className='select-hover'
                                    onClick={this.changeAdmin.bind(this, i)}
                                    style={{
                                        display: 'flex', overflowY: 'auto', wordBreak: 'break-all', cursor: 'pointer', justifyContent: 'space-between',
                                        minWidth: 200, alignItems: 'center', marginTop: 10, padding: '4px 11px', maxHeight: 400
                                    }}>
                                    <div style={{ marginRight: 20 }}><img style={{ width: 30, height: 30, borderRadius: '50%', }} alt='头像' src={item.logo}></img></div>
                                    <div style={{ flex: 1, fontSize: 14, cursor: 'pointer' }}>{item.name}</div>
                                    {item.istop ? (
                                        <div style={{
                                            minWidth: 40, height: 20, fontSize: 12, lineHeight: '20px', textAlign: 'center', borderRadius: 10, marginLeft: 10, background: '#fd5356', color: '#fff'
                                        }}>
                                            本人
                                        </div>
                                    ) : ''}
                                </div>
                            )
                        })}
                    </Modal>
                ) : ''}
                {this.state.logoutPanel ? (
                    <Modal title='确认退出登录' cancel={true} confirm={this.logout.bind(this)} close={this.cancelLogout.bind(this)}>
                        请确认退出
                    </Modal>
                ) : ''}
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: (sidebarOpen) => {
            dispatch(toggleSidebar(sidebarOpen))
        },
        logout: () => {
            dispatch(logout())
        },
        login: (data) => {
            dispatch(set_login_user_data(true, data))
        },
        waitingLogin: () => {
            dispatch(waitingLogin(false))
        },
        setUserType: (v) => {
            dispatch(setusertype(v))
        },
        setOrderList: (order) => {
            dispatch(setOrder(order))
        },
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        sidebarType: state.sidebarType,
        sidebarOpen: state.sidebarOpen,
        is_Login: state.is_Login,
        login_user_data: state.login_user_data,
        userType: state.userType,
        order: state.order
    }
}

const SB = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar))

class SbWraper extends Component {

    state = {}

    componentDidMount() {
        let ban = false
        for (let i = 0; i < banSidebarList.length; i++) {
            const item = banSidebarList[i];
            const reg = new RegExp(item, 'g')
            if (reg.test(this.props.location.pathname)) {
                ban = true
                break
            }
        }
        if (ban !== this.state.banSidebar) {
            this.setState({
                banSidebar: ban
            })
            // this.props.toggleSidebar(false)
        }
    }

    componentDidUpdate() {
        let ban = false
        for (let i = 0; i < banSidebarList.length; i++) {
            const item = banSidebarList[i];
            const reg = new RegExp(item, 'g')
            if (reg.test(this.props.location.pathname)) {
                ban = true
                break
            }
        }
        if (ban !== this.state.banSidebar) {
            this.setState({
                banSidebar: ban
            })
            // this.props.toggleSidebar(false)
        }
    }

    render() {
        if (this.state.banSidebar) return <></>
        return <SB />
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SbWraper))