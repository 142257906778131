import React, { useEffect, useState } from 'react';
import { DatePicker, Pagination } from 'antd';
import { RouteComponentProps, match, } from 'react-router-dom'
import styled from 'styled-components';
import Search from '../../total/Input/Search';
import moment from 'moment';
import { get } from '../../../netApi/fetch';
import { connect } from 'react-redux';
import Content from '../../../high-components/Content';
import Select from '../../total/Select_';
import JSONPretty from 'react-json-pretty';
import { confirm } from '../../Methods/Confirm';

interface props extends RouteComponentProps {
    match: Match,
    user: user
}
interface Match extends match {
    params: {
        type: string,
    }
}

interface logItem {
    request_id: string
    server_ip: string
    time: string
    type: string
    uid: string
    url: string
    user_ip: string
    method: string
    header: {
        Accept: string
        'Accept-Encoding': string
        Connection: string
        'Content-Length': string
        'Content-Type': string
        Cookie: string
        Createtime: string
        Host: string
        Platform: string
        Token: string
        'User-Agent': string
    }
    params: {
        [key: string]: string
    }
}
const Table = styled.table`
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    & td{
        padding: 12px;
        border-right: 1px solid #eee;
        box-sizing: border-box;
    }
    &:nth-child(1){
        border-left: 1px solid #eee;
    }
    & tr:last-child{
        border-bottom: 1px solid #eee;
    }

    tr:nth-child(2n){
        background-color: #f5f5f5;
    }
`

function List(props: props) {

    const [search, setSearch] = useState('') // 搜索关键字
    const [key_word, setkey_word] = useState('')

    const [controller, setcontroller] = useState('log')
    const [controllerList, setControllerList] = useState<{ value: string }[]>()
    const [action, setaction] = useState('')
    const [actionList, setActionList] = useState<{ value: string }[]>()

    const [page, setPage] = useState(1)
    const LIMIT = 30

    const [time, setTime] = useState<moment.Moment>(moment())

    const [count, setCount] = useState(0)

    const [list, setList] = useState<logItem[]>()
    useEffect(() => {
        const data = {
            key_word,
            controller,
            action,
            start: (page - 1) * LIMIT,
            limit: LIMIT,
            time: time.startOf('d').unix(),
            type: props.match.params.type || 1,
        }
        console.log(props.match.params.type);
        console.log(data.controller);
        if (!data.controller) return
        if (!data.action) return
        setList([])
        get('/log/apiloglist', data).then((res: { count: React.SetStateAction<number>; list: any[] }) => {
            setCount(res.count)
            console.log(res.list[0]?.data);
            try {
                console.log(res.list.map(item => {
                    return {
                        ...item, data: JSON.parse(item?.data)
                    }
                }));
            } catch (error) {
                console.log(error);
            }
            setList(res.list)
        })
    }, [page, time, key_word, controller, action, props.match.params.type])

    useEffect(() => {
        if (props.user && props.user.uid) {
            get('/log/getcontrollerlist', {}).then((res: any) => {
                setControllerList(res.list.map((i: string) => { return { value: i } }))
            })
        }
    }, [props.user])

    useEffect(() => {
        if (controller) {
            get('/log/getactionbycontroller', {
                controller
            }).then((res: any) => {
                setActionList(res.list.map((i: string) => { return { value: i } }))
            })
        }
    }, [controller])
    return <div>
        <div style={{ width: '100%', margin: '0 auto', padding: 30, }}>
            <div className='fbc'>
                <div className='flex-box'>
                    <DatePicker value={time} onChange={e => {
                        if (e) {
                            const time = moment(e)
                            time.startOf('d')
                            setTime(time)
                        }
                    }} />
                    <Select placeholder='controller' list={controllerList || []} maxHeight={400} showKey='value' uni='controller' value={controllerList?.findIndex(i => i.value === controller)} onChange={e => {
                        setaction('')
                        setcontroller(e.value)
                    }} />
                    <Select placeholder='action' list={actionList || []} maxHeight={400} showKey='value' uni='action' value={actionList?.findIndex(i => i.value === action)} onChange={e => {
                        setaction(e.value)
                    }} />
                </div>
                <div style={{ width: '20%' }}>
                    <Search onChange={setSearch} onSearch={() => {
                        setkey_word(search)
                    }} search={search} placeholder='搜索' />
                </div>
            </div>

            <Table>
                <thead>
                    <tr style={{ background: '#f5f5f5' }}>
                        <td style={{ width: 120 }}>uid</td>
                        <td>时间</td>
                        <td>method</td>
                        <td style={{ width: 300 }}>params</td>
                        <td style={{ width: 300 }}>header</td>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((i, index) => {
                        return <tr key={index}>
                            <td onDoubleClick={() => {
                                confirm({
                                    text: <JSONPretty data={(i as any).data} mainStyle='white-space: pre-wrap; word-break: break-all;' />
                                })
                            }} className='cur-pointer' onClick={() => {
                                confirm({
                                    text: <JSONPretty data={i} mainStyle='white-space: pre-wrap; word-break: break-all; max-height: 40vh; overflow: overlay;' />
                                })
                            }}>{i.uid}</td>
                            <td>{i.time}</td>
                            <td>{i.method}</td>
                            <td><JSONPretty data={i.params || (i as any).data} mainStyle='white-space: pre-wrap; word-break: break-all;' /></td>
                            <td><JSONPretty data={i.header} mainStyle='white-space: pre-wrap; word-break: break-all;' /></td>
                        </tr>
                    })}
                </tbody>
            </Table>

            <Pagination pageSize={LIMIT} total={count} current={page} onChange={e => {
                setPage(e)
            }} />
        </div>
    </div>
}

const mapStateToProps = (state: { login_user_data: user; },) => {
    return {
        user: state.login_user_data
    }
}

export default connect(mapStateToProps,)(Content(List))