import React, { Component } from 'react';
import Content from '../../../high-components/Content';
import { RouteComponentProps, NavLink, } from 'react-router-dom'
import { get } from '../../../netApi/fetch';
import parse from '../../../methods/paramParse';
import { Pagination, message } from 'antd';
import Search from '../../total/Input/Search';
import Loading from '../../Methods/Loading';
import styled from 'styled-components';
const Table = styled.table`
    width: 100%;
    tr:nth-child(2n){
        background: rgb(245,245,245);
    }
    tr:only-child{
        background: rgb(245,245,245);

    }
    td{
        border: 1px solid  rgb(245,245,245);
        padding: 10px;
        &:nth-child(1){
            width: 200px;
        }
        a div{
            color: #333;
        }
    }
`
interface Props extends RouteComponentProps {

}
interface state {
    search: string,
    page: number,
    total?: number,
    pageSize: number,
    searchText: string,
    list?: dataItem[]
}
interface dataItem {
    sid: string,
    name: string,
    logo: string,
    type: string,
    income_sum: string,
    order_count: string
    fensi_count: number | string
    can_withdraw_amount: string,
    level_name: string
    service_level_id: string
}

class List extends Component<RouteComponentProps>{
    readonly state: state
    constructor(p: Props) {
        super(p)
        const params = parse(p.location.search)
        this.state = {
            pageSize: 20,
            search: params.s || '',
            searchText: params.s || '',
            page: Number(params.p) || 1,
        }
        this.getData()
    }
    componentDidMount() {
        document.title = '供应商管理'
    }
    componentWillUnmount() {
        document.title = '接力go'
    }
    componentDidUpdate() {
        const params = parse(this.props.location.search)
        if (params.s !== this.state.search || Number(params.p) !== this.state.page) {
            if (params.s === undefined || params.p === undefined) return
            this.setState({
                search: params.s || '',
                page: Number(params.p) || 1,
            }, () => {
                this.getData()
            })
        }
        console.log(this.state);
    }

    render() {
        return <div style={{ maxWidth: 1200, width: '100%', margin: '0 auto' }}>
            <div className='flex-between' style={{ padding: 30 }}>
                <h2>供应商管理</h2>
                <Search placeholder='搜索供应商' search={this.state.searchText} onChange={(e: string) => {
                    this.setState({ searchText: e })
                }} onSearch={() => {
                    this.props.history.push(`/suppliers/list?p=1&s=${this.state.searchText}`)
                }} />
            </div>
            <div style={{ padding: 10 }}>
                <Table>
                    <thead>
                        <tr>
                            <td>用户</td>
                            <td>等级</td>
                            <td>收入</td>
                            <td>订单数</td>
                            <td>粉丝数</td>
                            <td>可提现余额</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list && this.state.list.map((item: dataItem) => {
                            return <tr key={item.sid}>
                                <td>
                                    <NavLink to={`/user/detail/${item.sid}?mode=2`} className='flex-box'>
                                        <img alt='' src={item.logo} style={{ width: 60, height: 60, borderRadius: 30, marginRight: 6 }} />
                                        <div>{item.name}</div>
                                    </NavLink>
                                </td>
                                <td>
                                    <div>{item.level_name}</div>
                                </td>
                                <td>
                                    <div>￥{item.income_sum}</div>
                                </td>
                                <td>
                                    <div>{item.order_count}</div>
                                </td>
                                <td>
                                    <div>{item.fensi_count}</div>
                                </td>
                                <td>
                                    <div>￥{item.can_withdraw_amount}</div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
            {/* 分页器 */}
            <div style={{ textAlign: 'center' }}>
                <Pagination total={this.state.total || 0} current={this.state.page} pageSize={this.state.pageSize} onChange={this.pageChange.bind(this)} />
            </div>
        </div>
    }

    pageChange(e: number) {
        this.props.history.push(`/suppliers/list?p=${e}&s=${this.state.search}`)
    }

    getData() {
        const limit = this.state.pageSize
        const page = this.state.page
        const offset = (page - 1) * limit
        const data = {
            limit,
            offset,
        }
        Loading.show()
        get('/serviceprovider/management', data).then((res: any) => {
            if (this.state.page === page) {
                Loading.hide()
                this.setState({
                    total: Number(res.count),
                    list: res.list.map((item: any) => {
                        const { sid, name, logo, type, income_sum, order_count, fensi_count, can_withdraw_amount } = item
                        return {
                            sid, name, logo, type: Number(type),
                            income_sum: (Number(income_sum) / 100).toFixed(2),
                            order_count: Number(order_count),
                            fensi_count: Number(fensi_count),
                            level_name: item.level_name,
                            can_withdraw_amount: (Number(can_withdraw_amount) / 100).toFixed(2),
                        }
                    })
                })
            }
        }).catch((error: string) => {
            Loading.hide()
            message.error(error)
        })
    }
}

export default Content(List)