import React, { Component } from 'react'
import styled from 'styled-components';
// import { connect } from 'react-redux';
// import { showLoading } from '../../../reducer/index';
import { get, post } from '../../../netApi/fetch';

import Content from '../../../high-components/Content';
import { message } from 'antd';

const List = styled.div`
    padding: 150px 50px;
    max-width: 856px;
    margin: 0 auto;
    & table{
        background: #fff;
        &:nth-child(2n){
            background: #f5f5f5;
        }
        width: 100%;
        tr:nth-child(2){
            td{
                &>div{
                    padding: 20px;
                }
            }
        }
        td{
            border: 1px solid #ccc;
        }
        
    }
`
const Logo = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 20px;
`
const Name = styled.div`
    font-weight: 600;
    color: #330000;
    font-size: 14px;
    max-width: 6em;
    min-width: 6em;
    word-wrap:break-word;
    word-break:break-all;
`
const IdCard = styled.div`
    width:120px;
    height: 90px;
    img{
        width: 100%;
        height: 100%;
    }
`
class ManualCertifiedList extends Component<Location> {

    limit: number = 10

    offset: number = 0
    loading: boolean = false
    readonly state = {
        list: [],
        count: 99999999999999,
    }

    scrollHook = this.scrollLoad.bind(this)

    componentDidMount() {
        console.log(this.props);
        this.getlist()
        document.addEventListener('scroll', this.scrollHook)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollHook)
    }

    render() {
        return <div>
            <List>
                {this.state.list.map((user: ManualCertifiedUser) => {
                    return <table key={user.certified_info.id}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <div style={{ padding: 20, maxWidth: 300 }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Logo src={user.shopinfo.logo} />
                                            <Name>{user.shopinfo.name}</Name>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <div>姓名：{user.certified_info.name}</div>
                                        <div>身份证：{user.certified_info.idcard}</div>
                                        <div>手机号：{user.certified_info.phone}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fcc'>
                                        <div style={{ marginRight: 24, alignSelf: 'flex-start' }}>照片：</div>
                                        <div className='fcc'>
                                            <div style={{ marginRight: 30 }}>
                                                <IdCard >
                                                    <img alt='' className='img-center' src={user.idcard_back_img} />
                                                </IdCard>
                                                <div style={{ textAlign: 'center', marginTop: 12 }}>
                                                    <a href={user.idcard_back_img} target='_blank' rel="noopener noreferrer" >查看大图</a>
                                                </div>
                                            </div>
                                            <div>
                                                <IdCard>
                                                    <img alt='' className='img-center' src={user.idcard_front_img} />
                                                </IdCard>
                                                <div style={{ textAlign: 'center', marginTop: 12 }}>
                                                    <a href={user.idcard_front_img} target='_blank' rel="noopener noreferrer" >查看大图</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <button className='button_solid_red' style={{minWidth: '6em'}} onClick={() => {
                                        this.accpetAuthen(user)
                                    }}>确定认证</button>
                                </td>
                            </tr>
                        </tbody>
                    </table >
                })}
            </List>
        </div>
    }

    async getlist() {
        if(this.loading)return
        if(this.offset >= this.state.count)return
        this.loading = true
        const data = await get('/god/gettomanualcertifiedlist', {
            offset: this.offset,
            limit: this.limit,
            types: 1,
        })
        if (data) {
            this.loading = false
            this.offset += data.list.length
            this.setState({
                list: [...this.state.list, ...data.list],
                count: data.count,
            })
        }
    }

    accpetAuthen(e: ManualCertifiedUser) {
        post('/god/authenticationbyadmin', {
            sid: e.certified_info.sid,
            types: 1,
        }).then((res: any) => {
            message.success('认证成功')
            this.refreshList()
        }).catch((err: any) => {
            message.error(err)
        })
    }

    async refreshList() {
        const data = await get('/god/gettomanualcertifiedlist', {
            offset: 0,
            limit: this.offset,
            types: 1,
        })
        if (data) {
            this.setState({
                list: data.list,
                count: data.count,
            })
        }
        return
    }

    scrollLoad(e: any) {
        console.log(e);
        const offsetY = window.pageYOffset
        const scrollHeight = e.srcElement.body.scrollHeight
        let bottom = scrollHeight - offsetY
        console.log(offsetY,scrollHeight,bottom);
        if(bottom <2000){
            this.getlist()
        }
    }
}

export default Content(ManualCertifiedList)