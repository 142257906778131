import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setusertype } from '../reducer/index'

// import api from '../netApi/index';
import {post} from '../netApi/fetch.ts';

import { withRouter } from 'react-router-dom';
import Footer from '../components/total/footer';
// import Right from '../components/total/userdata/Sidebar';

const mapStateToProps = (state) => {
    return { sidebarOpen: state.sidebarOpen, is_Login: state.is_Login }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setusertype: (v) => {
            dispatch(setusertype(v))
        }
    }
}


export default (Wrap, footer = true) => {
    class Content extends Component {
        constructor(p) {
            super()
            this.state = {
                showFooter: true,
                style: {}
            }
            // const _this = this
            if (p.is_Login) {
                // api('proxy', 'checkProxy')({ type: 1 }, 'post', {}, {
                //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                // }).then((res) => {
                //     // console.log(res.data.data.type)
                //     p.setusertype(res.data.data.type)
                // })
                post('/proxy/checkProxy', {type: 1}).then(res => {
                    p.setusertype(res.type)
                })
            }
        }

        sS = this.setState

        setState = function (state) {
            this.sS(state)
        }

        componentDidMount() {
            if (!this.props.sidebarOpen) {
                this.setState({
                    style: {
                        ...this.state.style,
                        marginLeft: 'auto'
                    }
                })
            } else {
                this.setState({
                    style: {
                        ...this.state.style,
                        marginLeft: null
                    }
                })
            }
        }

        componentDidUpdate(prev) {
            if (prev.sidebarOpen !== this.props.sidebarOpen) {
                if (!this.props.sidebarOpen) {
                    this.setState({
                        style: {
                            ...this.state.style,
                            marginLeft: 'auto'
                        }
                    })
                } else {
                    this.setState({
                        style: {
                            ...this.state.style,
                            marginLeft: null
                        }
                    })
                }
            }
        }
        render() {
            return (
                <div style={this.state.bg} className='PageContent'>
                    <div style={this.state.style} id='contentWrap'>
                        <Wrap {...this.props} hideFooter={this.hideFooter.bind(this)} />
                        {(this.state.showFooter && footer)?<Footer />: null}
                    </div>
                    {/* <Right /> */}
                </div>
            )
        }

        hideFooter(){
            this.setState({showFooter: false})
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(withRouter(Content))
}
