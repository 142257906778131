import { Component } from 'react'
import { connect } from 'react-redux'
import { post, get, getToken, setToken } from '../netApi/fetch';
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { set_login_user_data } from '../reducer';

import parseParams from '../methods/paramParse';

interface Props extends RouteComponentProps {
    login: (data: any) => void
}

class Login extends Component<Props> {

    async componentDidMount() {
        console.log(this.props)
        await this.getLogin()
    }

    render() {
        return null
    }

    async getLogin() {
        let user
        // 解析token
        let data = parseParams(this.props.location.search)
        if (data.token) {
            setToken(data.token)
            try {
                user = await post('/admin/checkIsLogin', {})
                this.props.login(user)
            } catch (error) {
                console.log(error);
            }
            return
        }

        const ua = navigator.userAgent.toLowerCase();
        if ((/MicroMessenger/i).test(ua)) {
            if (this.props.location.search !== '') {
                const s = this.props.location.search.slice(1)
                const q = s.split('&')
                const data = q.map((item) => {
                    return item.split('=')
                })
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    if (item[0] === 'code') {
                        const postdata = {
                            code: item[1]
                        }
                        try {
                            user = await get('/huser/callback', postdata)
                            setToken(user.token)
                            this.props.login(user)
                        } catch (error) {
                            console.log(error)
                        }
                        return user
                    }
                }
            }
            let href = `https://www.jieligo.net/auth?redirect_uri=${encodeURIComponent(window.location.href)}`
            const s = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6cf3b69eb2efe6e7&redirect_uri=${encodeURIComponent(href)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
            window.location.href = s
            return
        }

        if (getToken() === '') {
            const s = `https://www.jieligo.net/auth?redirect_uri=${encodeURIComponent(window.location.href)}`
            window.location.href = s
        } else {
            try {
                user = await post('/admin/checkIsLogin', {})
                if (user.token) setToken(user.token)
                this.props.login(user)
            } catch (error) {
                const s = `https://www.jieligo.net/auth?redirect_uri=${encodeURIComponent(window.location.href)}`
                window.location.href = s
            }
        }

        return user
    }
}

const mapStateToProps = (state: any,) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        login: (data: any) => {
            dispatch(set_login_user_data(true, data))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
