import { message } from "antd"
import React, { useState } from "react"
import styled, { CSSProperties } from "styled-components"
import { post } from "../../../netApi/fetch"
import { themeItem } from "./themeControl"

const ControlTag = styled.div`
    height: 22px;
    background: rgba(0,0,0,.7);
    border-radius: 4px;
    padding: 0 6px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    &:not(:last-child){
        margin-right: 6px;
    }
`

export default (props: {
    list: themeItem[]
    activeThemeId: string | number
    user: user
    selectTheme: (id: string | number) => void
    onRankChange: (list: themeItem[]) => void
    onStatusChange: (theme: themeItem) => void
}) => {
    const activeCss: CSSProperties = {
        background: '#fff1ef',
        border: '1px solid #fa503c',
        boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.49)',
        position: "relative",
    }
    const [showMenuId, setShowMenuId] = useState<string>()
    return <div style={{ position: 'relative' }}>
        <div style={{ width: 375, background: '#f5f5f5', overflow: 'auto', height: '100%' }}>
            <div style={{
                height: 169,
                background: 'url(https://jieligo-1259536745.picgz.myqcloud.com/uploads/seller_circle/R34rZUV5uR.png)',
                backgroundSize: 'contain',
            }}>
                <img alt='用户头像' src={props.user.avatar} style={{ width: 32, height: 32, borderRadius: 16, marginTop: 48, marginLeft: 12 }} />
            </div>
            <div>
                {[...props.list,].map(theme => {
                    return <div key={theme.id} style={{
                        margin: '9px 0', boxSizing: "border-box",
                        border: '1px solid transparent',
                        ...(theme.id === props.activeThemeId ? activeCss : {})
                    }} onClick={() => {
                        props.selectTheme(theme.id)
                    }} onMouseEnter={() => {
                        setShowMenuId(theme.id)
                    }} onMouseLeave={() => { setShowMenuId(undefined) }} >
                        <div className='cur-pointer' style={{ background: '#fff', borderRadius: 9, margin: '5px 12px', padding: '12px 15px', position: 'relative' }}>
                            <div className='flex-between' style={{ marginBottom: 6 }}>
                                <img alt='theme_logo' className='img-center' src={theme.logo + '/sm'} style={{ width: 42, height: 42, border: '1px solid #f5f5f5', borderRadius: 3, marginRight: 6, }} />
                                <div className="flex-between" style={{ flex: 1 }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ fontSize: 16, color: '#333', fontWeight: 600 }}>{theme.theme_name}</div>
                                        <div style={{ fontSize: 12, color: '#999' }}>{theme.desc}</div>
                                    </div>
                                    <div className='flex-center' style={{ width: 85, height: 30, background: 'linear-gradient(315deg,#fa503c, #fa803c)', borderRadius: 15, color: '#fff' }}>进入会场</div>
                                </div>
                            </div>
                            <div style={{ alignItems: 'stretch' }} className='flex-between' >
                                {theme.tuan_list.map(tuan => {
                                    return <div key={tuan.id} style={{ width: 103, display: 'flex', flexDirection: 'column' }} >
                                        <img src={tuan.logo} className='img-center' style={{ height: 103, borderRadius: 5, width: '100%' }} alt="" />
                                        <div style={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            flex: 1,
                                            color: '#333'
                                        }}>
                                            {tuan.title}
                                        </div>
                                        {Number(tuan.original_price) > 0 && <div style={{ color: '#ccc', fontSize: 12 }} >
                                            ￥{(Number(tuan.original_price) / 100).toFixed(2)}
                                        </div>}
                                        <div style={{ color: '#fa4f3a', fontSize: 16 }} >
                                            <span style={{ color: '#fa4f3a', fontSize: 12 }} >￥</span>
                                            {(Number(tuan.price) / 100).toFixed(2)}
                                        </div>
                                    </div>
                                })}
                                {theme.tuan_list.length < 3 && <div style={{ width: 103 }}></div>}
                            </div>
                            {showMenuId === theme.id && <div onClick={e => { e.stopPropagation() }} className='flex-between' style={{ position: 'absolute', width: '100%', left: 0, bottom: 0, }}>
                                <ControlTag onClick={() => {
                                    post('/tuan/changethemesellstatus', { theme_id: theme.id, is_stop_sell: Number(theme.status) === 1 ? 1 : 0 }).then(() => {
                                        message.success('操作成功')
                                        props.onStatusChange({
                                            ...theme, status: Number(theme.status) === 1 ? '0' : '1'
                                        })
                                    }).catch((e: { message: any }) => {
                                        message.error(e?.message || e)
                                    })
                                }}>{Number(theme.status) === 1 ? '暂停' : '开始'}出售</ControlTag>
                                <div className="flex-center">
                                    <ControlTag onClick={() => {
                                        post('/tuan/deletetheme', { theme_id: theme.id }).then(() => {
                                            const target = [...props.list].filter(i => i.id !== theme.id).sort((a, b) => a.rank - b.rank).map((i, rank) => {
                                                return {
                                                    ...i, rank,
                                                }
                                            })
                                            props.onRankChange(target)
                                        }).catch((e: { message: any }) => {
                                            message.error(e?.message || e)
                                        })
                                    }}>删除</ControlTag>
                                    <ControlTag onClick={() => {
                                        theme.rank -= 1.1
                                        const target = [...props.list].sort((a, b) => a.rank - b.rank).map((i, rank) => {
                                            return {
                                                ...i, rank,
                                            }
                                        })
                                        props.onRankChange(target)
                                    }}>上移</ControlTag>
                                    <ControlTag onClick={() => {
                                        theme.rank += 1.1
                                        const target = [...props.list].sort((a, b) => a.rank - b.rank).map((i, rank) => {
                                            return {
                                                ...i, rank,
                                            }
                                        })
                                        props.onRankChange(target)
                                    }}>下移</ControlTag>
                                </div>
                            </div>}
                        </div>
                    </div>
                })}
            </div>
            <div style={{ height: 60 }}></div>
            <div className='flex-between' style={{ position: "absolute", left: 0, bottom: 0, height: 50, width: '100%', background: '#fff' }}>
                <div className='flex-center' style={{ color: '#ff5156', flexDirection: 'column', flex: 1 }} >
                    <i className="iconfont  icon-dingyue1 " />
                    <div style={{ fontSize: 12 }} >订阅</div>
                </div>
                <div className='flex-center' style={{ flexDirection: 'column', flex: 1 }} >
                    <i className="iconfont icon-shouyejieligo-xuanzhong" />
                    <div style={{ fontSize: 12 }} >接力GO</div>
                </div>
                <div className='flex-center' style={{ flexDirection: 'column', flex: 1 }} >
                    <i className="iconfont icon-wode-weixuanzhong " />
                    <div style={{ fontSize: 12 }} >我的</div>
                </div>
            </div>
        </div> </div>
}